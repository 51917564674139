import { Component, inject, Inject, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { NZ_MODAL_DATA, NzModalService, NzModalFooterDirective } from 'ng-zorro-antd/modal';
import * as utils from '../../../../shared/utils/utils';
import { PauseService } from 'src/app/private/services/pause.service';
import { ProductionPauseDto } from 'src/app/shared/dto/production-pause.dto';
import { NzTableComponent, NzTheadComponent, NzTrDirective, NzTableCellDirective, NzThMeasureDirective, NzTbodyComponent } from 'ng-zorro-antd/table';
import { DatePipe } from '@angular/common';
import { ArticleBatchDto } from 'src/app/shared/dto/article-batch.dto';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
    selector: 'app-modal-watch-pauses',
    templateUrl: './modal-watch-pauses.component.html',
    styleUrls: ['./modal-watch-pauses.component.scss'],
    standalone: true,
    imports: [
    NzTableComponent,
    NzTheadComponent,
    NzTrDirective,
    NzTableCellDirective,
    NzThMeasureDirective,
    NzTbodyComponent,
    NzModalFooterDirective,
    DatePipe
],
})
export class ModalWatchPausesComponent implements OnInit {
    readonly message = inject(NzMessageService);
    
    isLoading: boolean = true;
    pauses: ProductionPauseDto[] = [];
    article: ArticleBatchDto;
    private _processOrderId!: number;

    constructor(
        @Inject(NZ_MODAL_DATA) public data: any,
        private pauseService: PauseService,
        private modalService: NzModalService,
    ) {
        this._processOrderId = Number(data.data.processId);
        this.article = data.data.article as ArticleBatchDto;
    }

    async ngOnInit() {
        await this.get();
    }

    async get() {
        try {
            this.isLoading = true;
            this.pauses = await this.pauseService.getByProcessId(this._processOrderId);
            this.isLoading = false;
        } catch (e: any) {
            this.isLoading = false;

            this.message.create('error', `${e.message}`);
        }
    }

    handleOk(): void {
        this.modalService.closeAll();
    }

    milisecondsToFormatTime(start: Date, end: Date): string {
        const millisec: number = end.getTime() - start.getTime();
        let seconds: number = +(millisec / 1000).toFixed(0);
        let minutes: number = Math.floor(seconds / 60);
        let hours: number = 0;

        if (minutes > 59) {
            hours = Math.floor(minutes / 60);
            minutes = minutes - hours * 60;
        }

        seconds = Math.floor(seconds % 60);

        if (hours > 0) {
            return `${hours >= 10 ? '' : '0'}${hours} : ${minutes >= 10 ? '' : '0'}${minutes} : ${seconds >= 10 ? '' : '0'}${seconds}`;
        }

        return `${minutes >= 10 ? '' : '0'}${minutes} : ${seconds >= 10 ? '' : '0'}${seconds}`;
    }

    datesAreOnSameDay(date: Date): any {
        return utils.datesAreOnSameDay(new Date(), new Date(date));
    }
}
