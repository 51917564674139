
export enum SocketUris {
    EDITED_OPERATION = 'edited-operation',
    EDITED_DATA_OPERATION = 'edited-data_operation',
    JOIN_OP = 'join-op',
    JOIN_ORDEN = 'join-orden',
    RELOAD_DATA = 'reload-data',
    RELOAD_OP = 'reload-op',
    RELOAD_ORDEN = 'reload-orden',
    JOIN_ROOM = 'join-room',
    LEAVE_ROOM = 'leave-room',
    SEND_MESSAGE = 'send-message'
}