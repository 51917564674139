import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { SCREEN_LOGGED } from '../constants/storage';
import { ScreenDto } from 'src/app/shared/dto/screen.dto';

@Injectable({
    providedIn: 'root',
})
export class ScreenLoggedService {
    public screenSubject: Subject<ScreenDto | null> = new Subject();
    private screen: ScreenDto | null = null;

    constructor() {}

    public get screenLogged(): ScreenDto | null {
        return this.screen;
    }

    public set screenLogged(v: ScreenDto | null) {
        this.screen = v;
        this.screenSubject.next(v);
    }

    public setScreen(data: ScreenDto | null): void {
        this.screenLogged = data;
        if (data != null) {
            localStorage.setItem(SCREEN_LOGGED, JSON.stringify(data));
        }
    }
}
