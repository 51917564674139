import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../../public/services/auth.service';
import { UserLoggedService } from '../services/userLogged.service';
import { SCREEN_LOGGED, TOKEN } from '../constants/storage';
import { ScreenLoggedService } from '../services/screenLogged.service';

@Injectable({
    providedIn: 'root',
})
export class PrivateViewGuard implements CanActivate {
    helper: JwtHelperService;

    constructor(
        private router: Router,
        private authService: AuthService,
        private userLoggedService: UserLoggedService,
        private screenLoggedService: ScreenLoggedService,
    ) {
        this.helper = new JwtHelperService();
    }

    canActivate(): boolean {
        const token = localStorage.getItem(TOKEN);
        const userLogged = this.userLoggedService.userLogged;

        if (
            token == 'null' ||
            token == 'undefined' ||
            !token ||
            this.helper.isTokenExpired(token)
        ) {
            this.authService.logout();
            this.router.navigate(['/auth/login']);

            return false;
        }

        const dataToken = this.helper.decodeToken(token);

        if (userLogged === null) {
            this.userLoggedService.setUserLogged(dataToken.user, token);
        }

        const screenSaved =
            localStorage.getItem(SCREEN_LOGGED) !== null
                ? JSON.parse(localStorage.getItem(SCREEN_LOGGED)!)
                : null;

        if (screenSaved != null) {
            this.screenLoggedService.setScreen(screenSaved);
        }

        return true;
    }
}
