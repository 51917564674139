<div class="ml-4 mr-4">
    <nz-page-header
        class="site-page-header"
        nzTitle="Tipos de artículos"
        nzSubtitle="Tipos de artículos en el sistema"
    >
        <nz-page-header-extra>
            <nz-space>
                <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    (click)="createType()"
                >
                    Añadir tipo
                </button>
            </nz-space>
        </nz-page-header-extra>
    </nz-page-header>

    <nz-table #articlesTypesTable [nzData]="articleTypes" [nzLoading]="loadingTable">
        <thead>
            <tr>
                <th>Nombre</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            @for (data of articlesTypesTable.data; track data) {
                <tr>
                    <td>{{ data.name }}</td>
                    <td>
                        <div class="buttonsDnDTableContainer">
							<img src="assets/icons/eye-icon.svg" class="h-6 w-6" (click)="seeType(data.id)" title="Ver">
							<nz-divider nzType="vertical"></nz-divider>
							<img src="assets/icons/edit-icon.svg" class="h-6 w-6" (click)="editType(data.id)" title="Editar">
							<nz-divider nzType="vertical"></nz-divider>
							<img src="assets/icons/xmark-icon.svg" class="h-6 w-6" (click)="deleteType(data.id)" title="Eliminar">
						</div>
                    </td>
                </tr>
            }
        </tbody>
    </nz-table>
</div>

<nz-drawer
    [nzClosable]="false"
    [nzVisible]="drawerVisible"
    nzPlacement="right"
    [nzTitle]="drawerTitle"
    (nzOnClose)="closeDrawer()"
    [nzWidth]="'500px'"
    [nzMaskClosable]="fieldsReadOnly"
>
    <ng-container *nzDrawerContent>
        <form (ngSubmit)="saveEditing()">

            <label>Nombre:</label>
            <input nz-input [value]="typeSelected.name ? typeSelected.name : ''" type="text" class="searchSelectors" [readOnly]="fieldsReadOnly" id="name" />

            @if (!fieldsReadOnly) {
                <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                >
                    Guardar
                </button>
                <nz-divider nzType="vertical"></nz-divider>
                <button
                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    (click)="cancelEditing()"
                >
                    Cancelar
                </button>
            } @else {
                <!-- No metadata at the moment -->
                <!--
                <nz-divider></nz-divider>
                <h3>Metadatos</h3>
                -->
            }
        </form>
    </ng-container>
</nz-drawer>
