export class StatusDto {
    id!: number;
    name!: string;
    abbreviation!: string;
    isStartingStatus?: boolean;
    isEndingStatus?: boolean;
    isPauseStatus?: boolean;
    cssClassName?: string;
    nextStatus?: number;
    condition?: string;
}
