import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { NzWaveDirective } from 'ng-zorro-antd/core/wave';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalService, NzModalTitleDirective } from 'ng-zorro-antd/modal';
import { LossDto } from 'src/app/shared/dto/loss.dto';
import { ɵNzTransitionPatchDirective } from 'ng-zorro-antd/core/transition-patch';
import { NzFormControlComponent, NzFormDirective, NzFormItemComponent } from 'ng-zorro-antd/form';
import { NzColDirective, NzRowDirective } from 'ng-zorro-antd/grid';
import { NzInputDirective, NzInputGroupComponent } from 'ng-zorro-antd/input';
import { NzOptionComponent, NzSelectComponent } from 'ng-zorro-antd/select';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { ArticleBatchDto } from 'src/app/shared/dto/article-batch.dto';
import { ArticleDto } from 'src/app/shared/dto/article.dto';
import { MeasurementunitsService } from 'src/app/private/services/measurementunits.service';
import { MeasurementUnitDto } from 'src/app/shared/dto/measurement-unit.dto';
import { MeasurementUnitResponseDto } from 'src/app/shared/dto/pagination/measurement-unit-response.dto';
import { LossesService } from 'src/app/private/services/losses.service';

@Component({
    selector: 'app-modal-create-losses',
    templateUrl: './modal-create-losses.component.html',
    styleUrls: ['./modal-create-losses.component.scss'],
    standalone: true,
    imports: [
        NzModalTitleDirective,
        ReactiveFormsModule,
        NzFormDirective,
        NzRowDirective,
        NzFormItemComponent,
        NzColDirective,
        NzFormControlComponent,
        NzInputGroupComponent,
        ɵNzTransitionPatchDirective,
        NzInputDirective,
        NzSelectComponent,
        NzOptionComponent,
        NzButtonComponent,
        NzWaveDirective,
        NzMessageModule
    ]
})

export class ModalCreateLossesComponent implements OnInit {
    @Output() emitService = new EventEmitter();
    isLoading: boolean = true;
    processId: number = 0;
    processDescription: string = '';
    article!: ArticleDto;
    articleBatch!: ArticleBatchDto;
    measurementUnits: MeasurementUnitDto[] = [];
    articleName: string = '';

    private selectedMeasurementId: number = 0;

    validateForm: FormGroup<{
        observations: FormControl<string>;
        quantity: FormControl<number>;
        selectedMeasurementUnit: FormControl<MeasurementUnitDto>;
    }> = this.fb.group({
        observations: ['', [Validators.required]],
        quantity: [0, [Validators.required]],
        selectedMeasurementUnit: [new MeasurementUnitDto(), [Validators.required]]
    });

    constructor(
        @Inject(NZ_MODAL_DATA) public data: any,
        private _modalService: NzModalService,
        private toast: NzMessageService,
        private lossesService: LossesService,
        private measurementUnitsService: MeasurementunitsService,
        private fb: NonNullableFormBuilder,
        private userLoggedService: UserLoggedService
    ) {
        this.processId = data?.data?.processId as number;
        this.processDescription = data?.data?.processDescription as string;
        this.article = (data?.data?.article as ArticleDto) ?? undefined;
        this.articleBatch = (data?.data?.articleBatch as ArticleBatchDto) ?? undefined;

        this.articleName = this.article.name ?? this.articleBatch.article?.name;

        this.isLoading = false;
    }

    async ngOnInit() {
        this.measurementUnits = await this.getMeasurementUnits();

        this.isLoading = false;
    }

    async submitForm(): Promise<void> {
        try {
            console.log('submit', this.validateForm.value);

            if (!this.validateForm.valid) {
                this.toast.error(`Porfavor, rellene los campos obligatorios`);
                return;
            }

            const loss: LossDto = {
                observations: this.validateForm.value.observations ?? '',
                quantity: this.validateForm.value.quantity ?? 0,
                productionOrderProcessId: this.processId,
                measurementUnitId: this.selectedMeasurementId,
                date: new Date(),
                createdById: this.userLoggedService.userLogged.id
            };

            const createdScrap: LossDto = await this.lossesService.create(loss);

            this.emitService.emit(createdScrap);
            this._modalService.closeAll();
        } catch (e: any) {
            this.toast.error(e.message);
        }
    }

    setMeasurement(measurementId: any) {
        this.selectedMeasurementId = +measurementId;
    }

    private async getMeasurementUnits(): Promise<MeasurementUnitDto[]> {
        try {
            const measurementsResponse: MeasurementUnitResponseDto = await this.measurementUnitsService.findAll();

            return measurementsResponse.measurementUnits;
        } catch (e: any) {
            this.toast.error(`${e.message}`);

            return [];
        }
    }
}
