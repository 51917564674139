import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { MachineDto } from 'src/app/shared/dto/machine.dto';
import { MachineResponseDto } from 'src/app/shared/dto/pagination/machine.dto';
import { BaseService } from 'src/app/shared/services/base.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';

@Injectable({
    providedIn: 'root',
})

export class MachineService extends BaseService {
    private machinesSubject = new BehaviorSubject<MachineDto[]>([]);
    public machines$ = this.machinesSubject.asObservable();

    private readonly socketService = inject(SocketService);

    constructor(httpClient: HttpClient) {
        super(httpClient, 'machines');
    }

    async setupWebSocket(): Promise<void> {
        try {
            this.machinesSubject.next((await this.findAll()).machines);
            this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.MACHINES_ROOM));
            this.socketService.getNotifications(Messages.MACHINES_CHANGED).subscribe(async () => {
                const response = await this.findAll();
                this.machinesSubject.next(response.machines);
            });
        } catch (e: any) {
            throw new Error(`Error al establecer conexión con el socket de Máquinas. Error: ${e.message}`);
        }
    }

    disconnectWebSocket(): void {
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.MACHINES_ROOM));
    }

    async getMachineAvailable(ids: number[]): Promise<MachineDto[]> {
        try {
            if (ids.length === 0) {
                throw new Error('La lista de ids de máquinas no puede estar vacía');
            }

            let params = new HttpParams().set('conditionType', 'NOT');

            for (let i = 0; i < ids.length; i++) {
                params = params.append('ids', ids[i]);
            }

            const request = this.httpClient.get<MachineDto[]>(this.url, { params: params });

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se ha podido obtener las máquinas disponibles. Error: ${e.message}`);
        }
    }

    async findAll(): Promise<MachineResponseDto> {
        try {
            const request = this.httpClient.get<MachineResponseDto>(`${this.url}/findAll`);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se ha encontrado ninguna máquina. Error: ${e.message}`);
        }
    }

    async create(machine: MachineDto): Promise<MachineDto> {
        try {
            if (machine === null || machine === undefined) {
                throw new Error('La máquina no es válida');
            }

            const request = this.httpClient.post<MachineDto>(`${this.url}`, machine);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se han podido crear la máquina. Error: ${e.message}`);
        }
    }

    async updateMachine(machine: MachineDto): Promise<MachineDto> {
        try {
            if (machine === null || machine === undefined) {
                throw new Error('La máquina no es válida');
            }

            const request = this.httpClient.patch<MachineDto>(`${this.url}/${machine.id}`, machine);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se ha podido actualizar la máquina. Error: ${e.message}`);
        }
    }

    async deleteMachine(id: number): Promise<MachineDto> {
        try {
            if (id <= 0) {
                throw new Error('El id de la máquina ha de ser mayor de 0');
            }

            const request = this.httpClient.delete<MachineDto>(`${this.url}/${id}`);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se ha podido eliminar la máquina. Error: ${e.message}`);
        }
    }
}
