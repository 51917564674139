import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { PauseReasonDto } from 'src/app/shared/dto/pause-reason.dto';
import { ProductionPauseDto } from 'src/app/shared/dto/production-pause.dto';
import { BaseService } from 'src/app/shared/services/base.service';

@Injectable({
    providedIn: 'root',
})
export class PauseService extends BaseService {
    constructor(httpClient: HttpClient) {
        super(httpClient, 'pauses');
    }
    async getByProcessId(processId: number): Promise<ProductionPauseDto[]> {
        try {
            if (!processId || processId <= 0) {
                throw Error('El proceso ha de ser mayor o igual que cero.');
            }

            const pausesObservable = this.httpClient.get<ProductionPauseDto[]>(
                `${this.url}/findByOrderId/${processId}`
            );

            const pauses = await lastValueFrom(pausesObservable);

            for (let i = 0; i < pauses.length; i++) {
                if (pauses[i].endDate) {
                    pauses[i].endDate = new Date(pauses[i].endDate.toString());
                }

                pauses[i].startDate = new Date(pauses[i].startDate.toString());
            }

            return pauses;
        } catch (e: any) {
            throw new Error(`No se han podido obtener las pausas del proceso ${processId ?? ''}. Error: ${e.message}`);
        }
    }

    async getReasons(): Promise<PauseReasonDto[]> {
        try {
            const scrapReasonsObservable = this.httpClient.get<PauseReasonDto[]>(`${this.url}/getReasons`);

            return await lastValueFrom(scrapReasonsObservable);
        } catch (e: any) {
            throw new Error(`No se han podido obtener las razones de pausa. Error: ${e.message}`);
        }
    }
}
