import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ProductionRouteResponseDto } from 'src/app/shared/dto/pagination/production-route-response.dto';
import { ProductionRouteDto } from 'src/app/shared/dto/production-route.dto';
import { BaseService } from 'src/app/shared/services/base.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';

@Injectable({
    providedIn: 'root',
})
export class ProductionRoutesService extends BaseService {
    private producitonRoutesSubject = new BehaviorSubject<ProductionRouteDto[]>([]);
    public productionRoutes$ = this.producitonRoutesSubject.asObservable();

    private readonly socketService = inject(SocketService);

    constructor(httpClient: HttpClient) {
        super(httpClient, 'production-routes');
    }

    async setupWebSocket(): Promise<void> {
        try {
            this.producitonRoutesSubject.next((await this.findAll()).productionRoutes);
            this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.PRODUCTION_ROUTES_ROOM));
            this.socketService.getNotifications(Messages.PRODUCTION_ROUTES_CHANGED).subscribe(async () => {
                const response = await this.findAll();
                this.producitonRoutesSubject.next(response.productionRoutes);
            });
        } catch (e: any) {
            throw new Error(`Error al establecer conexión con el socket. Error: ${e.message}`);
        }
    }

    disconnectWebSocket(): void {
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.PRODUCTION_ROUTES_ROOM));
    }

    async findAll(): Promise<ProductionRouteResponseDto> {
        try {
            const request = this.httpClient.get<ProductionRouteResponseDto>(`${this.url}/findAll`);

            const response: ProductionRouteResponseDto = await lastValueFrom(request);

            if (!response) {
                throw null;
            }

            return response;
        } catch (e: any) {
            throw new Error(`No se ha encontrado ninguna ruta de producción. Error: ${e.message}`);
        }
    }

    async createRoute(route: ProductionRouteDto): Promise<ProductionRouteDto> {
        try {
            const request = this.httpClient.post<ProductionRouteDto>(`${this.url}/create`, route);

            const createdRoute: ProductionRouteDto = await lastValueFrom(request);

            if (!createdRoute) {
                throw null;
            }

            return createdRoute;

        } catch (e: any) {
            throw new Error(`No se ha podido crear la ruta de producción. Error: ${e.error.message}`);
        }
    }

    async updateRoute(route: ProductionRouteDto): Promise<ProductionRouteDto> {
        try {
            const request = this.httpClient.put<ProductionRouteDto>(`${this.url}/update`, route);

            const updatedRoute: ProductionRouteDto = await lastValueFrom(request);

            if (!updatedRoute) {
                throw null;
            }

            return updatedRoute;

        } catch (e: any) {
            throw new Error(`No se ha podido actualizar la ruta de producción ${route.id}. Error: ${e.message}`);
        }
    }

    async deleteRoute(routeId: number): Promise<ProductionRouteDto> {
        try {
            const request = this.httpClient.delete<ProductionRouteDto>(`${this.url}/${routeId}`);

            const response: ProductionRouteDto = await lastValueFrom(request);

            if (!response) {
                throw null;
            }

            return response;
        } catch (e: any) {
            throw new Error(`No se ha podido eliminar la ruta de producción ${routeId}. Error: ${e.message}`);
        }
    }
}
