import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import _ from "lodash";
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageModule, NzMessageService } from "ng-zorro-antd/message";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpaceModule } from "ng-zorro-antd/space";
import { NzTableModule } from "ng-zorro-antd/table";
import { UserLoggedService } from "src/app/core/services/userLogged.service";
import { ArticleDto } from "src/app/shared/dto/article.dto";
import { MeasurementUnitDto } from "src/app/shared/dto/measurement-unit.dto";
import { UserDto } from "src/app/shared/dto/user.dto";
import { ArticlesService } from "../../services/articles.service";
import { MeasurementunitsService } from "../../services/measurementunits.service";
import { Subject, takeUntil } from "rxjs";
import { PackagingDto } from "src/app/shared/dto/packaging.dto";
import { PackagingsService } from "../../services/packagings.service";

@Component({
    selector: 'app-packagings',
    templateUrl: './packagings.component.html',
    styleUrl: './packagings.component.scss',
    standalone: true,
    imports: [
        NzPageHeaderModule,
        NzSpaceModule,
        NzTableModule,
        NzDividerModule,
        NzDrawerModule,
        NzDatePickerModule,
        FormsModule,
        NzMessageModule,
        NzDropDownModule,
        NzSelectModule,
        NzInputModule,
        FormsModule,
        NzCheckboxModule,
    ],
})
export class PackagingsComponent implements OnInit, OnDestroy {

    readonly packagingsService = inject(PackagingsService);
	readonly articlesService = inject(ArticlesService);
    readonly message = inject(NzMessageService);
    readonly userLoggedService = inject(UserLoggedService);
    readonly measurementUnitService = inject(MeasurementunitsService);
    
    packagings: PackagingDto[] = [];
    drawerVisible = false;
    drawerTitle = 'Placeholder';
    fieldsReadOnly = false;
    packagingSelected: PackagingDto = new PackagingDto();
    createdAt: Date = new Date();
    updatedAt: Date = new Date();
    isNewPackaging = false;

    currentUser: UserDto = new UserDto();
    
    loadingTable: boolean = true;

	articles: ArticleDto[] = [];
	selectedArticle: ArticleDto = new ArticleDto();
    measurements: MeasurementUnitDto[] = [];
    selectedMeasurement: MeasurementUnitDto = new MeasurementUnitDto();

    private unsubscribe$ = new Subject<void>();

    async ngOnInit() {
        this.currentUser = this.userLoggedService.userLogged;

        await this.packagingsService.setupWebSocket();
        this.packagingsService.packagings$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (packagings) => {
                    this.packagings = packagings;
                },
                error: () => {
                    this.message.error('Error cargando formatos');
                }
            });

		await this.articlesService.setupWebSocket();
        this.articlesService.articles$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (articles) => {
                    this.articles = articles;
                },
                error: () => {
                    this.message.error('Error cargando artículos');
                }
            });

        await this.measurementUnitService.setupWebSocket();
        this.measurementUnitService.measurementUnits$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (measures) => {
                    this.measurements = measures;
                    if (this.measurements.length > 0) {
                        this.selectedMeasurement = this.measurements[0];
                    } else {
                        this.selectedMeasurement = new MeasurementUnitDto();
                    }
                },
                error: () => {
                    this.message.error('Error cargando unidades de medida');
                }
            });

        this.loadingTable = false;
    }

    ngOnDestroy(): void {
        this.packagingsService.disconnectWebSocket();
        this.measurementUnitService.disconnectWebSocket();
        this.articlesService.disconnectWebSocket();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    createPackaging() {
        this.fieldsReadOnly = false;
        this.packagingSelected = new PackagingDto();
        this.drawerTitle = 'Creando nuevo formato';
        this.isNewPackaging = true;
        
        this.openDrawer();
    }

    seePackaging(id: number) {
        this.fieldsReadOnly = true;
        this.packagingSelected = this.packagings.find((a) => a.id === id) as PackagingDto;
        this.drawerTitle = 'Viendo formato ' + this.packagingSelected?.name;
        this.setData();
        this.openDrawer();
    }

    editPackaging(id: number) {
        this.fieldsReadOnly = false;
        this.packagingSelected = this.packagings.find((c) => c.id === id) as PackagingDto;
        this.drawerTitle = 'Editando formato ' + this.packagingSelected?.name;
        this.isNewPackaging = false;
        this.setData();
        this.openDrawer();
    }

    deletePackaging(id: number) {
        if (window.confirm('¿Estás seguro de que quieres eliminar este formato? Esta acción no se puede deshacer.')) {
            this.packagingsService.delete(id).subscribe({
                next: (packaging: PackagingDto) => {
                    try {
                        if (packaging == undefined) {
                            throw new Error('Packaging not found');
                        }

                        const index = this.packagings.findIndex((a) => a.id === id);

                        if (index < 0) {
                            this.message.create('error', `No se ha podido eliminar el formato ${packaging.name} con id ${id}, formato no encontrado`);
                            throw new Error('Packaging not found');
                        }

                        this.packagings.splice(index, 1);
                        const data = [...this.packagings];
                        this.packagings = data;

                        this.message.create('success', 'Formato eliminado correctamente');
                    } catch (error) {
                        console.error(error);
                        this.message.create('error', 'Error al eliminar el formato');
                    }
                },
                error: (error) => {
                    console.error(error);
                    this.message.create('error', 'Error al eliminar el formato, error de servidor');
                }
            });
        } else {
            console.log('Cancelando eliminación de formato ' + id);
        }
    }

    setData() {        
        this.selectedMeasurement = this.measurements.find((m) => m.id === this.packagingSelected.measurementUnitId) as MeasurementUnitDto;
        this.selectedArticle = this.articles.find((a) => a.id === this.packagingSelected.articleId) as ArticleDto;
    }

    openDrawer() {
        this.drawerVisible = true;
    }

    closeDrawer() {
        this.drawerVisible = false;
    }

    saveEditing() {
        const customPackagingDto: PackagingDto = {
            id: this.packagingSelected.id,
            name: (<HTMLInputElement>document.getElementById('name'))?.value ?? '',
            externalReference: (<HTMLInputElement>document.getElementById('externalReference'))?.value ?? '',
            quantityPerPackaging: (<HTMLInputElement>document.getElementById('quantityPerPackaging'))?.value
                ? +((<HTMLInputElement>document.getElementById('quantityPerPackaging'))?.value)
                : 0,
            measurementUnit: this.selectedMeasurement,
            measurementUnitId: this.selectedMeasurement.id,
			articleId: this.selectedArticle.id,
			article: this.selectedArticle
        };

        if (!this.validForm(customPackagingDto)) {
            return;
        }

        if (this.isNewPackaging) {
            this.packagingsService.save(customPackagingDto).subscribe({
                next: (packaging: PackagingDto) => {
                    try {
                        if (packaging == undefined) {
                            throw new Error('Error creating packaging');
                        }

                        this.packagings.push(packaging);

                        const data = [...this.packagings];
                        this.packagings = data;

                        this.message.create('success', 'Formato creado correctamente');
                        this.closeDrawer();
                    } catch (error) {
                        console.error('Error creating packaging', error);
                        this.message.create('error', 'Error al crear el formato');
                    }
                },
                error: (error) => {
                    console.error('Error creating packaging', error);
                    this.message.create('error', 'Error al crear el formato, error de servidor');
                }
            });
        } else {
            this.packagingsService.update(customPackagingDto.id, customPackagingDto).subscribe({
                next: (packaging: PackagingDto) => {
                    try {
                        if (packaging == undefined) {
                            throw new Error('Error updating packaging');
                        }

                        const index = this.packagings.findIndex((a) => a.id === packaging.id);

                        if (index < 0) {
                            this.message.create('error', `No se ha podido actualizar el formato ${customPackagingDto.name} con id ${customPackagingDto.id}, formato no encontrado`);
                            throw new Error('Packaging not found');
                        }

                        this.packagings[index] = packaging;
                        const data = [...this.packagings];
                        this.packagings = data;

                        this.message.create('success', 'Formato actualizado correctamente');
                        
                        this.closeDrawer();
                    } catch (error) {
                        console.error('Error updating packaging', error);
                        this.message.create('error', 'Error al actualizar el formato');
                    }
                },
                error: (error) => {
                    console.error('Error updating packaging', error);
                    this.message.create('error', 'Error al actualizar el formato, error de servidor');
                }
            });
        }
    }

    cancelEditing() {
        this.closeDrawer();
    }

    validForm(packaging: PackagingDto): boolean {
        if (!packaging.name) {
            this.message.create('error', 'El nombre del formato es obligatorio');
            return false;
        }
                
        if (packaging.quantityPerPackaging === undefined || packaging.quantityPerPackaging === null) {
            this.message.create('error', 'La cantidad del formato es obligatoria');
            return false;
        }
        
        if (packaging.quantityPerPackaging < 0) {
            this.message.create('error', 'La cantidad del formato no puede ser negativa');
            return false;
        }

		if (!packaging.articleId || packaging.articleId <= 0) {
			this.message.create('error', 'Se ha de seleccionar un artículo para el formato');
			return false;
		}

		if (!packaging.measurementUnitId || packaging.measurementUnitId <= 0) {
			this.message.create('error', 'Se ha de seleccionar una unidad de medida para el formato');
			return false;
		}

        return true;
    }

    changeMeasure(id: number) {
        this.selectedMeasurement = this.measurements.find((m) => m.id === id) as MeasurementUnitDto;
    }

	changeArticle(id: number) {
        this.selectedArticle = this.articles.find((a) => a.id === id) as ArticleDto;
    }
}
