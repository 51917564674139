<div class="ml-4 mr-4">
    <nz-page-header class="site-page-header" nzTitle="Configuración" nzSubtitle="Configuración del sistema"></nz-page-header><br>
    <div>
        <nz-divider></nz-divider>
        @for (conf of configurations; track conf.id) {
            @switch (conf.type) {
                @case (configurationsTypesEnum.STRING) {
                    <div>
                        <label>{{conf.name}}</label>
                        <input nz-input [(ngModel)]="conf.value" type="text" class="searchSelectors" [id]="conf.key"
                            [title]="conf.description ? conf.description : ''" />
                    </div>
                    <nz-divider></nz-divider>
                }
                @case (configurationsTypesEnum.NUMBER) {
                    <div>
                        <label>{{conf.name}}</label>
                        <input nz-input [(ngModel)]="conf.value" type="number" class="searchSelectors" [id]="conf.key"
                            [title]="conf.description ? conf.description : ''" />
                    </div>
                    <nz-divider></nz-divider>
                }
                @case (configurationsTypesEnum.BOOLEAN) {
                    <div>
                        <nz-switch [(ngModel)]="conf.value" [id]="conf.key"
                            [title]="conf.description ? conf.description : ''"
                            [class]="conf.value ? 'switchOn' : 'switchOff'"
                            ></nz-switch>&nbsp;
                        <label>{{conf.name}}</label>
                    </div>
                    <nz-divider></nz-divider>
                }
            }
        }
    </div>
    <button
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        type="button" (click)="saveConfiguration()">
        <div class="flex">
            <img src="assets/icons/save-icon.svg" class="h-4 w-4" style="filter: invert(1);">&nbsp;
            Guardar configuración
        </div>
    </button>
</div>