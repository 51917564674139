import { CustomerDto } from './customer.dto';
import { ForkEdgeDto } from './fork-edge.dto';
import { PriorityDto } from './priority.dto';
import { ProductionOrderProcessDto } from './production-order-process.dto';
import { ProductionRouteDto } from './production-route.dto';
import { StatusDto } from './status.dto';
import { UserDto } from './user.dto';

export class ProductionOrderDto {
    id!: number;
    description!: string;
    priorityId!: number;
    priority?: PriorityDto;
    reference!: string;
    startDate!: Date;
    deadlineDate!: Date;
    confirmationDate!: Date;
    deliveryDate!: Date;
    customers?: CustomerDto;
    customerId!: number;
    productionOrderProcesses?: ProductionOrderProcessDto[];
    productionRoute?: ProductionRouteDto;
    productionRouteId?: number;
    forkEdges?: ForkEdgeDto[];
    statusId!: number;
    status?: StatusDto;
    expand?: boolean = false;
    operators!: UserDto[];
}
