import { __awaiter, __decorate, __param } from 'tslib';
import { InjectionToken, Inject, Optional, ɵɵdefineInjectable, ɵɵinject, Injectable, NgModule } from '@angular/core';
import { Subscription, BehaviorSubject, fromEvent, interval } from 'rxjs';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';

/**
 * InjectionToken for specifing ConnectionService options.
 */
const ConnectionServiceOptionsToken = new InjectionToken('ConnectionServiceOptionsToken');
const DEFAULT_CONNECTION_STATE = {
    hasInternetAccess: true,
    hasNetworkConnection: window.navigator.onLine
};
const DEFAULT_HEART_BEAT_INTERVAL = 1000;
// export const DEFAULT_HEART_BEAT_URL = 'https://jsonplaceholder.typicode.com';
const DEFAULT_HEART_BEAT_URL = 'http://localhost:3000';
const DEFAULT_HEART_BEAT_RETRY_INTERVAL = 1000;
var HTTP_REQUEST_METHODS;
(function (HTTP_REQUEST_METHODS) {
    HTTP_REQUEST_METHODS["HEAD"] = "head";
    HTTP_REQUEST_METHODS["GET"] = "get";
    HTTP_REQUEST_METHODS["POST"] = "post";
    HTTP_REQUEST_METHODS["PUT"] = "put";
    HTTP_REQUEST_METHODS["OPTIONS"] = "options";
})(HTTP_REQUEST_METHODS || (HTTP_REQUEST_METHODS = {}));
const DEFAULT_OPTIONS = {
    enableHeartbeat: false,
    heartbeatUrl: DEFAULT_HEART_BEAT_URL,
    heartbeatInterval: DEFAULT_HEART_BEAT_INTERVAL,
    heartbeatRetryInterval: 1000,
    requestMethod: HTTP_REQUEST_METHODS.HEAD
};
let ConnectionService = class ConnectionService {
    constructor(http, options) {
        this.http = http;
        this.currentState = DEFAULT_CONNECTION_STATE;
        this.serviceOptions = DEFAULT_OPTIONS;
        this.subscription = new Subscription();
        this.httpSubscription = new Subscription();
        this.stateChanged$ = new BehaviorSubject(DEFAULT_CONNECTION_STATE);
        // TODO: Token useValue in providers not working.
        this.serviceOptions = Object.assign(Object.assign({}, DEFAULT_OPTIONS), options);
        this.checkNetworkState();
        if (this.serviceOptions.enableHeartbeat) {
            this.checkInternetState();
        }
    }
    checkNetworkState() {
        this.subscription.add(fromEvent(window, 'online').subscribe(() => {
            this.currentState.hasNetworkConnection = true;
            this.checkInternetState();
            this.publishState();
        }));
        this.subscription.add(fromEvent(window, 'offline').subscribe(() => {
            this.currentState.hasNetworkConnection = false;
            this.checkInternetState();
            this.publishState();
        }));
    }
    checkInternetState() {
        if (this.serviceOptions.enableHeartbeat) {
            this.subscription = interval(3000).pipe(switchMap(() => __awaiter(this, void 0, void 0, function* () {
                return this.http[this.serviceOptions.requestMethod || HTTP_REQUEST_METHODS.HEAD](this.serviceOptions.heartbeatUrl || DEFAULT_HEART_BEAT_URL, { responseType: 'text' }).subscribe({
                    next: (data) => {
                        this.currentState.hasInternetAccess = true;
                        this.publishState();
                    },
                    error: (err) => {
                        this.currentState.hasInternetAccess = false;
                        this.publishState();
                        throw err;
                    },
                });
            }))).subscribe(res => {
            });
            // this.httpSubscription = timer(0, this.serviceOptions.heartbeatInterval || DEFAULT_HEART_BEAT_INTERVAL)
            //   .pipe(
            //     switchMap(async () => this.http[this.serviceOptions.requestMethod || HTTP_REQUEST_METHODS.HEAD](this.serviceOptions.heartbeatUrl || DEFAULT_HEART_BEAT_URL,
            //       { responseType: 'text' })),
            //     retryWhen(errors =>
            //       errors.pipe(
            //         // log error message
            //         tap(val => {
            //           this.currentState.hasInternetAccess = false;
            //           this.publishState();
            //           throw errors;
            //         }),
            //         // restart after 5 seconds
            //         delay(this.serviceOptions.heartbeatRetryInterval || DEFAULT_HEART_BEAT_RETRY_INTERVAL)
            //       )
            //     )
            //   )
            //   .subscribe(result => {
            //     this.currentState.hasInternetAccess = true;
            //     this.publishState();
            //   });
        }
        else {
            this.currentState.hasInternetAccess = false;
            this.publishState();
        }
    }
    publishState() {
        this.stateChanged$.next(this.currentState);
    }
    /**
   * Monitor Network & Internet connection status by subscribing to this observer. If you set "reportCurrentState" to "false" then
   * function will not report current status of the connections when initially subscribed.
   * @param reportCurrentState Report current state when initial subscription. Default is "true"
   */
    monitor(options) {
        if (options) {
            this.serviceOptions = Object.assign(Object.assign({}, this.serviceOptions), options);
        }
        if (this.serviceOptions.enableHeartbeat) {
            this.checkInternetState();
        }
        return this.stateChanged$;
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
};
ConnectionService.ctorParameters = () => [
    { type: HttpClient },
    { type: undefined, decorators: [{ type: Inject, args: [ConnectionServiceOptionsToken,] }, { type: Optional }] }
];
ConnectionService.ɵprov = ɵɵdefineInjectable({ factory: function ConnectionService_Factory() { return new ConnectionService(ɵɵinject(HttpClient), ɵɵinject(ConnectionServiceOptionsToken, 8)); }, token: ConnectionService, providedIn: "root" });
ConnectionService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __param(1, Inject(ConnectionServiceOptionsToken)), __param(1, Optional())
], ConnectionService);

let ConnectionServiceModule = class ConnectionServiceModule {
};
ConnectionServiceModule = __decorate([
    NgModule({
        imports: [HttpClientModule],
        providers: [ConnectionService]
    })
], ConnectionServiceModule);

/*
 * Public API Surface of ng-connection-service
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ConnectionService, ConnectionServiceModule, ConnectionServiceOptionsToken, DEFAULT_CONNECTION_STATE, DEFAULT_HEART_BEAT_INTERVAL, DEFAULT_HEART_BEAT_RETRY_INTERVAL, DEFAULT_HEART_BEAT_URL, DEFAULT_OPTIONS, HTTP_REQUEST_METHODS };

