import { listOfValues } from '../models/listOfValues.model';

export const datesAreOnSameDay = (first: Date, second: Date) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

export const isEmpty = (value: any): boolean => {
    if (typeof value === 'object') {
        return Object.entries(value).length === 0;
    } else if (typeof value === 'string') {
        return value === undefined || value === null || value === '';
    } else {
        return false;
    }
};

export function isNumber(cadena: string): boolean {
    if (cadena.trim() === '') {
        return false; // O manejar de cualquier otra manera que necesites
    }
    return !isNaN(parseFloat(cadena)) && isFinite(Number(cadena));
}

export function isEmail(cadena: string): boolean {
    return String(cadena)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
        ? true
        : false;
}

export function extractValueObjectDictionary(list: any[]) {
    const objects: any[] = [];
    list.map((element) => {
        objects.push({ name: element.name, id: element.id });
    });
    return objects;
}

export function extractValuesToList(list: any[]) {
    const listOfValue: listOfValues[] = [];

    list.map((element) => {
        listOfValue.push({ id: element.id, name: element.text });
    });
    return listOfValue;
}

export function isValidIpAddress(ip: string): boolean {
    const regex = /^(\d{1,3}\.){3}\d{1,3}$/;
    if (!regex.test(ip)) {
        return false;
    }

    const segments = ip.split('.').map(Number);
    for (const segment of segments) {
        if (segment < 0 || segment > 255 || !Number.isInteger(segment)) {
            return false;
        }
    }

    return true;
}