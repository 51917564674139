import { ProfileDto } from './profile.dto';

export class UserDto {
    id!: number;
    name!: string;
    firstname!: string;
    surname!: string;
    username!: string;
    password?: string;
    email!: string;
    rfid!: string;
    createdAt?: Date;
    updatedAt?: Date;
    createdBy!: number;
    updatedBy!: number;
    profileId?: number;
    profile?: ProfileDto;
    externalAccess!: boolean;
    code!: number;
    codeExpirationDate!: Date;
}
