@if (isLoading) {
<div class="w-full h-full flex items-center justify-center">
	<svg role="status" class="inline w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
		viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
			fill="currentColor" />
		<path
			d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
			fill="currentFill" />
	</svg>
</div>
} @else {
<div class="px-10">
	<nz-page-header nzTitle="Orden - {{ productionOrderId }}" class="p-0 m-0 mt-10">
		<nz-page-header-extra>
			<div class="flex items-center">
				<!-- TODO: COMENTADO PARA IMPLEMENTACIÓN DE ENVIOS PARCIALES EN LA V2
          @if (order.sendPartials) {
            <button
              nz-button
              nzType="primary"
              class="flex items-center font-medium rounded-md ml-4">
              ACTIVAR METROS PARCIALES
            </button>
          }
          @else {
          <button
            nz-button
            nzType="primary"
            nzDanger
            class="flex items-center font-medium rounded-md ml-4">
            DESACTIVAR METROS PARCIALES
          </button>
        }
        END TODO -->
				<button nz-button nzType="primary"
					class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 flex items-center font-medium rounded-md ml-4">
					ACTUALIZAR ORDEN
				</button>
			</div>
		</nz-page-header-extra>
		<nz-page-header-tags> </nz-page-header-tags>
		<nz-breadcrumb nz-page-header-breadcrumb>
			<nz-breadcrumb-item>
				<a [routerLink]="'/production-orders'">
					<span>Órdenes</span>
				</a>
			</nz-breadcrumb-item>
			<nz-breadcrumb-item>
				<a [routerLink]="['/production-orders']" [queryParams]="{ customerName: order.customers?.name }">
					<span>{{ order.customers?.name }}</span>
				</a>
			</nz-breadcrumb-item>
			<nz-breadcrumb-item>Orden - {{ order.description }}</nz-breadcrumb-item>
		</nz-breadcrumb>
		<nz-page-header-content>
			<nz-descriptions nzSize="small" [nzColumn]="4">
				<nz-descriptions-item nzTitle="Núm. Orden" [nzSpan]="1"><b>{{ productionOrderId
						}}</b></nz-descriptions-item>
				<nz-descriptions-item nzTitle="Referencia" [nzSpan]="1"><b>{{ order.reference || 'Sin referencia' }}</b></nz-descriptions-item>

			</nz-descriptions>
			<nz-descriptions nzSize="small" [nzColumn]="4">
				<nz-descriptions-item nzTitle="Cliente" [nzSpan]="1"><b>{{ order.customers?.name
						}}</b></nz-descriptions-item>
				<nz-descriptions-item nzTitle="Descripción" [nzSpan]="2"><b>{{ order.description
						}}</b></nz-descriptions-item>
			</nz-descriptions>
			<nz-descriptions nzSize="small" [nzColumn]="4">
				<nz-descriptions-item nzTitle="Ruta" [nzSpan]="1">
					<b>{{
						order.productionRoute
						? order.productionRoute!.name
						: "SIN RUTA"
						}}</b>
				</nz-descriptions-item>
				<!-- <nz-descriptions-item nzTitle="Usa Cantidades Parciales" [nzSpan]="1">
					<span [class]="
                                'bg-red-200 text-red-700' +
                                ' text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800'
                            ">
						{{order.sendPartials ? 'ACTIVADO': 'DESACTIVADO' }}  -->
                        <!-- DESACTIVADO
					</span>
				</nz-descriptions-item> -->
				<nz-descriptions-item nzTitle="Prioridad" [nzSpan]="1">
					<span [class]="order.priority?.cssClassName">
						{{ order.priority?.name }}
					</span>
				</nz-descriptions-item>
			</nz-descriptions>
            <nz-descriptions nzSize="small" [nzColumn]="4">
                <nz-descriptions-item nzTitle="Fecha" [nzSpan]="1"><b>{{
                    order.startDate | date: "dd/MM/YYYY"
                    }}</b></nz-descriptions-item>
				<nz-descriptions-item nzTitle="Fecha fin" [nzSpan]="1">
					<b>{{
						order.deadlineDate
						? (order.deadlineDate | date: "dd/MM/YYYY")
						: "Sin fecha de fin"
						}}</b>
				</nz-descriptions-item>
				<nz-descriptions-item nzTitle="Fecha de confirmación" [nzSpan]="1"><b>{{ order.confirmationDate | date: "dd/MM/YYYY"
						}}</b></nz-descriptions-item>
				<nz-descriptions-item nzTitle="Fecha de entrega" [nzSpan]="1"><b>{{ order.deliveryDate | date: "dd/MM/YYYY"
						}}</b></nz-descriptions-item>
			</nz-descriptions>
		</nz-page-header-content>
	</nz-page-header>

	<div class="mt-12">
		<div class="flex items-center mb-5 justify-between">
			<div class="flex items-center">
				<svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
						d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
				</svg>
				<p class="text-[15px] font-semibold p-0 m-0">
					Bifurcaciones de la orden
				</p>
			</div>
			<div class="flex items-center">
				<label nz-checkbox [(ngModel)]="zoomEnabled">ACTIVAR ZOOM</label>
				<button nz-button class="flex items-center font-medium rounded-md ml-2" (click)="center$.next(true)">
					CENTRAR
				</button>
			</div>
		</div>

		<div class="items-center max-w-full">
			<div class="tree">
				<ngx-graph [links]="links" [nodes]="nodes" [clusters]="clusters" [layout]="layout" [curve]="curve"
					[draggingEnabled]="draggingEnabled" [panningEnabled]="panningEnabled" [enableZoom]="zoomEnabled"
					[zoomSpeed]="zoomSpeed" [minZoomLevel]="minZoomLevel" [maxZoomLevel]="maxZoomLevel"
					[panOnZoom]="panOnZoom" [autoZoom]="autoZoom" [autoCenter]="autoCenter"
					[center$]="center$">
					<ng-template #defsTemplate>
						<svg:marker id="arrow" viewBox="0 -5 10 10" refX="8" refY="0" markerWidth="4" markerHeight="4"
							orient="auto">
							<svg:path d="M0,-5L10,0L0,5" class="arrow-head" />
						</svg:marker>
					</ng-template>

					<ng-template #clusterTemplate let-cluster>
						<svg:g class="node cluster">
							<svg:rect rx="5" ry="5" [attr.width]="cluster.dimension.width"
								[attr.height]="cluster.dimension.height" [attr.fill]="cluster.data.color" />
						</svg:g>
					</ng-template>

					<ng-template #nodeTemplate let-node>
						<svg:g class="node" (click)="navigateToOperacion(node.id)" style="cursor: pointer">
							<svg:rect rx="5" [attr.width]="node.dimension.width" [attr.height]="node.dimension.height"
								[attr.fill]="node.data.colors.background" />
							<svg:text alignment-baseline="central" [attr.x]="10" font-weight="bold"
								[attr.y]="node.dimension.height / 2" [attr.fill]="node.data.colors.textColor">
								{{ node.label }}
							</svg:text>
						</svg:g>
					</ng-template>

					<ng-template #linkTemplate let-link>
						<svg:g class="edge">
							<svg:path class="line" stroke-width="2" marker-end="url(#arrow)"></svg:path>
							<svg:text class="edge-label" text-anchor="middle">
								<textPath class="text-path" [attr.href]="'#' + link.id" [style.dominant-baseline]="
                                            link.dominantBaseline
                                        " startOffset="50%">
									{{ link.label }}
								</textPath>
							</svg:text>
						</svg:g>
					</ng-template>
				</ngx-graph>
			</div>
		</div>
	</div>

	<div class="mt-12 pb-20">
		<div class="flex items-center mb-5">
			<svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
				xmlns="http://www.w3.org/2000/svg">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
					d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
			</svg>
			<p class="text-[15px] font-semibold p-0 m-0">
				Procesos productivos
			</p>
		</div>
		<nz-table #innterTable [nzData]="order.productionOrderProcesses?order.productionOrderProcesses:[]"
			nzTableLayout="auto" [nzBordered]="true" nzSize="small" [nzFrontPagination]="false"
			[nzShowPagination]="false">
			<thead>
				<tr>
					<th>Orden Fab.</th>
					<th>Proceso</th>
					<th>Procesos Hijos</th>
					<th>Estado</th>
					<th>Máquina</th>
					<th>Inicio</th>
					<th>Fin</th>
					<th>Duración</th>
					<th>Acciones</th>
				</tr>
			</thead>
			<tbody>
				@for (process of innterTable.data; track process; let i = $index) {
				<tr>
					<td class="text-right align-middle" style="width: 50px !important">
						<div class="flex items-center justify-end">
							<p>{{ process.id }}</p>
						</div>
					</td>
					<td class="uppercase" style="width: 130px !important">
						{{ process.productionProcess!.name }}
					</td>
					<td class="uppercase align-middle">
						<p>MOSTRAR STRING CON LOS PROCESOS HIJOS</p>
					</td>
					<td class="text-center align-middle" style="width: 130px !important">
						<span [ngClass]="process.status?.cssClassName">
							{{ process.status?.name ?? '' }}
						</span>
					</td>
					<td class="uppercase">
						{{
						process?.machine
						? process.machine!.name
						: "Cualquiera de " +
						process.productionProcess!.name
						}}
					</td>
					<td class="capitalize">
						{{
						!process.productionTime
						? "-"
						: (process.productionTime.startDate
						| date
						: (datesAreOnSameDay(
						process.productionTime
						.startDate
						)
						? "HH:mm:ss"
						: "dd/MM/YYYY HH:mm:ss
						a (O)"))
						}}
					</td>
					<td class="capitalize">
						{{
						!process.productionTime ||
						process.productionTime.endDate === null
						? "-"
						: (process.productionTime.endDate
						| date
						: (datesAreOnSameDay(
						process.productionTime
						.endDate
						)
						? "HH:mm:ss"
						: "dd/MM/YYYY HH:mm:ss a (O)"))
						}}
					</td>
					<td class="capitalize">
						{{
						!process.productionTime ||
						process.productionTime.endDate === null
						? "-"
						: getTotalTime(process)
						}}
					</td>
					<td>
						<div class="flex items-center">
							<button nz-button class="flex items-center font-medium rounded-md ml-4" (click)="navigateToOperacion(process.id!)" nzType="default">
								<span><svg class="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
											d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z">
										</path>
									</svg></span>Ver
							</button>
							<!--TODO: COMENTADO PARA IMPLEMENTACIÓN DE ENVIOS PARCIALES EN LA V2
                                                    @if (order.sendPartials && ) {
                                                      <button
                                                        nz-button
                                                        nzType="primary"
                                                        class="flex items-center font-medium rounded-md ml-4"
                                                        [nzLoading]="inMarcarPendiente">
                                                        <span
                                                          nz-icon>
                                                          <svg class="w-5 h-5 mr-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M814-452 162-178q-15 6-28.5-2.5T120-205v-550q0-16 13.5-24.5T162-782l652 274q18 8 18 28t-18 28ZM180-253l544-227-544-230v168l242 62-242 60v167Zm0 0v-457 457Z"/></svg>
                                                        </span>
                                                        Avanzar Cantidad
                                                      </button>
                                                    }
                                                    END TODO-->
							<div>
								<!--TODO: IF-->
								<button nz-button class="flex items-center font-medium rounded-md ml-4" nzType="primary"
									nzDanger [nzLoading]="inFinalizar">
									<span nz-icon nzType="check-circle" nzTheme="outline"></span>Finalizar
								</button>
							</div>
						</div>
					</td>
				</tr>
				}
			</tbody>
		</nz-table>
	</div>
</div>
}
