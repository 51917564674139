import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTableModule } from 'ng-zorro-antd/table';
import { Subject, takeUntil } from 'rxjs';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { CustomerDto } from 'src/app/shared/dto/customer.dto';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { IdentityDocumentTypesEnum } from 'src/app/shared/enums/identity-document-types.enum';
import { CustomersService } from '../../services/customers.service';

@Component({
    selector: 'app-customers',
    standalone: true,
    imports: [
        NzPageHeaderModule,
        NzSpaceModule,
        NzTableModule,
        NzDividerModule,
        NzDrawerModule,
        NzDatePickerModule,
        FormsModule,
        NzMessageModule,
        NzDropDownModule,
        NzSelectModule,
    ],
    templateUrl: './customers.component.html',
    styleUrl: './customers.component.scss',
})
export class CustomersComponent implements OnInit, OnDestroy {
    readonly customerService = inject(CustomersService);
    readonly message = inject(NzMessageService);
    readonly userLoggedService = inject(UserLoggedService);

    customers: CustomerDto[] = [];
    drawerVisible = false;
    drawerTitle = 'Placeholder';
    fieldsReadOnly = false;
    customerSelected: CustomerDto = new CustomerDto();
    isNewCustomer = false;
    identityDocumentTypes = ['DNI', 'CIF'];
    identityDocumentTypeSelected: IdentityDocumentTypesEnum = IdentityDocumentTypesEnum.DNI; // ToDo: Avoid hardcoded identity documents, get them from DB or an Enum
    currentUser: UserDto = new UserDto();
    loadingTable = true;

    private unsubscribe$ = new Subject<void>();

    async ngOnInit() {
        this.currentUser = this.userLoggedService.userLogged;
        await this.customerService.setupWebSocket();
        this.customerService.customers$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (customers) => {
                    this.customers = customers;
                    this.customerSelected = this.customers.find(customer => customer.id === this.customerSelected.id) || new CustomerDto();
                    this.identityDocumentTypeSelected = this.customerSelected.identityDocument as IdentityDocumentTypesEnum;
                },
                error: (e) => {
                    this.message.create('Error cargando clientes', e.message);
                },
            });
        this.loadingTable = false;
    }

    ngOnDestroy(): void {
        this.customerService.disconnectWebSocket();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    openCreateCustomerDrawer() {
        this.fieldsReadOnly = false;
        this.customerSelected = new CustomerDto();
        this.drawerTitle = 'Creando nuevo cliente';
        this.isNewCustomer = true;
        this.openDrawer();
    }

    seeCustomer(id: number) {
        this.fieldsReadOnly = true;
        this.customerSelected = this.customers.find((c) => c.id === id) as CustomerDto;
        this.drawerTitle = 'Viendo a cliente ' + this.customerSelected?.name;

        this.setData();
        this.openDrawer();
    }

    editCustomer(id: number) {
        this.fieldsReadOnly = false;
        this.customerSelected = this.customers.find((c) => c.id === id) as CustomerDto;
        this.drawerTitle = 'Editando a cliente ' + this.customerSelected?.name;
        this.isNewCustomer = false;
        this.setData();
        this.openDrawer();
    }

    async deleteCustomer(customerId: number) {
        try {
            if (!window.confirm('¿Estás seguro de que quieres eliminar este cliente? Esta acción no se puede deshacer.')) {
                return;
            }

            const deletedCustomer: CustomerDto = await this.customerService.deleteCustomer(customerId);

            const index = this.customers.findIndex((c) => c.id === customerId);

            if (index < 0) {
                this.message.create('error', `No se ha podido eliminar el cliente ${deletedCustomer.name} con id ${customerId}, cliente no encontrado`);
                return;
            }

            this.customers.splice(index, 1);

            const data = [...this.customers];
            this.customers = data;
            this.message.create('success', 'Cliente eliminado correctamente');
        } catch (e: any) {
            this.message.create('error', `${e.message}`);
        }
    }

    setData() {
        switch (this.customerSelected.identityDocumentId) {
            case 1:
                this.identityDocumentTypeSelected = IdentityDocumentTypesEnum.DNI;
                break;
            case 2:
                this.identityDocumentTypeSelected = IdentityDocumentTypesEnum.CIF;
                break;
        }
    }

    openDrawer() {
        this.drawerVisible = true;
    }

    closeDrawer() {
        this.drawerVisible = false;
    }

    async saveEditing() {
        try {
            const customerToBeProcessed: CustomerDto = {
                id: this.customerSelected.id,
                identityDocumentId: this.identityDocumentTypeSelected === IdentityDocumentTypesEnum.DNI ? 1 : 2, // ToDo: Get the id from the proper place not put them hardcoded
                identityDocument: (<HTMLInputElement>(document.getElementById('identityDocument'))).value
                    ? ((<HTMLInputElement>(
                        document.getElementById('identityDocument')
                    )).value as string)
                    : '',
                name: (<HTMLInputElement>document.getElementById('name')).value
                    ? ((<HTMLInputElement>document.getElementById('name'))
                        .value as string)
                    : '',
                businessName: (<HTMLInputElement>(document.getElementById('businessName'))).value
                    ? ((<HTMLInputElement>document.getElementById('businessName'))
                        .value as string)
                    : '',
                createdBy: this.isNewCustomer ? this.currentUser.id : this.customerSelected.createdBy
            };

            if ((<HTMLInputElement>(document.getElementById('externalId'))).value) {
                customerToBeProcessed.externalId = +(<HTMLInputElement>(document.getElementById('externalId'))).value;
            }

            if (!this.isFormValid(customerToBeProcessed)) {
                return;
            }

            if (this.isNewCustomer) {
                const createdCustomer: CustomerDto = await this.customerService.createCustomer(customerToBeProcessed);

                this.customers.push(createdCustomer);
                const data = [...this.customers];
                this.customers = data;

                this.message.create('success', 'Cliente creado correctamente');
            } else {
                const updatedCustomer: CustomerDto = await this.customerService.updateCustomer(customerToBeProcessed);

                const index = this.customers.findIndex((c) => c.id === updatedCustomer.id);

                if (index < 0) {
                    this.message.create('error', `No se ha podido actualizar el cliente ${customerToBeProcessed.name} con id ${customerToBeProcessed.id}, cliente no encontrado`);

                    return;
                }

                this.customers[index] = updatedCustomer;
                const data = [...this.customers];
                this.customers = data;

                this.message.create('success', 'Cliente actualizado correctamente');
            }

            this.closeDrawer();
        } catch (e: any) {
            this.message.create('error', `Error: ${e.message}`);
        }
    }

    cancelEditing() {
        this.closeDrawer();
    }

    changeDocumentType(id: IdentityDocumentTypesEnum) {
        this.identityDocumentTypeSelected = id;
    }

    isFormValid(customer: CustomerDto): boolean {
        if (customer.name.length === 0) {
            this.message.create('error', 'El nombre no puede estar vacío');
            return false;
        }

        if (customer.identityDocument.length === 0) {
            this.message.create('error', 'El documento de identidad no puede estar vacío');
            return false;
        }

        if (customer.identityDocumentId === -1) {
            this.message.create('error', 'El id de documento de identidad no puede estar vacío');
            return false;
        }

        if (customer.businessName.length === 0) {
            this.message.create('error', 'El nombre de la empresa no puede estar vacío');
            return false;
        }

        return true;
    }

    compareIdentityDocumentTypes = (o1: string, o2: string): boolean => {
        return o1 === o2;
    };
}
