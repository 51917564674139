<div class="p-10 h-full">
	<nz-page-header class="text-[16px] font-semibold p-0 m-0" nzTitle="Pantallas">
		<nz-page-header-extra>
			<button nz-button nzType="primary" (click)="create()">Nuevo</button>
		</nz-page-header-extra>
		<nz-page-header-content> </nz-page-header-content>
	</nz-page-header>

	<nz-table #nestedTable nzShowSizeChanger nzTableLayout="auto" [nzData]="screens" [nzLoading]="loading"
		[nzSimple]="true" nzTableLayout="auto" nzBordered>
		<thead>
			<tr>
				@for (column of columns; track column) {
				<th>
					{{ column.title }}
				</th>
				}
			</tr>
		</thead>
		<tbody>
			@for (data of nestedTable.data; track data) {
			<tr>
				<td>{{ data.name }}</td>
				<td>{{ data.ip }}</td>
				<td>
					<div class="buttonsDnDTableContainer">
                        <img src="assets/icons/eye-icon.svg" class="h-6 w-6" (click)="view(data)" title="Ver">
                        <nz-divider nzType="vertical"></nz-divider>
                        <img src="assets/icons/edit-icon.svg" class="h-6 w-6" (click)="edit(data)" title="Editar">
                        <nz-divider nzType="vertical"></nz-divider>
                        <img src="assets/icons/xmark-icon.svg" class="h-6 w-6" (click)="delete(data)" title="Eliminar">
						<button class="btn" nzType="default" (click)="openAssignMachinesDrawer(true, data.id)">
							Asignar máquinas
						</button>
                    </div>
				</td>
			</tr>
			}
		</tbody>
	</nz-table>
</div>

<div id="drawer-form">
	<app-drawer-form [(visible)]="showDrawerForm" [drawerMode]="drawerMode" [title]="title" [routeActually]="routeActually"
		[formStructure]="formStructure" [componentService]="screensService"
		(changeSelectedItem)="changeSelected($event)">
	</app-drawer-form>
</div>
<div id="drawer-assign-machines">
	<app-assign-machines [(visible)]="showDrawerAssignMachines" [title]="'Asignar máquinas'" [screen]="screenSelected">
	</app-assign-machines>
</div>
