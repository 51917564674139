<div class="ml-4 mr-4">
    <nz-page-header class="site-page-header" nzTitle="Artículo" nzSubtitle="Articulos en el sistema">
        <nz-page-header-extra>
            <nz-space>
                <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button" (click)="createArticle()">
                    Añadir artículo
                </button>
            </nz-space>
        </nz-page-header-extra>
    </nz-page-header>

    <nz-table #customersTable [nzData]="articles" [nzLoading]="loadingTable">
        <thead>
            <tr>
                <th>Nombre</th>
                <th>EAN</th>
                <th>Cantidad</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            @for (data of customersTable.data; track data.id) {
            <tr>
                <td>{{ data.name }}</td>
                <td>{{ data.ean }}</td>
                <td>{{ data.quantity }}</td>
                <td>
                    <div class="buttonsDnDTableContainer">
                        <img src="assets/icons/eye-icon.svg" class="h-6 w-6" (click)="seeArticle(data.id)" title="Ver">
                        <nz-divider nzType="vertical"></nz-divider>
                        <img src="assets/icons/edit-icon.svg" class="h-6 w-6" (click)="editArticle(data.id)"
                            title="Editar">
                        <nz-divider nzType="vertical"></nz-divider>
                        <img src="assets/icons/xmark-icon.svg" class="h-6 w-6" (click)="deleteArticle(data.id)"
                            title="Eliminar">
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </nz-table>
</div>

<nz-drawer [nzClosable]="false" [nzVisible]="drawerVisible" nzPlacement="right" [nzTitle]="drawerTitle"
    (nzOnClose)="closeDrawer()" [nzWidth]="'500px'" [nzMaskClosable]="fieldsReadOnly">
    <ng-container *nzDrawerContent>
        <form (ngSubmit)="saveEditing()">

            <label>Nombre:</label>
            <input nz-input [value]="articleSelected.name ? articleSelected.name : ''" type="text"
                class="searchSelectors" [readOnly]="fieldsReadOnly" id="name" />

            <label>EAN:</label>
            <input nz-input [value]=" articleSelected.ean ? articleSelected.ean : '' " type="number" pattern="\d*"
                class="searchSelectors" [readOnly]="fieldsReadOnly" id="ean" />

            <label>Alias:</label>
            <input nz-input [value]="articleSelected.alias ? articleSelected.alias : ''" type="text"
                class="searchSelectors" [readOnly]="fieldsReadOnly" id="alias" />

            <label nz-checkbox [(ngModel)]="articleActive" [nzDisabled]="fieldsReadOnly"
                name="articleActive">¿Activado?</label>
            <br>

            <label nz-checkbox [(ngModel)]="articleUseBatches" [nzDisabled]="fieldsReadOnly || !isNewArticle" name="useBatches">¿Usa
                lotes?</label>
            <br>

            <label>Referencia externa</label>
            <input nz-input [value]="articleSelected.externalReference ? articleSelected.externalReference : ''"
                type="text" class="searchSelectors" [readOnly]="fieldsReadOnly" id="externalReference" />

            <label>Cantidad:</label>
            <input nz-input [value]="articleSelected.quantity" type="number" pattern="\d*" class="searchSelectors"
                [readOnly]="fieldsReadOnly" id="quantity" />

            <button nz-button nz-dropdown [nzDropdownMenu]="menuMeasurements" type="button" [disabled]="fieldsReadOnly"
                nzTrigger="click" class="bg-white border border-gray-200 rounded px-4 py-2">
                <b>Unidad de medida:</b> {{ measurementUnitSelected.name }}
            </button>
            <nz-dropdown-menu #menuMeasurements="nzDropdownMenu">
                <ul nz-menu>
                    @for (m of measurementUnits; track m.id) {
                    <li nz-menu-item [innerHTML]="m.name" (click)="changeMeasure(m.id)"></li>
                    }
                </ul>
            </nz-dropdown-menu>
            <br /><br />

            <button nz-button nz-dropdown [nzDropdownMenu]="menuTypes" type="button" [disabled]="fieldsReadOnly"
                nzTrigger="click" class="bg-white border border-gray-200 rounded px-4 py-2">
                <b>Tipo:</b> {{ articleTypeSelected.name }}
            </button>
            <nz-dropdown-menu #menuTypes="nzDropdownMenu">
                <ul nz-menu>
                    @for (t of articleTypeDto; track t.id) {
                    <li nz-menu-item [innerHTML]="t.name" (click)="changeType(t.id)"></li>
                    }
                </ul>
            </nz-dropdown-menu>
            <br><br>

            <label>Familias: </label>
            <nz-select [nzMaxTagCount]="5" [nzMaxTagPlaceholder]="tagPlaceHolder" nzMode="multiple"
                nzPlaceHolder="Selecciona" [(ngModel)]="articleFamiliesSelected" name="familiesSelector"
                class="searchSelectors" [nzDisabled]="fieldsReadOnly" [compareWith]="compareArticleFamilies">
                @for (f of articleFamilyDto; track f.id) {
                <nz-option [nzLabel]="f.name" [nzValue]="f"></nz-option>
                }
            </nz-select>
            <ng-template #tagPlaceHolder let-selectedList>y {{ selectedList.length }} mas seleccionados</ng-template>

            <label>Máquina: </label>
            <nz-select nzShowSearch [(ngModel)]="machineSelected" name="machineSelector" [nzDisabled]="fieldsReadOnly"
                class="searchSelectors">
                @for (m of machines; track m.id) {
                <nz-option [nzLabel]="m.name" [nzValue]="m"></nz-option>
                }
            </nz-select>

            <label>Valores de máquina: </label>
            <nz-input-group nzCompact>
                <nz-select [(ngModel)]="machineMeasurementsSelected" name="measurementsSelector"
                    [nzDisabled]="fieldsReadOnly" style="width: 30%;">
                    @for (mm of machineMeasurements; track mm.id) {
                    <nz-option [nzLabel]="mm.measurement" [nzValue]="mm"></nz-option>
                    }
                </nz-select>
                <input type="text" nz-input [(ngModel)]="machineMeasurementsValueAdding.value"
                    style="width:50%;" name="measurementsSelectorInput" [style]="fieldsReadOnly ? 'width: 70%; height: 48px;' : 'width: 60%; height: 48px;'"
                    [disabled]="fieldsReadOnly" id="measurementsSelectorInput" />
                @if (!fieldsReadOnly) {
                <div style="width: 10%; height: 48px; cursor: pointer;">
                    <img src="assets/icons/plus-icon.svg" (click)="addEntryMachineMeasurement()" title="Añadir"
                        style="height: 50%; width: 50%; margin: auto;">
                </div>
                }
            </nz-input-group>
            <br>
            <nz-table #machineMeasurementsTable [nzData]="machineMeasurementsValueSelected">
                <thead>
                    <tr>
                        <th>Valores añadidos</th>
                        @if (!fieldsReadOnly) {
                        <th>Acciones</th>
                        }

                    </tr>
                </thead>
                <tbody>
                    @for (data of machineMeasurementsTable.data; let idx = $index; track idx) {
                    <tr>
                        <td>{{ data.formattedValue || '-' }}</td>
                        @if (!fieldsReadOnly) {
                        <td style="cursor: pointer;">
                            <img src="assets/icons/xmark-icon.svg" class="h-6 w-6"
                                (click)="deleteEntryMachineMeasurement(data.id)" title="Eliminar">
                        </td>
                        }
                    </tr>
                    }
                </tbody>
            </nz-table>

            @if (!fieldsReadOnly) {
            <button
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit">
                Guardar
            </button>
            <nz-divider nzType="vertical"></nz-divider>
            <button
                class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button" (click)="cancelEditing()">
                Cancelar
            </button>
            } @else {
            <!-- No metadata at the moment -->
            <!--
            <nz-divider></nz-divider>
            <h3>Metadatos</h3>
            -->
            }
        </form>
    </ng-container>
</nz-drawer>
