<div class="w-full">
    <div class="flex justify-center items-center w-full py-6">
        <label
            for="dropzone-file"
            class="flex flex-col justify-center items-center w-full bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 h-[200px]"
            (click)="onRfidRead()"
            >
            <div class="flex flex-col justify-center items-center pt-5 pb-3">
                <svg xmlns="http://www.w3.org/2000/svg" class="mb-1 w-10 h-10 text-gray-400" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
                </svg>
                <p class="mb-1 text-sm text-gray-500 dark:text-gray-400">
                    <span class="font-semibold">Iniciar sesión con RFID</span>
                </p>
                <p class="text-sm text-gray-500 dark:text-gray-400 text-center">
                    Para poder iniciar sesión, deslice su tarjeta sobre el
                    lector RFID.
                </p>
            </div>
            <input #rfidInput appAutofocus type="password" class="input input-bordered w-full opacity-0 h-0"
                [(ngModel)]="rfid" (keydown.enter)="onRfidRead()" />
        </label>
    </div>

    <div class="text-xs w-full text-left h-2 font-medium mt-1 flex items-center">
        <img class="w-3 h-auto mr-1.5" src="assets/svg/devoltec-logo.svg" alt="Logo" loading="lazy" />
        <p>Devoltec - v.{{ version }}</p>
    </div>
</div>
