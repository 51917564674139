import {
    Component,
    HostListener,
    input,
    model,
    OnInit,
    output,
    viewChild,
} from '@angular/core';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { ɵNzTransitionPatchDirective } from 'ng-zorro-antd/core/transition-patch';
import { NzWaveDirective } from 'ng-zorro-antd/core/wave';
import { NzDrawerComponent, NzDrawerContentDirective } from 'ng-zorro-antd/drawer';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NzModalService } from 'ng-zorro-antd/modal';
import { lastValueFrom, Observable } from 'rxjs';
import { AcctionsEdit } from 'src/app/core/enums/acctions-edit';
import { DrawerMode } from 'src/app/core/enums/drawer-mode';
import { DynamicFormComponent } from 'src/app/shared/components/dynamic-form/dynamic-form.component';
import { StandardModalComponent } from 'src/app/shared/components/standard-modal/standard-modal.component';
import { IStandardModalData } from 'src/app/shared/interface/standard-modal-data';
import { ItemFormStructure } from 'src/app/shared/services/form.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { DynamicFormComponent as DynamicFormComponent_1 } from '../../../shared/components/dynamic-form/dynamic-form.component';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';

@Component({
    selector: 'app-drawer-form',
    templateUrl: './drawer-form.component.html',
    styleUrl: './drawer-form.component.scss',
    standalone: true,
    imports: [
        NzDrawerComponent,
        NzDrawerContentDirective,
        LoaderComponent,
        DynamicFormComponent_1,
        NzButtonComponent,
        NzWaveDirective,
        ɵNzTransitionPatchDirective,
        NzIconDirective,
    ],
})
export class DrawerFormComponent implements OnInit {
    drawerMode = input(DrawerMode.NONE);
    routeActually = input<string>('');
    title = input<string>('');
    formStructure = input<ItemFormStructure[]>([]);
    componentService = input<any>();
    visible = model();
    entity = input(null);
    listValue = input<Record<string, any[]>>({});
    nameSelected = input('');
    changeSelectedItem = output<string>();
    dynamicFormComponent = viewChild(DynamicFormComponent);
    DrawerMode = DrawerMode;
    loading: boolean = false;
    idInternal: number = 0;
    disabled: boolean = false;

    constructor(
        public nzModalService: NzModalService,
        private _socketService: SocketService,
    ) { }

    ngOnInit(): void {
        this._socketService
            .getNotifications('reload-op')
            .subscribe(async (data) => {
                this.disabled = true;
                this.onCancel();
            });
    }

    async submit(): Promise<void> {
        const entity: object = this.dynamicFormComponent()?.onSubmit();
        console.log('Guardar', entity ? entity : 'No hay objeto');
        if (!entity) {
            return;
        }

        if (this.drawerMode() === DrawerMode.CREATE) {
            const res = await lastValueFrom(this.componentService().save(entity));
            this._socketService.sendNotification(
                AcctionsEdit.CREATE,
                this.routeActually(),
                'reload-data',
            );
            this.visible.set(false);
        } else if (this.drawerMode() === DrawerMode.EDIT) {
            this.idInternal =
                this.dynamicFormComponent()?.dynamicForm.getRawValue().id;
            const res = await lastValueFrom(
                this.componentService().update(this.idInternal, entity),
            );
            this._socketService.sendNotification(
                AcctionsEdit.EDIT,
                this.routeActually(),
                'reload-data',
            );
            this.disabled = false;
            this.visible.set(false);
        }
    }

    onCancel() {
        this.idInternal =
            this.dynamicFormComponent()?.dynamicForm.getRawValue().id;

        if (this.dynamicFormComponent()?.hasChangeObject()) {
            const dialogRefAux = this.nzModalService.create<
                StandardModalComponent,
                IStandardModalData
            >({
                nzContent: StandardModalComponent,
                nzStyle: { width: '30%', top: '10%' },
                nzClosable: false,
                nzFooter: null,
                nzData: {
                    close: false,
                    title: '¿Está seguro que quieres salir?',
                    message:
                        'Los datos no serán guardados si realiza esta acción.',
                },
            });

            const sub = dialogRefAux.afterClose.subscribe(async () => {
                sub.unsubscribe();
                const close = dialogRefAux.componentInstance?.close;

                if (close) {
                    this._socketService.disconnectSocketRoom(this.routeActually() + this.idInternal);
                    
                    this.disabled = false;
                    this.visible.set(false);
                }
            });
        } else {
            this._socketService.disconnectSocketRoom(
                this.routeActually() + this.idInternal,
            );
            this.disabled = false;
            this.visible.set(false);
        }
    }

    /** direction puede ser 'prev' || 'next' */
    changeSelected(direction: string): void {
        this.changeSelectedItem.emit(direction);
    }

    @HostListener('window:keyup.esc') onKeyUp() {
        this.onCancel();
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        if (this.dynamicFormComponent()?.hasChangeObject()) {
            return false;
        } else {
            return true;
        }
    }
}
