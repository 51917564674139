import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CustomFieldDto } from 'src/app/shared/dto/custom-field.dto';
import { CustomFieldsPropertiesDto } from 'src/app/shared/dto/custom-fields-properties.dto';
import { FieldTypesEnum } from 'src/app/shared/enums/field-types.enum';
import { BaseService } from 'src/app/shared/services/base.service';

@Injectable({
    providedIn: 'root'
})
export class CustomFieldsService extends BaseService {
    constructor(httpClient: HttpClient) {
        super(httpClient, 'custom-fields');
    }

    async findByEntity(entity: string): Promise<CustomFieldDto[]> {
        try {
            if (entity == null || entity == '') {
                throw new Error('Entity can not be null');
            }

            const request = this.httpClient.get<CustomFieldDto[]>(`${this.url}/findByEntity/${entity}`);

            const customFields = await lastValueFrom(request);

            if (!customFields) {
                throw new Error('No custom fields found');
            }

            customFields.map((cf) => {
                const type: FieldTypesEnum = (JSON.parse(cf.properties) as CustomFieldsPropertiesDto).type;

                cf.value = this.stringToDataType(cf.value as string, type)
            });

            return customFields;
        } catch (e: any) {
            throw new Error(`No custom fields found. Error: ${e.message}`);
        }
    }

    async updateCustomFields(customFields: CustomFieldDto[]): Promise<CustomFieldDto[]> {
        try {
            if (customFields.length === 0) {
                throw new Error('Custom fields list is empty');
            }

            const updateParams: {id: number, value: string}[] = [];

            customFields.map((cf) => {
                cf.value = cf.value?.toString();
                updateParams.push({id: cf.id as number, value: cf.value as string});
            });

            const updatedCustomFields: CustomFieldDto[] = await lastValueFrom(this.httpClient.patch<CustomFieldDto[]>(`${this.url}`, updateParams));

            updatedCustomFields.map((cf) => {
                const type: FieldTypesEnum = (JSON.parse(cf.properties) as CustomFieldsPropertiesDto).type;

                cf.value = this.stringToDataType(cf.value as string, type)
            });

            return updatedCustomFields;
        } catch (e: any) {
            throw new Error(`Error updating custom fields. Error: ${e.message}`)
        }
    }

    private stringToDataType(value: string | number | boolean | string[], dataType: FieldTypesEnum): string | number | boolean | string[] {
        let result: string | number | boolean | string[] = value;

		switch (dataType) {
			case FieldTypesEnum.INPUT_NUMBER:
				result = Number(value);
				break;
			case FieldTypesEnum.CHECKBOX:
				result = value === 'true';
				break;
			case FieldTypesEnum.MULTISELECT:
				result = (value as string).split(',');
				break;
		}

        return result;
    }
}
