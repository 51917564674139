<div class="p-10 h-full">
    <nz-page-header class="text-[16px] font-semibold p-0 m-0" nzTitle="Mantenimientos preventivos">
        <nz-page-header-extra class="flex">
            <button class="ml-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-4 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 w-full">Añadir</button>
        </nz-page-header-extra>
    </nz-page-header>

    <div class="flex justify-between items-center mt-4">
        <!-- Filter Inputs -->
        <div class="flex gap-2">
            <div class="flex flex-col">
                <label for="inputInterventionLevel">Nº</label>
                <input type="text" class="max-w-40 border rounded-lg pl-2 pr-2 py-1 bg-gray-100" name="inputInterventionLevel" placeholder="...">
            </div>
            <div class="flex flex-col">
                <label for="inputInterventionLevel">Nivel de Intervención</label>
                <input type="text" class="max-w-40 border rounded-lg pl-2 pr-2 py-1 bg-gray-100" name="inputInterventionLevel" placeholder="...">
            </div>
            <div class="flex flex-col">
                <label for="inputMachine">Máquina</label>
                <input type="text" class="max-w-40 border rounded-lg pl-2 pr-2 py-1 bg-gray-100" name="inputMachine" placeholder="...">
            </div>
            <div class="flex flex-col">
                <label for="inputOperator">Operario</label>
                <input type="text" class="max-w-40 border rounded-lg pl-2 pr-2 py-1 bg-gray-100" name="inputOperator" placeholder="...">
            </div>
            <div class="flex flex-col">
                <label for="inputElement">Elemento</label>
                <input type="text" class="max-w-40 border rounded-lg pl-2 pr-2 py-1 bg-gray-100" name="inputElement" placeholder="...">
            </div>
            <div class="flex flex-col">
                <label for="inputFrequency">Frecuencia</label>
                <input type="text" class="max-w-40 border rounded-lg pl-2 pr-2 py-1 bg-gray-100" name="inputFrequency" placeholder="...">
            </div>
            <div class="flex flex-col">
                <label for="inputDuration">Duración</label>
                <input type="number" class="max-w-40 border rounded-lg pl-2 pr-2 py-1 bg-gray-100" name="inputDuration" placeholder="... (h)">
            </div>
            <div class="flex flex-col">
                <label for="inputEconomicCost">Costo Económico</label>
                <input type="number" class="max-w-40 border rounded-lg pl-2 pr-2 py-1 bg-gray-100" name="inputEconomicCost" placeholder="... (€)">
            </div>
        </div>
    </div>
    <div #tableContainer class="mt-[15px] h-[calc(100%_-_100px)] max-h-[calc(100%_-_100px)]">
        <nz-table #nestedTable nzTableLayout="auto" nzSize="small" nzTableLayout="auto"
            [nzData]="preventiveMaintenances" [nzSimple]="true"
            [nzBordered]="true"
            [nzFrontPagination]="false" nzShowSizeChanger nzBordered
        >
            <thead>
                <tr>
                    <th id="requestId">Nº</th>
                    <th id="interventionLevel">Nivel Intervención</th>
                    <th id="operators">Operarios</th>
                    <th id="machineId">Maquinaria</th>
                    <th id="element">Elemento</th>
                    <th id="frequency">Frecuencia</th>
                    <th id="description">Descripción</th>
                    <th id="duration">Duración</th>
                    <th id="economicCost">Valor coste</th>
                </tr>
            </thead>
            <tbody>
                @for(revision of nestedTable.data; track revision.id){
                    <tr>
                        <td>{{ revision.id }}</td>
                        <td>{{ revision.interventionLevel }}</td>
                        <td>
                            @for(operator of revision.operators; track operator.id){
                                {{ operator.id}}<br>
                            }
                        </td>
                        <td>{{ revision.machineId}}</td>
                        <td>{{ revision.element }}</td>
                        <td>{{ revision.frequency }}</td>
                        <td>{{ revision.description }}</td>
                        <td>{{ revision.duration }} h</td>
                        <td>{{ revision.economicCost }} €</td>
                    </tr>
                }
            </tbody>
        </nz-table>
    </div>
</div>
