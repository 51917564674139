import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { DatePipe, NgClass } from '@angular/common';
import { Component, EventEmitter, inject, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ɵNzTransitionPatchDirective } from 'ng-zorro-antd/core/transition-patch';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { NzDividerComponent } from 'ng-zorro-antd/divider';
import { NzDrawerComponent, NzDrawerContentDirective } from 'ng-zorro-antd/drawer';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NzInputDirective, NzInputGroupComponent, NzInputGroupWhitSuffixOrPrefixDirective } from 'ng-zorro-antd/input';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalFooterDirective, NzModalService } from 'ng-zorro-antd/modal';
import { NzPageHeaderComponent, NzPageHeaderContentDirective, NzPageHeaderExtraDirective } from 'ng-zorro-antd/page-header';
import { NzProgressComponent } from 'ng-zorro-antd/progress';
import { NzOptionComponent, NzSelectComponent } from 'ng-zorro-antd/select';
import { NzTableCellDirective, NzTableComponent, NzTableFixedRowComponent, NzTbodyComponent, NzTdAddOnComponent, NzThAddOnComponent, NzTheadComponent, NzThMeasureDirective, NzTrDirective, NzTrExpandDirective } from 'ng-zorro-antd/table';
import { ArticleBatchDto } from 'src/app/shared/dto/article-batch.dto';
import { ArticleDto } from 'src/app/shared/dto/article.dto';
import { AssessDetailDto } from 'src/app/shared/dto/assess-detail.dto';
import { ProductionOrderProcessDto } from 'src/app/shared/dto/production-order-process.dto';
import { RawMaterialDto } from 'src/app/shared/dto/rawMaterial.dto';

@Component({
    selector: 'app-modal-create-assess',
    standalone: true,
    imports: [
        NzPageHeaderComponent,
        NzPageHeaderExtraDirective,
        NzInputGroupComponent,
        ɵNzTransitionPatchDirective,
        NzInputGroupWhitSuffixOrPrefixDirective,
        NzInputDirective,
        ReactiveFormsModule,
        FormsModule,
        NzIconDirective,
        NzPageHeaderContentDirective,
        NzTableComponent,
        NzTheadComponent,
        NzTrDirective,
        NzTableCellDirective,
        NzThMeasureDirective,
        NzThAddOnComponent,
        NzTbodyComponent,
        NzTdAddOnComponent,
        NgClass,
        NzProgressComponent,
        NzDividerComponent,
        NzTrExpandDirective,
        NzTableFixedRowComponent,
        NzDrawerComponent,
        NzDrawerContentDirective,
        NzSelectComponent,
        NzOptionComponent,
        CdkDropList,
        CdkDrag,
        NzDatePickerComponent,
        DatePipe,
        NzMessageModule,
        NzModalFooterDirective
    ],
    templateUrl: './modal-add-raw-materials.component.html',
    styleUrl: './modal-add-raw-materials.component.scss'
})
export class ModalAddRawMaterialsComponent implements OnInit {
    @Output() rawMaterialsChanged = new EventEmitter();
    @Output() assessmentsChanged = new EventEmitter();

    articles: ArticleDto[] = [];
    articlesBatches: ArticleBatchDto[] = [];
    articlesAndBatches: any[] = [];
    articleSelected?: any;
    quantity: number = 0;
    description: string = '';
    rawMaterials: RawMaterialDto[] = [];
    assessDetails: AssessDetailDto[] = [];
    productionOrderProcess: ProductionOrderProcessDto;
    selectedItems: any[] = [];

    private readonly modalService = inject(NzModalService);
    private readonly message = inject(NzMessageService);

    constructor(@Inject(NZ_MODAL_DATA) public data: any) {
        this.articles = data?.data?.articles;
        this.articlesBatches = data?.data?.articlesBatches;
        this.productionOrderProcess = data?.data?.productionOrderProcess;
    }

    async ngOnInit() {
        this.increaseStockQuantities();
        this.loadRawMaterials();
        this.remplaceArticlesWithBatches();
    }

    async saveRawMaterials() {

        if (!this.isValidForm()) {
            return;
        }

        if (!this.hasEnoughStock()) {
            return;
        }

        this.productionOrderProcess.rawMaterials = [];

        this.rawMaterials.forEach(rawMaterial => {
            this.productionOrderProcess.rawMaterials?.push({
                article: rawMaterial.article || undefined,
                articleBatch: rawMaterial.articleBatch || undefined,
                quantity: rawMaterial.quantity
            });
        });

        this.rawMaterialsChanged.next(this.productionOrderProcess);
    }

    addRawMaterial() {
        this.rawMaterials.push({
            quantity: 0
        });
    }

    handleCancel() {
        this.decreaseStockQuantities();
        this.modalService.closeAll();
    }

    onSelectionChange(selectedItem: any, subidx: number) {
        if (selectedItem.name) {
            this.rawMaterials[subidx].article = selectedItem;
            this.rawMaterials[subidx].articleBatch = undefined;
            this.articlesAndBatches = this.articlesAndBatches.filter(article => article.id !== selectedItem.id);
        } else {
            this.rawMaterials[subidx].articleBatch = selectedItem;
            this.rawMaterials[subidx].article = undefined;
            this.articlesAndBatches = this.articlesAndBatches.filter(batch => batch.id !== selectedItem.id);
        }
    }

    hasEnoughStock(): boolean {
        this.rawMaterials.map(rawMaterial => {
            const stockAvailable = rawMaterial.article ? rawMaterial.article.quantity : rawMaterial.articleBatch!.quantity;

            if (rawMaterial.quantity > stockAvailable) {
                this.message.error(`No hay suficiente stock de ${rawMaterial.article?.name || rawMaterial.articleBatch?.batchNumber}`);

                return false;
            }

            return true;
        });

        return true;
    }

    removeRawMaterialsFromArticlesAndBatches() {
        this.rawMaterials.forEach(rawMaterial => {
            if (this.articlesAndBatches.includes(rawMaterial.article || rawMaterial.articleBatch)) {
                const articleOrBatch = rawMaterial.article || rawMaterial.articleBatch;
                this.selectedItems = [...this.selectedItems, articleOrBatch];
                this.articlesAndBatches = this.articlesAndBatches.filter(artOrBatch => artOrBatch.id !== articleOrBatch?.id);
            }
        });
    }

    private loadRawMaterials() {
        this.productionOrderProcess.rawMaterials?.forEach((rawMaterial, index) => {
            this.rawMaterials.push({
                articleBatch: rawMaterial.articleBatch || undefined,
                article: rawMaterial.article || undefined,
                quantity: rawMaterial.quantity,
            });
            this.selectedItems[index] = rawMaterial.article || rawMaterial.articleBatch;
        });
    }

    private remplaceArticlesWithBatches() {
        for (const article of this.articles) {
            if (article.useBatches) {
                const articleBatchesOfArticle = this.articlesBatches.filter(articleBatch => articleBatch.articleId === article.id);
                articleBatchesOfArticle.forEach(articleBatch => this.articlesAndBatches.push(articleBatch));
            } else {
                this.articlesAndBatches.push(article);
            }
        }
    }

    private increaseStockQuantities() {
        this.productionOrderProcess.rawMaterials?.forEach(rawMaterial => {
            if (rawMaterial.article) {
                this.articles.find(article => article.id == rawMaterial.article!.id)!.quantity += rawMaterial.quantity;
            } else if (rawMaterial.articleBatch) {
                this.articlesBatches.find(articleBatch => articleBatch.id == rawMaterial.articleBatch!.id)!.quantity += rawMaterial.quantity;
            }
        });
    }

    private decreaseStockQuantities() {
        this.productionOrderProcess.rawMaterials?.forEach(rawMaterial => {
            if (rawMaterial.article) {
                this.articles.find(article => article.id == rawMaterial.article!.id)!.quantity -= rawMaterial.quantity;
            } else if (rawMaterial.articleBatch) {
                this.articlesBatches.find(articleBatch => articleBatch.id == rawMaterial.articleBatch!.id)!.quantity -= rawMaterial.quantity;
            }
        });
    }

    private isValidForm() {

        if (this.rawMaterials.length <= 0) {
            this.message.error('Debe añadir al menos un material');
            return false;
        }

        for (const rawMaterial of this.rawMaterials) {
            if (!rawMaterial.article && !rawMaterial.articleBatch) {
                this.message.error('Debe seleccionar un artículo o un lote');
                return false;
            }

            if (!rawMaterial.quantity || rawMaterial.quantity <= 0) {
                this.message.error('La cantidad de los materiales debe ser mayor que 0');
                return false;
            }
        }
        return true;
    }
}
