import { Component } from '@angular/core';

@Component({
    selector: 'app-no-internet',
    templateUrl: './no-internet.component.html',
    styleUrls: ['./no-internet.component.scss'],
    standalone: true,
})
export class NoInternetComponent {
    constructor() {}

    refresh(): void {
        window.location.reload();
    }
}
