import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ScrapCauseDto } from 'src/app/shared/dto/scrap-cause.dto';
import { BaseService } from 'src/app/shared/services/base.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';

@Injectable({
    providedIn: 'root',
})
export class ScrapCauseService extends BaseService {
    private scrapCausesSubject = new BehaviorSubject<ScrapCauseDto[]>([]);
    public scrapCauses$ = this.scrapCausesSubject.asObservable();

    private readonly socketService = inject(SocketService);

    constructor(httpClient: HttpClient) {
        super(httpClient, 'scrap-causes');
    }

    async setupWebSocket(): Promise<void> {
        try {
            const initialData = await lastValueFrom(this.get());
            this.scrapCausesSubject.next(initialData);
            this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.SCRAP_CAUSES_ROOM));
            this.socketService.getNotifications(Messages.SCRAP_CAUSES_CHANGED).subscribe(async () => {
                const response = await lastValueFrom(this.get());
                this.scrapCausesSubject.next(response);
            });
        } catch (e: any) {
            throw new Error(`Error al establecer conexión con el socket de Causas de Tara. Error: ${e.message}`);
        }
    }

    disconnectWebSocket(): void {
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.SCRAP_CAUSES_ROOM));
    }
}
