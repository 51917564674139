import { Component, EventEmitter, inject, Inject, Output } from '@angular/core';
import { NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { CustomFieldsService } from 'src/app/private/services/custom-fields.service';
import { CustomFieldDto } from 'src/app/shared/dto/custom-field.dto';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { FormsModule } from '@angular/forms';
import { CustomFieldsPropertiesDto } from 'src/app/shared/dto/custom-fields-properties.dto';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CustomFieldsRestrictionsDto } from 'src/app/shared/dto/custom-fields-restrictions.dto';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { FieldTypesEnum } from 'src/app/shared/enums/field-types.enum';

@Component({
	selector: 'app-modal-quality-control',
	standalone: true,
	imports: [
		FormsModule,
		NzDividerModule,
		NzSwitchModule,
		NzRadioModule,
		NzSelectModule,
		NzMessageModule
	],
	templateUrl: './modal-quality-control.component.html',
	styleUrl: './modal-quality-control.component.scss'
})
export class ModalQualityControlComponent {
	@Output() emitService = new EventEmitter();

	customFields: CustomFieldDto[] = [];
	typesEnum = FieldTypesEnum;
	infinity = Infinity;

	private readonly modalService = inject(NzModalService);
	private readonly customFieldsService = inject(CustomFieldsService);
	private readonly toast = inject(NzMessageService);

	constructor(@Inject(NZ_MODAL_DATA) public data: any) { }

	async ngOnInit() {
		try {
			this.customFields = await this.customFieldsService.findByEntity('production.orders.process.detail.quality.modal');
			this.customFields = this.customFields.map((cf) => {
				if (cf.properties) {
					cf.propertiesFormated = JSON.parse(cf.properties as string) as CustomFieldsPropertiesDto;
					if (cf.propertiesFormated.options) {
						cf.propertiesFormated.optionsFormatted = cf.propertiesFormated.options.split(',');
					}
				}
	
				if (cf.restrictions) {
					cf.restrictionsFormated = JSON.parse(cf.restrictions as string) as CustomFieldsRestrictionsDto;
				}
	
				return cf;
			});
		} catch (error) {
			throw error;
		}
	}

	async handleFinishButton() {
		if (!this.isValidCustomObjects()) {
			return;
		}
		await this.customFieldsService.updateCustomFields(this.customFields);
		this.emitService.emit();
		this.modalService.closeAll();
	}

	isValidCustomObjects(): boolean {
		for (let cf of this.customFields) {
			if (cf.propertiesFormated?.type === FieldTypesEnum.INPUT_NUMBER && cf.value != null && cf.restrictionsFormated != null) {
				if (cf.restrictionsFormated.max && (+cf.value) > cf.restrictionsFormated.max) {
					this.toast.error(`El campo ${cf.propertiesFormated?.name} debe ser menor de ${cf.restrictionsFormated.max}`);
					return false;
				}

				if (cf.restrictionsFormated.min && (+cf.value) < cf.restrictionsFormated.min) {
					this.toast.error(`El campo ${cf.propertiesFormated?.name} debe ser mayor de ${cf.restrictionsFormated.min}`);
					return false;
				}
			}
			
			if (cf.restrictionsFormated == null || !cf.restrictionsFormated.required || cf.propertiesFormated?.type === FieldTypesEnum.CHECKBOX) {
				if (cf.propertiesFormated?.type === FieldTypesEnum.CHECKBOX && cf.value == null) {
					cf.value = false;
				}
				continue;
			}

			if (cf.restrictionsFormated.required && (cf.value == null || cf.value == '')) {
				if (cf.propertiesFormated?.type === FieldTypesEnum.MULTISELECT && cf.value != null && (cf.value).length === 0) {
					this.toast.error(`El campo ${cf.propertiesFormated?.name} es obligatorio`);
					return false;
				}

				this.toast.error(`El campo ${cf.propertiesFormated?.name} es obligatorio`);
				return false;
			}
		}
		return true;
	}
}
