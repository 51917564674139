import * as i0 from '@angular/core';
import { Directive, Input, NgModule } from '@angular/core';
let id = 1;
const generateId = () => id++;
/* for testing only */
const resetIdCounter = () => id = 1;

/// MENU - Spec: https://www.w3.org/TR/wai-aria-practices-1.2/#menubutton
class MenuDirective {
  constructor(renderer, changeDetection) {
    this.renderer = renderer;
    this.changeDetection = changeDetection;
    this.static = false;
    this.expanded = false;
    this.menuItems = [];
    this.activeItem = null;
    this.searchQuery = '';
    this.searchDebounce = null;
  }
  toggle(focusAfterExpand = null, focusButtonOnClose = true) {
    if (this.expanded) {
      // close items panel
      this.expanded = false;
      this.menuItemsPanel.collapse();
      this.menuButton.element.removeAttribute('aria-controls');
      this.menuButton.element.removeAttribute('expanded');
      this.menuItems = [];
      this.activeItem = null;
      this.windowClickUnlisten();
      if (focusButtonOnClose) {
        this.menuButton.focus();
      }
      this.changeDetection.markForCheck();
    } else {
      // open items panel
      this.expanded = true;
      this.changeDetection.markForCheck();
      setTimeout(() => {
        this.menuItemsPanel.expand();
        this.menuItemsPanel.focus();
        if (this.menuItemsPanel.element != null) {
          this.menuButton.element.setAttribute('aria-controls', this.menuItemsPanel.element.id);
        }
        this.menuButton.element.setAttribute('expanded', 'true');
        this.windowClickUnlisten = this.initListeners();
        if (focusAfterExpand) {
          setTimeout(() => this.focusItem(focusAfterExpand));
        }
      });
    }
  }
  focusItem(focusType) {
    const activeItem = this.calculateFocusedItem(focusType);
    if (activeItem === this.activeItem) {
      return;
    }
    this.activeItem = activeItem;
    this.menuItems.forEach(item => {
      if (this.activeItem) {
        this.menuItemsPanel.element?.setAttribute('aria-activedescendant', this.activeItem.element.id);
      } else {
        this.menuItemsPanel.element?.removeAttribute('aria-activedescendant');
      }
      item.setActive(item === this.activeItem);
    });
  }
  clickActive() {
    this.activeItem?.element.click();
  }
  search(value) {
    if (this.searchDebounce) {
      clearTimeout(this.searchDebounce);
    }
    this.searchDebounce = setTimeout(() => this.searchQuery = '', 350);
    this.searchQuery += value.toLocaleLowerCase();
    const matchingItem = this.menuItems.find(item => {
      const itemText = item.element.textContent?.trim().toLocaleLowerCase();
      return itemText?.startsWith(this.searchQuery) && !item.hlMenuItemDisabled;
    });
    if (matchingItem === undefined || matchingItem === this.activeItem) {
      return;
    }
    this.focusItem({
      kind: 'FocusSpecific',
      item: matchingItem
    });
  }
  calculateFocusedItem(focusType) {
    const enabledItems = this.menuItems.filter(item => !item.hlMenuItemDisabled);
    switch (focusType.kind) {
      case 'FocusSpecific':
        return focusType.item;
      case 'FocusNothing':
        return null;
      case 'FocusFirst':
        return enabledItems[0];
      case 'FocusLast':
        return enabledItems[enabledItems.length - 1];
      case 'FocusNext':
        if (this.activeItem === null) {
          return enabledItems[0];
        } else {
          const nextIndex = Math.min(enabledItems.indexOf(this.activeItem) + 1, enabledItems.length - 1);
          return enabledItems[nextIndex];
        }
      case 'FocusPrevious':
        if (this.activeItem === null) {
          return enabledItems[enabledItems.length - 1];
        } else {
          const previousIndex = Math.max(enabledItems.indexOf(this.activeItem) - 1, 0);
          return enabledItems[previousIndex];
        }
    }
  }
  initListeners() {
    return this.renderer.listen(window, 'click', event => {
      const target = event.target;
      const active = document.activeElement;
      if (this.menuButton.element.contains(target) || this.menuItemsPanel?.element?.contains(target)) {
        return;
      }
      const clickedTargetIsFocusable = active !== document.body && active?.contains(target);
      // do not focus button if the clicked element is itself focusable
      this.toggle(null, !clickedTargetIsFocusable);
    });
  }
}
MenuDirective.ɵfac = function MenuDirective_Factory(t) {
  return new (t || MenuDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
MenuDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: MenuDirective,
  selectors: [["", "hlMenu", ""]],
  inputs: {
    static: "static"
  },
  exportAs: ["hlMenu"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuDirective, [{
    type: Directive,
    args: [{
      selector: '[hlMenu]',
      exportAs: 'hlMenu'
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    static: [{
      type: Input
    }]
  });
})();
// MENU ITEM BUTTON
class MenuButtonDirective {
  constructor(elementRef, menu, renderer) {
    this.menu = menu;
    this.renderer = renderer;
    this.element = elementRef.nativeElement;
    menu.menuButton = this;
  }
  ngOnInit() {
    this.initAttributes(this.element);
    this.renderer.listen(this.element, 'click', () => {
      this.menu.toggle();
    });
    this.renderer.listen(this.element, 'keydown', event => {
      switch (event.key) {
        case ' ': // Space
        case 'Enter':
        case 'ArrowDown':
          event.preventDefault();
          this.menu.toggle({
            kind: 'FocusFirst'
          });
          break;
        case 'ArrowUp':
          event.preventDefault();
          this.menu.toggle({
            kind: 'FocusLast'
          });
          break;
      }
    });
  }
  focus() {
    setTimeout(() => this.element?.focus());
  }
  initAttributes(element) {
    element.id = `headlessui-menu-button-${generateId()}`;
    element.setAttribute('type', 'button');
    element.setAttribute('aria-haspopup', 'true');
  }
}
MenuButtonDirective.ɵfac = function MenuButtonDirective_Factory(t) {
  return new (t || MenuButtonDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(MenuDirective), i0.ɵɵdirectiveInject(i0.Renderer2));
};
MenuButtonDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: MenuButtonDirective,
  selectors: [["", "hlMenuButton", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuButtonDirective, [{
    type: Directive,
    args: [{
      selector: '[hlMenuButton]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: MenuDirective
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
/// MENU ITEMS PANEL
class MenuItemsPanelDirective {
  constructor(templateRef, viewContainerRef, menu, renderer) {
    this.templateRef = templateRef;
    this.viewContainerRef = viewContainerRef;
    this.menu = menu;
    this.renderer = renderer;
    this.element = null;
    this.menu.menuItemsPanel = this;
  }
  ngOnInit() {
    if (this.menu.static) {
      this.expandInternal();
    }
  }
  expand() {
    if (!this.menu.static) {
      this.expandInternal();
    }
  }
  collapse() {
    if (!this.menu.static) {
      this.viewContainerRef.clear();
      this.element = null;
    }
  }
  focus() {
    this.element?.focus({
      preventScroll: true
    });
  }
  expandInternal() {
    const view = this.viewContainerRef.createEmbeddedView(this.templateRef);
    const element = view.rootNodes[0];
    this.initAttributes(element);
    this.initListeners(element);
    this.element = element;
    view.markForCheck();
  }
  initAttributes(element) {
    element.tabIndex = -1;
    element.id = `headlessui-menu-items-${generateId()}`;
    element.setAttribute('role', 'menu');
    element.setAttribute('aria-labelledby', this.menu.menuButton.element.id);
  }
  initListeners(element) {
    this.renderer.listen(element, 'keydown', event => {
      switch (event.key) {
        case ' ':
          // Space
          if (this.menu.searchQuery !== '') {
            event.preventDefault();
            this.menu.search(event.key);
          } else {
            event.preventDefault();
            this.menu.clickActive();
          }
          break;
        case 'Enter':
          event.preventDefault();
          this.menu.clickActive();
          break;
        case 'ArrowDown':
          event.preventDefault();
          this.menu.focusItem({
            kind: 'FocusNext'
          });
          break;
        case 'ArrowUp':
          event.preventDefault();
          this.menu.focusItem({
            kind: 'FocusPrevious'
          });
          break;
        case 'Home':
        case 'PageUp':
          event.preventDefault();
          this.menu.focusItem({
            kind: 'FocusFirst'
          });
          break;
        case 'End':
        case 'PageDown':
          event.preventDefault();
          this.menu.focusItem({
            kind: 'FocusLast'
          });
          break;
        case 'Escape':
          event.preventDefault();
          this.menu.toggle();
          break;
        case 'Tab':
          event.preventDefault();
          break;
        default:
          if (event.key.length === 1) {
            this.menu.search(event.key);
          }
      }
    });
  }
}
MenuItemsPanelDirective.ɵfac = function MenuItemsPanelDirective_Factory(t) {
  return new (t || MenuItemsPanelDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(MenuDirective), i0.ɵɵdirectiveInject(i0.Renderer2));
};
MenuItemsPanelDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: MenuItemsPanelDirective,
  selectors: [["", "hlMenuItems", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuItemsPanelDirective, [{
    type: Directive,
    args: [{
      selector: '[hlMenuItems]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }, {
      type: i0.ViewContainerRef
    }, {
      type: MenuDirective
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
// MENU ITEM
class MenuItemDirective {
  constructor(templateRef, viewContainerRef, menu, renderer) {
    this.templateRef = templateRef;
    this.viewContainerRef = viewContainerRef;
    this.menu = menu;
    this.renderer = renderer;
    this.hlMenuItemDisabled = false;
    this.context = {
      active: false
    };
    this.menu.menuItems.push(this);
  }
  ngOnInit() {
    this.view = this.viewContainerRef.createEmbeddedView(this.templateRef, {
      $implicit: this.context
    });
    this.element = this.view.rootNodes[0];
    this.initAttributes(this.element);
    this.initListeners(this.element);
  }
  setActive(active) {
    this.context.active = active;
    this.view.markForCheck();
  }
  initAttributes(element) {
    element.id = `headlessui-menu-item-${generateId()}`;
    element.tabIndex = -1;
    element.setAttribute('role', 'menuitem');
    if (this.hlMenuItemDisabled) {
      this.element.setAttribute('aria-disabled', 'true');
    } else {
      this.element.removeAttribute('aria-disabled');
    }
  }
  initListeners(element) {
    this.renderer.listen(element, 'pointermove', () => this.menu.focusItem({
      kind: 'FocusSpecific',
      item: this
    }));
    this.renderer.listen(element, 'pointerleave', () => this.menu.focusItem({
      kind: 'FocusNothing'
    }));
    this.renderer.listen(element, 'click', event => {
      if (this.hlMenuItemDisabled) {
        event.preventDefault();
        return;
      }
      this.menu.toggle();
    });
  }
}
MenuItemDirective.ɵfac = function MenuItemDirective_Factory(t) {
  return new (t || MenuItemDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(MenuDirective), i0.ɵɵdirectiveInject(i0.Renderer2));
};
MenuItemDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: MenuItemDirective,
  selectors: [["", "hlMenuItem", ""]],
  inputs: {
    hlMenuItemDisabled: "hlMenuItemDisabled"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuItemDirective, [{
    type: Directive,
    args: [{
      selector: '[hlMenuItem]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }, {
      type: i0.ViewContainerRef
    }, {
      type: MenuDirective
    }, {
      type: i0.Renderer2
    }];
  }, {
    hlMenuItemDisabled: [{
      type: Input
    }]
  });
})();
class MenuModule {}
MenuModule.ɵfac = function MenuModule_Factory(t) {
  return new (t || MenuModule)();
};
MenuModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MenuModule
});
MenuModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuModule, [{
    type: NgModule,
    args: [{
      imports: [],
      exports: [MenuDirective, MenuButtonDirective, MenuItemsPanelDirective, MenuItemDirective],
      declarations: [MenuDirective, MenuButtonDirective, MenuItemsPanelDirective, MenuItemDirective],
      providers: []
    }]
  }], null, null);
})();

// eslint-disable-next-line @typescript-eslint/semi

/**
 * Generated bundle index. Do not edit.
 */

export { MenuButtonDirective, MenuDirective, MenuItemDirective, MenuItemsPanelDirective, MenuModule };
