import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EntityStructureInfo } from '../models/entity-structure-info';

@Injectable()
export abstract class BaseService {
    httpClient: HttpClient;
    url: string;
    entityName: string;

    constructor(httpClient: HttpClient, @Inject('url') url: string) {
        this.httpClient = httpClient;
        this.url = environment.apiUrl + url;
        this.entityName = url.replace('/', '');
    }

    /**
     * Send the the given object to the server to be saved in the database
     * @param object The object to be saved
     * @returns An observable with the response from the server
     */
    public save(object: any): Observable<any> {
        return this.httpClient.post<any>(this.url, object);
    }

    /**
     * Send a delete instruction to the server to delete the object with the given id
     * @param id The id of the object to be deleted
     * @returns An observable with the response from the server
     */
    public delete(id: number): Observable<any> {
        return this.httpClient.delete<any>(`${this.url}/${id}`);
    }

    /**
     * Send a patch instruction to the server to update the object with the given id
     * @param id The id of the object to be updated
     * @param body The new values for the object
     * @returns An observable with the response from the server
     */
    public update(id: any, body: any): Observable<any> {
        return this.httpClient.patch<any>(`${this.url}/${id}`, body);
    }

    /**
     * Get all the objects from the server
     * @returns An observable with the response from the server
     */
    public get(): Observable<any> {
        return this.httpClient.get<any>(this.url);
    }

    /**
     * Get the object with the given id from the server
     * @param id The id of the object to be retrieved
     * @returns An observable with the response from the server
     */
    public getById(id: number): Observable<any> {
        return this.httpClient.get<any>(`${this.url}/${id}`);
    }

    public getStructure(): Observable<EntityStructureInfo[]> {
        const params = new HttpParams()
            .append('entity', this.entityName)
            .append('entity', '*');
        return this.httpClient.get<EntityStructureInfo[]>(
            `${environment.apiUrl}entity-properties`,
            { params: params },
        );
    }
}
