export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const TOKEN = 'token';
export const USER = 'user';
export const ORDERS = 'orders';
export const CONFIGURATIONS = 'configurations';

export const SCREEN_LOGGED = 'screenLogged';
export const MAQUINA_LOGGED = 'maquinaLogged';
export const OPERARIO_LOGGED = 'operarioLogged';
export const ROL_LOGGED = 'rolLogged';
