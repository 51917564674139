import { Component, inject } from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from '../../services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/public/services/auth.service';
import { sha256 } from 'js-sha256';

@Component({
    selector: 'app-change-password',
    standalone: true,
    imports: [ReactiveFormsModule],
    templateUrl: './change-password.component.html',
    styleUrl: './change-password.component.scss',
})
export class ChangePasswordComponent {
    readonly message = inject(NzMessageService);
    readonly userService = inject(UserService);
    readonly authService = inject(AuthService);

    private readonly usl = inject(UserLoggedService);
    userLogged!: UserDto;
    changePasswordFormGroup = new FormGroup({
        password: new FormControl('', [Validators.required]),
        newPassword: new FormControl('', [Validators.required]),
        confirmPassword: new FormControl('', [Validators.required]),
    });

    ngOnInit(): void {
        this.userLogged = this.usl.userLogged;
    }

    async changePassword() {
        if (this.changePasswordFormGroup.invalid) {
            this.message.error('Por favor, rellena todos los campos');
            return;
        }

        if (
            this.changePasswordFormGroup.value.newPassword !==
            this.changePasswordFormGroup.value.confirmPassword
        ) {
            this.message.error('Las contraseñas no coinciden');
            return;
        }

        const password = sha256(
            this.changePasswordFormGroup.value.password as string,
        );
        
        const newPassword = sha256(
            this.changePasswordFormGroup.value.newPassword as string,
        );

        const isPasswordChanged: boolean = await this.userService.changePassword(password, newPassword);
        
        if (!isPasswordChanged) {
            this.message.error('Algo ha ido mal cambiando la contraseña');
        }
        
        this.message.success('Contraseña cambiada correctamente, por favor, vuelve a iniciar sesión');
        this.authService.logout();
    }
}
