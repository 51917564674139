<div class="p-10 h-full">
    <nz-page-header class="text-[16px] font-semibold p-0 m-0" nzTitle="Calendario de mantenimientos">
    </nz-page-header>
    <div class="flex justify-between items-center mt-4">
        <!-- Filter Inputs -->
        <div class="flex gap-2">
            <div class="flex flex-col">
                <label for="inputMachine">Máquina</label>
                <input type="text" class="max-w-40 border rounded-lg pl-2 pr-2 py-1 bg-gray-100" name="inputMachine" placeholder="...">
            </div>
            <div class="flex flex-col">
                <label for="inputOperator">Operario</label>
                <input type="text" class="max-w-40 border rounded-lg pl-2 pr-2 py-1 bg-gray-100" name="inputOperator" placeholder="...">
            </div>
        </div>
    </div>
    <nz-calendar>
        <ul *nzDateCell="let date" class="events">
          @switch (date.getDate()) {
            @case (8) {
              @for (item of listDataMap.eight; track $index) {
                <li>
                  <nz-badge [nzStatus]="item.type" [nzText]="item.content"></nz-badge>
                </li>
              }
            }
            @case (11) {
              @for (item of listDataMap.eleven; track $index) {
                <li>
                  <nz-badge [nzStatus]="item.type" [nzText]="item.content"></nz-badge>
                </li>
              }
            }
          }
        </ul>
        <ng-container *nzMonthCell="let month">
          @if (getMonthData(month); as monthData) {
            <div class="notes-month">
              <section>{{ monthData }}</section>
              <span>Backlog number</span>
            </div>
          }
        </ng-container>
      </nz-calendar>
</div>
