// // This file can be replaced during build by using the `fileReplacements` array.
// // `ng build` replaces `environment.ts` with `environment.prod.ts`.
// // The list of file replacements can be found in `angular.json`.

// /**s
//  * LOCAL - Local
//  * Environment variables.
//  */
export const environment = {
    production: false,
    preproduction: false,
    development: true,
    local: false,
    envName: 'dev',
    apiUrl: 'https://dev.samarplast.mes.devoltec.com/api/v1/',
    socketUrl: 'http://backend-api:3000',
    socketPath: '/socket.io',
};

// /*
//  * For easier debugging in development mode, you can import the following file
//  * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//  *
//  * This import should be commented out in production mode because it will have a negative impact
//  * on performance if an error is thrown.
//  */
// // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

// export const environment = {
//     production: false,
//     preproduction: false,
//     development: true,
//     apiUrl: '{{ API_URL }}',
//     socketUrl: '{{ SOCKET_URL }}',
//     socketPath: '{{ SOCKET_PATH }}',
// };
