import { Component } from '@angular/core';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzPageHeaderComponent, NzPageHeaderExtraDirective } from 'ng-zorro-antd/page-header';
import { NzTableCellDirective, NzTableComponent, NzTbodyComponent, NzTheadComponent, NzTrDirective } from 'ng-zorro-antd/table';

interface Maintenance {
    id: string;
    machineId: string;
    status: string;
    element: string;
    interventionChief: string;
    operators: Operator[];
    description: string;
    duration: string;
    date: string;
}

interface Operator {
    id: string;
    name: string;
    surname: string;

}
@Component({
    selector: 'app-maintenances',
    standalone: true,
    imports: [
        NzPageHeaderComponent,
        NzTableComponent,
        NzTheadComponent,
        NzTrDirective,
        NzTableCellDirective,
        NzTbodyComponent,
        NzMessageModule,
        NzPageHeaderExtraDirective
    ],
    templateUrl: './maintenances.component.html',
    styleUrl: './maintenances.component.scss'
})
export class MaintenancesComponent {

    maintenances: Maintenance[] = [
        {
            id: "MP001",
            machineId: "M001",
            status: "Pending",
            interventionChief: "J001",
            operators: [
                { id: "O002", name: "María", surname: "Gómez" }
            ],
            element: "Máquina de extrusión",
            description: "Supervisión y test de todos los componentes de la máquina de extrusión. Sustitución cuando sea necesario.",
            duration: "4",
            date: "08/10/2024 08:00"
        },
        {
            id: "MP002",
            machineId: "M002",
            status: "In Progress",
            interventionChief: "J002",
            operators: [
                { id: "O001", name: "Juan", surname: "Pérez" },
                { id: "O003", name: "Carlos", surname: "López" },
            ],
            element: "Máquina de trenzado",
            description: "Limpieza a fondo de la máquina de trenzado. Sustitución de los elementos principales que forman parte de la dotación.",
            duration: "4",
            date: "08/10/2024 09:00"
        },
        {
            id: "MP003",
            machineId: "T001",
            status: "Completed",
            interventionChief: "J003",
            operators: [
                { id: "O003", name: "Carlos", surname: "López" },
            ],
            element: "Toro de transporte",
            description: "Revisión completa del toro de transporte, incluyendo frenos, ruedas y sistema hidráulico.",
            duration: "8",
            date: "11/10/2024 06:00"
        },
        {
            id: "MP004",
            machineId: "M003",
            status: "Pending",
            interventionChief: "J002",
            operators: [
                { id: "O003", name: "Carlos", surname: "López" },
            ],
            element: "Sistema de bobinado",
            description: "Limpieza a fondo de todo el sistema de bobinado, incluyendo rodillos y guías.",
            duration: "6",
            date: "11/10/2024 18:00"
        },
        {
            id: "MP005",
            machineId: "M004",
            status: "In Progress",
            interventionChief: "J002",
            operators: [
                { id: "O002", name: "María", surname: "Gómez" },
            ],
            element: "Limpieza de estructura",
            description: "Limpieza general de la estructura de la maquinaria, comprobar su correcto funcionamiento.",
            duration: "1",
            date: "11/10/2024 17:30"
        },
        {
            id: "MP006",
            machineId: "S001",
            status: "Completed",
            interventionChief: "J001",
            operators: [
                { id: "O001", name: "Juan", surname: "Pérez" },
                { id: "O002", name: "María", surname: "Gómez" },
            ],
            element: "Estanterías",
            description: "Inspección y certificación ITA de las estanterías para asegurar su seguridad y cumplimiento de normativas.",
            duration: "2",
            date: "11/10/2024 10:00"
        }
    ];
}
