import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { StandardModalComponent } from 'src/app/shared/components/standard-modal/standard-modal.component';
import { IStandardModalData } from 'src/app/shared/interface/standard-modal-data';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard
    implements CanDeactivate<ComponentCanDeactivate>
{
    constructor(private dialog: NzModalService) {}

    async canDeactivate(component: ComponentCanDeactivate) {
        // if there are no pending changes, just allow deactivation; else confirm first
        return component.canDeactivate()
            ? true
            : // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
              // when navigating away from your angular app, the browser will show a generic warning message
              // see http://stackoverflow.com/a/42207299/7307355
              //confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
              await this.openConfirmDialog();
    }

    async openConfirmDialog() {
        const dialogRefAux = this.dialog.create<
            StandardModalComponent,
            IStandardModalData
        >({
            nzContent: StandardModalComponent,
            nzStyle: { width: '30%', top: '10%' },
            nzClosable: false,
            nzFooter: null,
            nzData: {
                close: false,
                title: '¿Está seguro que quieres salir?',
                message: 'Los datos no serán guardados si realiza esta acción.',
            },
        });

        const sub = await dialogRefAux.afterClose.toPromise();

        if (!sub) {
            return false;
        } else {
            return sub;
        }
    }
}
