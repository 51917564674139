<div class="w-full h-[calc(100%_-_44px)] max-h-[calc(100%_-_44px)]">
	<div nz-row class="flex justify-center items-center h-screen">
		<div nz-col [nzSpan]="15" class="h-screen overflow-hidden">
			<div class="imagen-login">
				<img src="/assets/svg/devoltec.svg" alt="" />
			</div>
		</div>
		<div nz-col [nzSpan]="9" style="display: block">
			<div class="flex justify-center items-center h-screen">
				<div class="flex items-center justify-center w-full flex-col">
					<div class="w-[325px] -mt-28">
						<div class="flex items-center justify-center">
							<img class="w-52 h-auto" src="assets/svg/demo-logo.svg" alt="Logo" loading="lazy" />
						</div>
						<div class="w-full flex items-center justify-between">
							<div></div>
							<button (click)="changeRFID()"
								class="text-black bg-gray-200 hover:bg-gray-300 focus:ring-2 focus:ring-gray-400 font-medium rounded-2xl text-sm px-2.5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 mt-5">
								<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
										d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"></path>
								</svg>
							</button>
						</div>

						@if (!showRFID) {
						<app-login-form ></app-login-form>
						} @else {
						<app-rfid-form ></app-rfid-form>
						}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
