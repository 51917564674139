<div class="bg-red-500">
    <div class="mx-auto py-2 px-2 sm:px-6 lg:px-8">
        <div class="flex flex-wrap items-center justify-between">
            <div class="flex w-0 flex-1 items-center">
                <span class="flex rounded-lg bg-red-700 p-1 text-white">
                    <!-- Heroicon name: outline/megaphone -->
                    <svg
                        class="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        ></path>
                    </svg>
                </span>
                <p class="ml-3 truncate font-medium text-white">
                    <span class="md:hidden"
                        >Atención, entorno de pre-producción para ejecución de
                        pruebas y correcto funcionamiento.</span
                    >
                    <span class="hidden md:inline"
                        >Atención, entorno de pre-producción para ejecución de
                        pruebas y correcto funcionamiento.</span
                    >
                </p>
            </div>
        </div>
    </div>
</div>
