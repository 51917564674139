import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';

@Injectable({
    providedIn: 'root'
})
export class SensorsService {
    private machineSensorsSubject = new BehaviorSubject<void>(undefined);
    public machineSensors$ = this.machineSensorsSubject.asObservable();

    private readonly socketService = inject(SocketService);

    async setupWebSocket(): Promise<void> {
        this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.SENSORS_ROOM));
        this.socketService.getNotifications(Messages.SENSOR_DATA_CHANGED).subscribe(async (data) => {
            console.log(data);
            this.machineSensorsSubject.next(data);
        });
    }

    disconnectWebSocket(): void {
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.SENSORS_ROOM));
    }
}
