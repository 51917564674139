<div class="p-10 h-full">
    <nz-page-header class="text-[16px] font-semibold p-0 m-0" nzTitle="Solicitudes de mantenimiento">
        <nz-page-header-extra class="flex">
            <button class="ml-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-4 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 w-full">Añadir</button>
        </nz-page-header-extra>
    </nz-page-header>
    <div #tableContainer class="mt-[15px] h-[calc(100%_-_100px)] max-h-[calc(100%_-_100px)]">
        <nz-table #nestedTable nzTableLayout="auto" nzSize="small" nzTableLayout="auto"
            [nzData]="maintenanceRequests" [nzSimple]="true"
            [nzBordered]="true"
            [nzFrontPagination]="false" nzShowSizeChanger nzBordered
        >
            <thead>
                <tr>
                    <th id="requestId">Nº Solicitud</th>
                    <th id="machineId">Maquinaria</th>
                    <th id="operatorId">Operario</th>
                    <th id="description">Descripción</th>
                    <th id="priority">Prioridad</th>
                </tr>
            </thead>
            <tbody>
                @for(request of nestedTable.data; track request.id){
                    <tr>
                        <td>{{ request.id }}</td>
                        <td>{{ request.machineId }}</td>
                        <td>{{ request.operator }}</td>
                        <td>{{ request.description }}</td>
                        <td>{{ request.priority }}</td>
                    </tr>
                }
            </tbody>
        </nz-table>
    </div>
</div>
