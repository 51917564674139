import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LossDto } from 'src/app/shared/dto/loss.dto';
import { BaseService } from 'src/app/shared/services/base.service';

@Injectable({
    providedIn: 'root',
})
export class LossesService extends BaseService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'losses');
    }

    async create(loss: LossDto): Promise<LossDto> {
        try {
            if (!loss) {
                throw new Error('La merma no es un objeto válido.');
            }

            const createdScrap = await lastValueFrom(
                this.httpClient.post<LossDto>(`${this.url}`, loss)
            );

            return createdScrap as LossDto;
        } catch (e: any) {
            throw new Error(`No se ha podido crear la merma. Error: ${e.message}`);
        }
    }
}
