<nz-drawer
    [nzBodyStyle]="{ overflow: 'auto' }"
    [nzMaskClosable]="false"
    [nzVisible]="visible()"
    [nzTitle]="title()"
    [nzExtra]="extra"
    [nzWidth]="'500px'"
    (nzOnClose)="onCancel()"
>
    <!-- TODO: HACER EL FORMULARIO EN UN HTML EXTERNO -->
    <div *nzDrawerContent class="h-full justify-items-center w-full">
        @if (loading) {
            <app-loader></app-loader>
        } @else {
            <app-dynamic-form
                [formStructure]="formStructure()"
                [listValue]="listValue()"
                [nameSelected]="nameSelected()"
            ></app-dynamic-form>
        }
    </div>
    <ng-template #extra>
        <div class="p-2 float-right">
            @if (drawerMode() === DrawerMode.VIEW) {
                <button
                    nzType="default"
                    nz-button
                    (click)="changeSelected('prev')"
                >
                    <span nzType="left" nz-icon> </span>
                </button>
                <button
                    class="mr-2"
                    nzType="default"
                    nz-button
                    (click)="changeSelected('next')"
                >
                    <span nzType="right" nz-icon> </span>
                </button>
            }
            <button class="mr-2" nz-button (click)="onCancel()">
                Cancelar
            </button>
            @if (
                drawerMode() === DrawerMode.CREATE ||
                drawerMode() === DrawerMode.EDIT
            ) {
                <button
                    nz-button
                    [disabled]="this.disabled"
                    nzType="primary"
                    (click)="submit()"
                >
                    Guardar
                </button>
            }
        </div>
    </ng-template>
</nz-drawer>
