import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/public/services/auth.service';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { MenuModule } from 'headlessui-angular';
import { Router } from '@angular/router';

@Component({
    selector: 'app-dropdown-user',
    templateUrl: './dropdown-user.component.html',
    styleUrls: ['./dropdown-user.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('toggleAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scale(0.95)' }),
                animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
            ]),
            transition(':leave', [
                animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' })),
            ]),
        ]),
    ],
    standalone: true,
    imports: [MenuModule],
})
export class DropdownUserComponent implements OnInit {
    userLogged: UserDto | null = null;
    bottomText: string = '';

    constructor(
        private userLoggedService: UserLoggedService,
        private authService: AuthService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.userLogged = this.userLoggedService.userLogged;

        if (window.location.href.includes('samarplast')) {
            this.bottomText = 'SAMARPLAST | B98704521';
        } else if (window.location.href.includes('roses')) {
            this.bottomText = 'SEÑALIZACIONES ROSES | B96424809';
        } else {
            this.bottomText = 'LOCALHOST | BXXXXXXXX';
        }
    }

    goToChangePassword() {
        this.router.navigate(['/change-password']);
    }

    goToConfiguration() {
        this.router.navigate(['/configuration']);
    }

    async logout() {
        await this.authService.registerLogout(this.userLogged?.id ?? -1);
        this.authService.logout();
    }
}
