<div *nzModalTitle class="px-0 py-1">
	Nueva Merma de {{ articleName }} - Orden {{ processId }} | {{ processDescription }}
</div>
<div class="ml-4 mr-4">
	<div class="mt-1">
		<form nz-form [nzLayout]="'inline'" [formGroup]="validateForm" (ngSubmit)="submitForm()">
			<nz-form-item>
				<nz-form-control nzErrorTip="Indique información acerca de la merma">
					<nz-input-group>
						<input formControlName="observations" nz-input placeholder="Observaciones" class="searchSelectors"/>
					</nz-input-group>
				</nz-form-control>
			</nz-form-item>
			<nz-form-item>
				<nz-form-control nzErrorTip="Indique la cantidad de producto mermado">
					<nz-input-group>
						<input formControlName="quantity" nz-input type="number" placeholder="Cantidad" class="searchSelectors"/>
					</nz-input-group>
				</nz-form-control>
			</nz-form-item>
			<nz-form-item>
				<nz-form-control nzErrorTip="Indique la unidad de medida de la merma">
					<nz-input-group>
						<nz-select formControlName="selectedMeasurementUnit" class="nz-select-custom"
							(ngModelChange)="setMeasurement($event)">
							@for (measurement of measurementUnits; track measurement) {
							<nz-option nzValue="{{ measurement.id }}" nzLabel="{{ measurement.abbreviation }}">
							</nz-option>
							}
						</nz-select>
					</nz-input-group>
				</nz-form-control>
			</nz-form-item>
			
			<nz-form-item>
				<nz-form-control>
					<button nzType="primary" class="mt-1.5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" [disabled]="!validateForm.valid">
						Crear
					</button>
				</nz-form-control>
			</nz-form-item>
		</form>
	</div>

	<!-- <div *nzModalFooter style="border: 0px;">
<button nz-button nzType="primary" [disabled]="!validateForm.valid">Crear</button>
<button type="submit"
class="mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Aceptar</button>
</div> -->
</div>