<div *nzModalTitle class="px-0 py-1">
	Nueva Tara - Orden {{ processId }} | {{ processDescription }}
</div>
<div class="ml-4 mr-4">
	<!-- <div class="w-full h-full p-0 m-0 mb-0.5" *ngIf="scraps.length > 0">
<nz-table #basicTable [nzData]="scraps" class="m-0 p-0" [nzLoading]="isLoading" nzTableLayout="auto"
  [nzBordered]="true" [nzFrontPagination]="false" [nzShowPagination]="false" [nzScroll]="{ y: '350px' }">
  <thead>
    <tr>
      <th>Pieza</th>
      <th>Motivo</th>
      <th nzWidth="120px">Metros</th>
      <th>Fecha</th>
      <th>Observaciones</th>
      <th>Operario</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of basicTable.data">
      <td>{{article.name}}</td>
      <td>{{data.scrapCause.text}}</td>
      <td class="text-right">{{data.units}}{{article.measurementUnit.abbreviation}}</td>
      <td>{{data.date === null ? '-' : data.date | date: "dd/MM/YYYY HH:mm:ss"}}</td>
      <td>{{data.observations}}</td>
      <td>{{operatorName}}</td>
    </tr>
  </tbody>
</nz-table>
</div> -->

	<div class="mt-1">
		<form nz-form [nzLayout]="'inline'" [formGroup]="validateForm" (ngSubmit)="submitForm()">
			<nz-form-item>
				<nz-form-control nzErrorTip="Indique información acerca de la tara">
					<nz-input-group>
						<input formControlName="observations" nz-input placeholder="Observaciones" class="searchSelectors"/>
					</nz-input-group>
				</nz-form-control>
			</nz-form-item>
			<nz-form-item>
				<nz-form-control nzErrorTip="Indique la cantidad de producto tarado">
					<nz-input-group>
						<input formControlName="quantity" nz-input type="number" placeholder="Cantidad" class="searchSelectors"/>
					</nz-input-group>
				</nz-form-control>
			</nz-form-item>
			<nz-form-item>
				<nz-form-control nzErrorTip="Indique la razón de la tara">
					<nz-input-group>
						<nz-select formControlName="selectedReason" class="nz-select-custom"
							(ngModelChange)="loadCauses($event)">
							@for (reason of scrapReasons; track reason) {
							<nz-option nzValue="{{ reason.id }}" nzLabel="{{ reason.text }}">
							</nz-option>
							}
						</nz-select>
					</nz-input-group>
				</nz-form-control>
			</nz-form-item>
			<nz-form-item>
				<nz-form-control nzErrorTip="Indique la causa de la tara">
					<nz-input-group>
						<nz-select formControlName="selectedCause" class="nz-select-custom"
							(ngModelChange)="loadCauses($event)">
							@for (cause of scrapCauses; track cause) {
							<nz-option nzValue="{{ cause.id }}" nzLabel="{{ cause.text }}">
							</nz-option>
							}
						</nz-select>
					</nz-input-group>
				</nz-form-control>
			</nz-form-item>
			<!-- <nz-form-item>
    <nz-form-control nzErrorTip="Indique la causa de la tara">
      <nz-input-group>
        <nz-select ngModel="lucy">
          <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
          <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
          <nz-option nzValue="disabled" nzLabel="Disabled" nzDisabled></nz-option>
        </nz-select>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item> -->
			<nz-form-item>
				<nz-form-control>
					<button nzType="primary" class="mt-1.5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" [disabled]="!validateForm.valid">
						Crear
					</button>
				</nz-form-control>
			</nz-form-item>
		</form>
	</div>

	<!-- <div *nzModalFooter style="border: 0px;">
<button nz-button nzType="primary" [disabled]="!validateForm.valid">Crear</button>
<button type="submit"
class="mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Aceptar</button>
</div> -->
</div>