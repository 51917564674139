import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { ɵNzTransitionPatchDirective } from 'ng-zorro-antd/core/transition-patch';
import { NzWaveDirective } from 'ng-zorro-antd/core/wave';
import { NzDividerComponent } from 'ng-zorro-antd/divider';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzPageHeaderComponent, NzPageHeaderContentDirective, NzPageHeaderExtraDirective } from 'ng-zorro-antd/page-header';
import { NzTableCellDirective, NzTableComponent, NzTbodyComponent, NzTheadComponent, NzThMeasureDirective, NzTrDirective } from 'ng-zorro-antd/table';
import { lastValueFrom } from 'rxjs';
import { AcctionsEdit } from 'src/app/core/enums/acctions-edit';
import { DrawerMode } from 'src/app/core/enums/drawer-mode';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { ProductionSubprocess } from 'src/app/shared/models/production-order/production-subprocess.model';
import { FormService, ItemFormStructure } from 'src/app/shared/services/form.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { DrawerFormComponent } from '../../components/drawer-form/drawer-form.component';
import { ProductionSubprocessService } from '../../services/production-subprocess.service';

@Component({
    selector: 'app-production-subprocesses',
    templateUrl: './production-subprocesses.component.html',
    styleUrl: './production-subprocesses.component.scss',
    standalone: true,
    imports: [
        NzPageHeaderComponent,
        NzPageHeaderExtraDirective,
        NzButtonComponent,
        NzWaveDirective,
        ɵNzTransitionPatchDirective,
        NzPageHeaderContentDirective,
        NzTableComponent,
        NzTheadComponent,
        NzTrDirective,
        NzTableCellDirective,
        NzThMeasureDirective,
        NzTbodyComponent,
        NzDividerComponent,
        DrawerFormComponent,
        NzMessageModule,
    ],
})

export class ProductionSubprocessesComponent implements OnInit, OnDestroy {
    @ViewChild('nestedTable', { static: false })
    table?: NzTableComponent<ProductionSubprocess>;

    drawerMode: DrawerMode = DrawerMode.NONE;
    routeActually: string = '';
    title: string = '';
    formStructure: ItemFormStructure[] = [];
    componentService: any;
    showDrawer = false;
    prodSubprocesses: ProductionSubprocess[] = [];
    loading: boolean = false;
    userName: string | undefined = '';
    userLogged: { name: string; rol: string; token: string } | null = null;
    columns = [
        {
            title: 'Nombre',
            dataIndex: 'nombre'
        },
        {
            title: 'Descripción',
            dataIndex: 'description'
        },
        {
            title: 'Acciones'
        }
    ];

    constructor(
        public prodSubprocess: ProductionSubprocessService,
        private _toast: NzMessageService,
        private _router: Router,
        private _formService: FormService,
        private _socketService: SocketService,
        private _userLoggedService: UserLoggedService,
    ) { }

    async ngOnInit(): Promise<void> {
        try {
            this.loading = true;

            this.userName = this._userLoggedService.userLogged?.username;
            this.componentService = this.prodSubprocess;

            const prodSubprocesses = await this.getProductionSubprocesss();
            this.setTableData(prodSubprocesses);

            this.routeActually = this._router.url;
        } catch (error: any) {
            this._toast.error(`Error cargando los subprocesos de producción. Error: ${error.message}`);
        } finally {
            this.loading = false;
        }
    }

    ngOnDestroy(): void {
        this._socketService.disconnectSocketRoom(this.routeActually);
    }

    /**
     * Si cambia el nzData se tiene que reasigna el data a la tabla.
     * @param data
     */
    setTableData(data: ProductionSubprocess[]): void {
        this.prodSubprocesses = _.cloneDeep(data);
        this.table!.nzData = data;
    }

    async getProductionSubprocesss() {
        const prodSubprocesses$ = await lastValueFrom(
            this.prodSubprocess.get(),
        );

        return prodSubprocesses$;
    }

    async create(): Promise<void> {
        this.drawerMode = DrawerMode.CREATE;
        this.title = 'Crear Subproceso de producción';

        const structure = await lastValueFrom(this.prodSubprocess.getStructure());

        this.formStructure = _.cloneDeep(this._formService.mapEntityToFormStructure(structure));
        this.showDrawer = true;
    }

    async edit(data: ProductionSubprocess): Promise<void> {
        this.drawerMode = DrawerMode.EDIT;
        this.title = `Editar subproceso de producción ${data.name}`;
        
        const prodSubprocess = (await lastValueFrom(this.prodSubprocess.getById(data.id)))[0];
        const structure = await lastValueFrom(this.prodSubprocess.getStructure());
        
        this.formStructure = _.cloneDeep(
            this._formService.mapEntityToFormStructure(
                structure,
                false,
                prodSubprocess
            )
        );

        this.showDrawer = true;
    }

    async view(data: ProductionSubprocess): Promise<void> {
        this.drawerMode = DrawerMode.VIEW;
        this.title = `Subproceso de producción ${data.name}`;

        const prodSubprocess = (
            await lastValueFrom(this.prodSubprocess.getById(data.id))
        )[0];

        const structure = await lastValueFrom(
            this.prodSubprocess.getStructure()
        );

        this.formStructure = _.cloneDeep(
            this._formService.mapEntityToFormStructure(
                structure,
                true,
                prodSubprocess
            )
        );

        this.showDrawer = true;
    }

    async delete(data: ProductionSubprocess) {
        const res = await lastValueFrom(this.prodSubprocess.delete(data.id));

        if (res === 0) {
            this._toast.error('No se ha eliminado ningún subproceso.');
        } else {
            this._socketService.sendNotification(
                AcctionsEdit.DELETE,
                this.routeActually,
                'reload-data'
            );

            this._toast.error(
                res > 1
                    ? `Se han eliminado ${res} subprocesos`
                    : `Se ha eliminado el subproceso ${data.name}`
            );
        }
    }

    async changeSelected(direction: string): Promise<void> {
        const id = this.formStructure.find(
            (structure) => structure['name'] === 'id'
        )!.value;

        const index = this.prodSubprocesses.findIndex(
            (prodSubprocess) => prodSubprocess.id === id
        );

        let entity = this.prodSubprocesses[index];
        const limit = this.prodSubprocesses.length - 1;

        if (direction == 'prev' && index > 0) {
            entity = this.prodSubprocesses[index - 1];
        } else if (direction == 'next' && index < limit) {
            entity = this.prodSubprocesses[index + 1];
        }

        if (this.drawerMode === DrawerMode.EDIT) {
            await this.edit(entity);
        } else if (this.drawerMode === DrawerMode.VIEW) {
            await this.view(entity);
        }
    }

    @HostListener('window:beforeunload')
    canDeactivate(): boolean {
        //TODO: not implemented
        return true;
    }
}
