<div class="ml-4 mr-4 mb-4">
    <div>
        <br>
        @for (cf of customFields; track cf.id) {
            @switch (cf.propertiesFormated?.type) {
                @case (typesEnum.INPUT_TEXT) {
                    <div>
                        <label>{{cf.propertiesFormated?.name}}</label>
                        <input nz-input [(ngModel)]="cf.value" type="text"
                        [style]="
                            (cf.propertiesFormated?.style ?? '')
                            + (cf.propertiesFormated?.height ? 'height:' + cf.propertiesFormated?.height + 'px;' : '')
                            + (cf.propertiesFormated?.width ? 'width:' + cf.propertiesFormated?.width + 'px;' : '')
                        "
                        [class]="cf.propertiesFormated?.cssClass ?? ''"
                        [id]="cf.entity+cf.id" [title]="cf.propertiesFormated?.description" [placeholder]="cf.propertiesFormated?.name" />
                    </div>
                }
                @case (typesEnum.INPUT_NUMBER) {
                    <div>
                        <label>{{cf.propertiesFormated?.name}}</label>
                        <input nz-input [(ngModel)]="cf.value" type="number"
                        [style]="
                            (cf.propertiesFormated?.style ?? '')
                            + (cf.propertiesFormated?.height ? 'height:' + cf.propertiesFormated?.height + 'px;' : '')
                            + (cf.propertiesFormated?.width ? 'width:' + cf.propertiesFormated?.width + 'px;' : '')
                        "
                        [class]="cf.propertiesFormated?.cssClass ?? ''"
                        [id]="cf.entity+cf.id" [title]="cf.propertiesFormated?.description" [placeholder]="cf.propertiesFormated?.name"
                        [min]="cf.restrictionsFormated?.min ?? null" [max]="cf.restrictionsFormated?.max ?? null" />
                    </div>
                }
                @case (typesEnum.CHECKBOX) {
                    <div>
                        <nz-switch [(ngModel)]="cf.value" [id]="cf.entity+cf.id"
                            [title]="cf.propertiesFormated?.description ?? ''"
                            [class]="cf.value ? 'switchOn' : 'switchOff'"
                            ></nz-switch>&nbsp;
                        <label>{{cf.propertiesFormated?.name}}</label>
                    </div>
                }
                @case (typesEnum.RADIOBUTTONGROUP) {
                    <div>
                        <label>{{ cf.propertiesFormated?.name }}</label>
                        <br>
                        <nz-radio-group [(ngModel)]="cf.value">
                            @for (option of cf.propertiesFormated?.optionsFormatted; track option) {
                                <label nz-radio [nzValue]="option" [title]="cf.propertiesFormated?.description ?? ''"
                                [style]="
                                    (cf.propertiesFormated?.style ?? '')
                                    + (cf.propertiesFormated?.height ? 'height:' + cf.propertiesFormated?.height + 'px;' : '')
                                    + (cf.propertiesFormated?.width ? 'width:' + cf.propertiesFormated?.width + 'px;' : '')
                                "
                                [class]="cf.propertiesFormated?.cssClass ?? ''"
                                >{{option}}</label>
                            }
                        </nz-radio-group>
                    </div>
                }
                @case (typesEnum.SELECT) {
                    <div>
                        <label>{{ cf.propertiesFormated?.name }}</label>
                        <nz-select nzShowSearch [(ngModel)]="cf.value" [name]="cf.entity"
                        [style]="
                            (cf.propertiesFormated?.style ?? '')
                            + (cf.propertiesFormated?.height ? 'height:' + cf.propertiesFormated?.height + 'px;' : '')
                            + (cf.propertiesFormated?.width ? 'width:' + cf.propertiesFormated?.width + 'px;' : '')
                        "
                        [class]="cf.propertiesFormated?.cssClass ?? ''"
                        [title]="cf.propertiesFormated?.description">
                            @for (option of cf.propertiesFormated?.optionsFormatted; track option) {
                                <nz-option [nzLabel]="option" [nzValue]="option"></nz-option>
                            }
                        </nz-select>
                    </div>
                }
                @case (typesEnum.MULTISELECT) {
                    <div>
                        <label>{{ cf.propertiesFormated?.name }}</label>
                        <nz-select [nzMaxTagCount]="5" [nzMaxTagPlaceholder]="tagPlaceHolder" nzMode="multiple"
                        nzPlaceHolder="Selecciona" [(ngModel)]="cf.value" name="familiesSelector"
                        [style]="
                            (cf.propertiesFormated?.style ?? '')
                            + (cf.propertiesFormated?.height ? 'height:' + cf.propertiesFormated?.height + 'px;' : '')
                            + (cf.propertiesFormated?.width ? 'width:' + cf.propertiesFormated?.width + 'px;' : '')
                        "
                        [class]="cf.propertiesFormated?.cssClass ?? ''"
                        [nzMaxMultipleCount]="cf.propertiesFormated?.multiSelectMaxChoices ?? infinity">
                        @for (option of cf.propertiesFormated?.optionsFormatted; track option) {
                            <nz-option [nzLabel]="option" [nzValue]="option"></nz-option>
                        }
                    </nz-select>
                    <ng-template #tagPlaceHolder let-selectedList>y {{ selectedList.length }} mas seleccionados</ng-template>
                    </div>
                }
            }
            <nz-divider></nz-divider>
        }
    </div>

    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" (click)="handleFinishButton()">
        Terminar control de calidad
    </button>
</div>