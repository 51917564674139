import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTableModule } from 'ng-zorro-antd/table';
import { Subject, takeUntil } from 'rxjs';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { MachineMeasurementDto } from 'src/app/shared/dto/machine-measurements.dto';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { MachineMeasurementsService } from '../../services/machine-measurements.service';

@Component({
    selector: 'app-machine-values',
    standalone: true,
    imports: [
        NzPageHeaderModule,
        NzSpaceModule,
        NzTableModule,
        NzDividerModule,
        NzDrawerModule,
        NzDatePickerModule,
        FormsModule,
        NzMessageModule,
    ],
    templateUrl: './machine-values.component.html',
    styleUrl: './machine-values.component.scss'
})
export class MachineValuesComponent implements OnInit, OnDestroy {
    readonly machineMeasurementsService = inject(MachineMeasurementsService);
    readonly message = inject(NzMessageService);
    readonly userLoggedService = inject(UserLoggedService);

    machineMeasurements: MachineMeasurementDto[] = [];
    drawerVisible: boolean = false;
    fieldsReadOnly: boolean = true;
    drawerTitle: string = 'Placeholder';
    machineMeasurementSelected: MachineMeasurementDto = new MachineMeasurementDto();
    createdAt: Date = new Date();
    updatedAt: Date = new Date();
    isNewMachineMeasurement: boolean = false;
    currentUser: UserDto = new UserDto();
    loadingTable: boolean = true;

    private unsubscribe$ = new Subject<void>();


    async ngOnInit() {
        this.currentUser = this.userLoggedService.userLogged;
        await this.machineMeasurementsService.setupWebSocket();
        this.machineMeasurementsService.machineMeasurements$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (machineMeasurements: MachineMeasurementDto[]) => {
                    this.machineMeasurements = machineMeasurements;
                    this.machineMeasurementSelected = this.machineMeasurements.find(mm => mm.id === this.machineMeasurementSelected.id) || new MachineMeasurementDto();
                    this.loadingTable = false;
                },
                error: () => {
                    this.message.error('Error cargando valores de máquina');
                }
            });
        this.loadingTable = false;
    }

    ngOnDestroy(): void {
        this.machineMeasurementsService.disconnectWebSocket();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    createMachineMeasurements() {
        this.fieldsReadOnly = false;
        this.machineMeasurementSelected = new MachineMeasurementDto();
        this.drawerTitle = 'Creando nuevo valor de máquina';
        this.isNewMachineMeasurement = true;
        this.openDrawer();
    }

    seeMachineMeasurements(id: number) {
        this.fieldsReadOnly = true;
        this.machineMeasurementSelected = this.machineMeasurements.find(
            (p) => p.id === id,
        ) as MachineMeasurementDto;
        this.drawerTitle = 'Viendo valor de máquina ' + this.machineMeasurementSelected.measurement;
        this.setData();
        this.openDrawer();
    }

    editMachineMeasurements(id: number) {
        this.fieldsReadOnly = false;
        this.isNewMachineMeasurement = false;
        this.machineMeasurementSelected = this.machineMeasurements.find(
            (p) => p.id === id,
        ) as MachineMeasurementDto;
        this.drawerTitle = 'Editando valor de máquina ' + this.machineMeasurementSelected.measurement;
        this.setData();
        this.openDrawer();
    }

    setData() {
        // This does nothing, it's just for consistency
    }

    cancelEditing() {
        this.closeDrawer();
    }

    deleteMachineMeasurements(id: number) {
        if (
            window.confirm(
                '¿Estás seguro de que quieres eliminar este valor de máquina? Esta acción no se puede deshacer.',
            )
        ) {
            this.machineMeasurementsService.delete(id).subscribe({
                next: (machineValue: MachineMeasurementDto) => {
                    try {
                        if (machineValue == undefined) {
                            throw new Error('Machine value not found');
                        }
                        const index = this.machineMeasurements.findIndex((p) => p.id === id);
                        if (index < 0) {
                            this.message.create('error', `No se ha podido eliminar el valor de máquina ${machineValue.measurement} con id ${id}, valor de máquina no encontrado`);
                            throw new Error('Machine value not found');
                        }
                        this.machineMeasurements.splice(index, 1);
                        const data = [...this.machineMeasurements];
                        this.machineMeasurements = data;
                        this.message.create('success', 'Valor de máquina eliminado correctamente',);
                    } catch (error) {
                        this.message.create('error', 'Error eliminando valor de máquina');
                        console.error(error);
                    }
                },
                error: (error) => {
                    this.message.create('error', 'Error eliminando valor de máquina, error de servidor');
                    console.error(error);
                },
            });
        } else {
            console.log(
                'Cancelando eliminación de valor de máquina ' + id,
            );
        }
    }

    openDrawer() {
        this.drawerVisible = true;
    }

    closeDrawer() {
        this.drawerVisible = false;
    }

    saveEditing() {
        const customMachineMeasurementDto: MachineMeasurementDto = {
            id: this.machineMeasurementSelected.id,
            measurement: (<HTMLInputElement>document.getElementById('measurement')).value
                ? ((<HTMLInputElement>document.getElementById('measurement'))
                    .value as string)
                : '',
        };

        if (!this.validForm(customMachineMeasurementDto)) {
            return;
        }

        if (this.isNewMachineMeasurement) {
            this.machineMeasurementsService.save(customMachineMeasurementDto).subscribe({
                next: (machineMeasurement) => {
                    try {
                        if (machineMeasurement == undefined) {
                            throw new Error('Error creating machine value');
                        }
                        this.machineMeasurements.push(machineMeasurement);
                        const data = [...this.machineMeasurements];
                        this.machineMeasurements = data;
                        this.message.create('success', 'Valor de máquina creado correctamente');
                        this.closeDrawer();
                    } catch (error) {
                        this.message.create('error', 'Error creando valor de máquina');
                        console.error('Error creating route', error);
                    }
                },
                error: (error) => {
                    this.message.create('error', 'Error creando valor de máquina, error de servidor');
                    console.error('Error creating route', error);
                },
            });
        } else {
            this.machineMeasurementsService.update(customMachineMeasurementDto.id, customMachineMeasurementDto).subscribe({
                next: (machineMeasurement: MachineMeasurementDto) => {
                    try {
                        if (machineMeasurement == undefined) {
                            throw new Error('Error updating machine measurements');
                        }
                        const index = this.machineMeasurements.findIndex(
                            (r) => r.id === machineMeasurement.id,
                        );
                        if (index < 0) {
                            this.message.create('error', `Valor de máquina ${customMachineMeasurementDto.measurement} con id ${customMachineMeasurementDto.id} no encontrado`);
                            throw new Error('Machine value not found');
                        }
                        this.machineMeasurements[index] = machineMeasurement;
                        const data = [...this.machineMeasurements];
                        this.machineMeasurements = data;
                        this.message.create('success', 'Valor de máquina actualizado correctamente',);
                        this.closeDrawer();
                    } catch (error) {
                        this.message.create('error', 'Error actualizando valor de máquina');
                        console.error('Error updating machine measurements', error);
                    }
                },
                error: (error) => {
                    this.message.create('error', 'Error actualizando valor de máquina');
                    console.error('Error updating machine measurements', error);
                },
            });
        }
    }

    validForm(machineMeasurement: MachineMeasurementDto): boolean {
        if (!machineMeasurement.measurement) {
            this.message.create(
                'error',
                'La medida es obligatoria',
            );
            return false;
        }
        return true;
    }
}
