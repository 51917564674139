<div class="p-10 h-full">
	<nz-page-header class="text-[16px] font-semibold p-0 m-0" nzTitle="Subprocesos">
		<nz-page-header-extra>
			<button nz-button nzType="primary" (click)="create()">Nuevo</button>
		</nz-page-header-extra>
		<nz-page-header-content> </nz-page-header-content>
	</nz-page-header>

	<nz-table #nestedTable nzShowSizeChanger nzTableLayout="auto" [nzData]="prodSubprocesses" [nzLoading]="loading"
		[nzSimple]="true" nzTableLayout="auto" nzBordered>
		<thead>
			<tr>
				@for (column of columns; track column) {
				<th>
					{{ column.title }}
				</th>
				}
			</tr>
		</thead>
		<tbody>
			@for (data of nestedTable.data; track data) {
			<tr>
				<td>{{ data.name }}</td>
				<td>{{ data.description }}</td>
				<td>
					<button nz-button nzType="primary" (click)="view(data)">
						Ver
					</button>
					<nz-divider nzType="vertical"></nz-divider>
					<button nz-button nzDanger nzType="primary" (click)="edit(data)">
						Editar
					</button>
					<nz-divider nzType="vertical"></nz-divider>
					<button nz-button nzType="primary" (click)="delete(data)">
						Eliminar
					</button>
				</td>
			</tr>
			}
		</tbody>
	</nz-table>
</div>
<div id="drawer-form">
	<app-drawer-form [(visible)]="showDrawer" [drawerMode]="drawerMode" [title]="title" [routeActually]="routeActually"
		[formStructure]="formStructure" [componentService]="prodSubprocess"
		(changeSelectedItem)="changeSelected($event)">
	</app-drawer-form>
</div>
