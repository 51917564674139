import { ProductionProcessActionDto } from './production-process-action.dto';

export class ProductionProcessDto {
    id!: number;
    name!: string;
    createdAt?: Date;
    updatedAt?: Date;
    createdBy?: number;
    updatedBy?: number;
    productionProcessActions?: ProductionProcessActionDto[];
    qualityControl?: boolean;
}
