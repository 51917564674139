import { MachineMeasurementsValueDto } from "./machine-measurements-value.dto";

export class MachineDto {
    id!: number;
    name!: string;
    ip!: string;
    machineMeasurements?: MachineMeasurementsValueDto[];
    createdAt?: Date;
    updatedAt?: Date;
    createdBy!: number;
    updatedBy!: number;
    queueName?: string;
}
