<div class="ml-4 mr-4">
    <nz-page-header
        class="site-page-header"
        nzTitle="Valores de máquina"
        nzSubtitle="Valores de máquina en el sistema"
    >
        <nz-page-header-extra>
            <nz-space>
                <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    (click)="createMachineMeasurements()"
                >
                    Añadir valor de máquina
                </button>
            </nz-space>
        </nz-page-header-extra>
    </nz-page-header>

    <nz-table #customersTable [nzData]="machineMeasurements" [nzLoading]="loadingTable">
        <thead>
            <tr>
                <th>Medida</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            @for (data of customersTable.data; track data) {
                <tr>
                    <td>{{ data.measurement }}</td>
                    <td>
                        <div class="buttonsDnDTableContainer">
							<img src="assets/icons/eye-icon.svg" class="h-6 w-6" (click)="seeMachineMeasurements(data.id || 0)" title="Ver">
							<nz-divider nzType="vertical"></nz-divider>
							<img src="assets/icons/edit-icon.svg" class="h-6 w-6" (click)="editMachineMeasurements(data.id || 0)" title="Editar">
							<nz-divider nzType="vertical"></nz-divider>
							<img src="assets/icons/xmark-icon.svg" class="h-6 w-6" (click)="deleteMachineMeasurements(data.id || 0)" title="Eliminar">
						</div>
                    </td>
                </tr>
            }
        </tbody>
    </nz-table>
</div>

<nz-drawer
    [nzClosable]="false"
    [nzVisible]="drawerVisible"
    nzPlacement="right"
    [nzTitle]="drawerTitle"
    (nzOnClose)="closeDrawer()"
    [nzWidth]="'500px'"
    [nzMaskClosable]="fieldsReadOnly"
>
    <ng-container *nzDrawerContent>
        <form (ngSubmit)="saveEditing()">
            
            <label>Medida:</label>
            <input nz-input [value]="machineMeasurementSelected.measurement ? machineMeasurementSelected.measurement : ''" type="text" class="searchSelectors" [readOnly]="fieldsReadOnly" id="measurement" />

            @if (!fieldsReadOnly) {
                <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                >
                    Guardar
                </button>
                <nz-divider nzType="vertical"></nz-divider>
                <button
                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    (click)="cancelEditing()"
                >
                    Cancelar
                </button>
            }
        </form>
    </ng-container>
</nz-drawer>
