import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ScreensResponseDto } from 'src/app/shared/dto/pagination/screens-response.dto';
import { ScreenDto } from 'src/app/shared/dto/screen.dto';
import { BaseService } from 'src/app/shared/services/base.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';

@Injectable({
    providedIn: 'root',
})
export class ScreenService extends BaseService {
    private screensSubject = new BehaviorSubject<ScreenDto[]>([]);
    public screens$ = this.screensSubject.asObservable();

    private readonly socketService = inject(SocketService);

    constructor(httpClient: HttpClient) {
        super(httpClient, 'screens');
    }

    async setupWebSocket(): Promise<void> {
        this.screensSubject.next((await this.findAll()).screens);
        this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.SCREENS_ROOM));
        this.socketService.getNotifications(Messages.SCREENS_CHANGED).subscribe(async () => {
            const response = await this.findAll();
            this.screensSubject.next(response.screens);
        });
    }

    disconnectWebSocket(): void {
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.SCREENS_ROOM));
    }


    async getByIp(ip: string): Promise<ScreenDto[]> {
        try {
            if (ip === '') {
                throw new Error('Se ha de facilitar una ip válida');
            }

            const request = this.httpClient.get<ScreenDto[]>(this.url + `/ip/${ip}`);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se ha encontrado ninguna pantalla. Error: ${e.message}`);
        }
    }

    async assignMachines(operation: string, screenId: number, ids: { screenId: number; machineId: number }[]): Promise<void> {
        try {
            if (operation === '') {
                throw new Error('Se ha de facilitar una operación válida');
            }

            if (screenId <= 0) {
                throw new Error('El id de la pantalla ha de ser mayor de 0');
            }

            const body = { operation: operation, ids: ids };
            const request = this.httpClient.post<void>(this.url + `/${screenId}/machines`, body);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se ha podido asignar ninguna máquina. Error: ${e.message}`);
        }
    }

    async findAll(): Promise<ScreensResponseDto> {
        try {
            const request = this.httpClient.get<ScreensResponseDto>(`${this.url}/findAll`);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se ha encontrado ninguna pantalla. Error: ${e.message}`);
        }
    }
}
