<div class="ml-4 mr-4">
    <nz-page-header class="site-page-header" nzTitle="Rutas de producción"
        nzSubtitle="Rutas de producción en el sistema">
        <nz-page-header-extra>
            <nz-space>
                <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button" (click)="openCreateRouteDrawer()">
                    Añadir ruta
                </button>
            </nz-space>
        </nz-page-header-extra>
    </nz-page-header>

    <nz-table #routesTable [nzData]="routes" [nzLoading]="loadingTable">
        <thead>
            <tr>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            @for (data of routesTable.data; track data) {
            <tr>
                <td>{{ data.name }}</td>
                <td>{{ data.description }}</td>
                <td>
                    <div class="buttonsDnDTableContainer">
                        <img src="assets/icons/eye-icon.svg" class="h-6 w-6" (click)="seeRoute(data.id)" title="Ver" alt="Ver">
                        <nz-divider nzType="vertical"></nz-divider>
                        <img src="assets/icons/edit-icon.svg" class="h-6 w-6" (click)="editRoute(data.id)" title="Editar" alt="Editar">
                        <nz-divider nzType="vertical"></nz-divider>
                        <img src="assets/icons/xmark-icon.svg" class="h-6 w-6" (click)="deleteRoute(data.id)" title="Eliminar" alt="Eliminar">
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </nz-table>
</div>

<nz-drawer [nzClosable]="false" [nzVisible]="drawerVisible" nzPlacement="right" [nzTitle]="drawerTitle"
    (nzOnClose)="closeDrawer()" [nzWidth]="'500px'" [nzMaskClosable]="fieldsReadOnly">
    <ng-container *nzDrawerContent>
        <form (ngSubmit)="saveEditing()">

            <label>Nombre:</label>
            <input nz-input [value]="routeSelected.name ? routeSelected.name : ''" type="text" class="searchSelectors"
                [readOnly]="fieldsReadOnly" id="name" />

            <label>Descripción:</label>
            <input nz-input [value]="routeSelected.description ? routeSelected.description : ''" type="text"
                class="searchSelectors" [readOnly]="fieldsReadOnly" id="description" />

            <label>Procesos de producción</label>
            <nz-select [nzMaxTagCount]="5" [nzMaxTagPlaceholder]="tagPlaceHolder" nzMode="multiple"
                nzPlaceHolder="Selecciona" [(ngModel)]="selectedProcessess" (ngModelChange)="onProductionProcessessChange($event)"
                name="ppSelector" class="searchSelectors" [nzDisabled]="fieldsReadOnly">
                @for (pp of productionProcesses; track pp.id) {
                <nz-option [nzLabel]="pp.name" [nzValue]="pp"></nz-option>
                }
            </nz-select>
            <ng-template #tagPlaceHolder let-selectedList>y {{ selectedList.length }} mas seleccionados</ng-template>
            <br>

            <nz-table [nzData]="productionProcessTableRows ? productionProcessTableRows : []" [nzFrontPagination]="false" [nzShowPagination]="false">
                <thead>
                    <tr>
                        <th colspan="2">
                            @if (!fieldsReadOnly) {
                            Orden de los procesos (Arrastra para cambiar)
                            }
                            @else {
                            Orden de los procesos
                            }
                        </th>
                    </tr>
                    <tr>
                        <th>º</th>
                        <th>Proceso</th>
                        <th [hidden]="fieldsReadOnly">Acciones</th>
                    </tr>
                </thead>
                <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                    @for (data of productionProcessTableRows; track idx; let idx = $index) {
                    <tr cdkDrag>
                        <td>{{ idx + 1 + 'º' }}</td>
                        <td>{{ data.name }}</td>
                        <td [hidden]="fieldsReadOnly">
                            <div class="buttonsDnDTableContainer">
                                <img src="assets/icons/copy-icon.svg" class="h-6 w-6" alt="Duplicar"
                                    (click)="cloneProductionOrderProcess(idx)" title="Duplicar">
                                <nz-divider nzType="vertical"></nz-divider>
                                <img src="assets/icons/xmark-icon.svg" class="h-6 w-6" alt="Eliminar"
                                    (click)="removeProductionProcess(idx)" title="Eliminar">
                            </div>
                        </td>
                    </tr>
                    }
                </tbody>
            </nz-table>

            @if (!fieldsReadOnly) {
            <button
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit">
                Guardar
            </button>
            <nz-divider nzType="vertical"></nz-divider>
            <button
                class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button" (click)="cancelEditing()">
                Cancelar
            </button>
            }
        </form>
    </ng-container>
</nz-drawer>
