import { ProductionRouteProcessDto } from "./production-route-process.dto";

export class ProductionRouteDto {
    id!: number;
    name!: string;
    description?: string;
    productionProcesses?: ProductionRouteProcessDto[];
    productionProcessesIds?: number[];
    createdAt?: Date;
    updatedAt?: Date;
    createdBy?: number;
    updatedBy?: number;
}
