import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { DatePipe, NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { ɵNzTransitionPatchDirective } from 'ng-zorro-antd/core/transition-patch';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { NzDividerComponent } from 'ng-zorro-antd/divider';
import { NzDrawerComponent, NzDrawerContentDirective } from 'ng-zorro-antd/drawer';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NzInputDirective, NzInputGroupComponent, NzInputGroupWhitSuffixOrPrefixDirective } from 'ng-zorro-antd/input';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzPageHeaderComponent, NzPageHeaderContentDirective, NzPageHeaderExtraDirective } from 'ng-zorro-antd/page-header';
import { NzProgressComponent } from 'ng-zorro-antd/progress';
import { NzOptionComponent, NzSelectComponent } from 'ng-zorro-antd/select';
import { NzTableCellDirective, NzTableComponent, NzTableFixedRowComponent, NzTbodyComponent, NzTdAddOnComponent, NzThAddOnComponent, NzTheadComponent, NzThMeasureDirective, NzTrDirective, NzTrExpandDirective } from 'ng-zorro-antd/table';

interface Maintenance {
    id: string;
    state: STATE;
    machineryId: string;
    element: string
    chiefIntervention: string;
    date: Date;
}

enum STATE {
    Awaiting = 'En espera',
    Pending = 'Pendiente',
    InProgress = 'En progreso',
    Finished = 'Finalizado'
}

@Component({
    selector: 'app-maintenances-calendar',
    standalone: true,
    //TODO: remove useless imports
    imports: [
        NzPageHeaderComponent,
        NzPageHeaderExtraDirective,
        NzInputGroupComponent,
        ɵNzTransitionPatchDirective,
        NzInputGroupWhitSuffixOrPrefixDirective,
        NzInputDirective,
        ReactiveFormsModule,
        FormsModule,
        NzIconDirective,
        NzPageHeaderContentDirective,
        NzTableComponent,
        NzTheadComponent,
        NzTrDirective,
        NzTableCellDirective,
        NzThMeasureDirective,
        NzThAddOnComponent,
        NzTbodyComponent,
        NzTdAddOnComponent,
        NgClass,
        NzProgressComponent,
        NzDividerComponent,
        NzTrExpandDirective,
        NzTableFixedRowComponent,
        NzDrawerComponent,
        NzDrawerContentDirective,
        NzSelectComponent,
        NzOptionComponent,
        CdkDropList,
        CdkDrag,
        NzDatePickerComponent,
        DatePipe,
        NzMessageModule,
        NzCalendarModule,
        NzBadgeModule
    ],
    templateUrl: './maintenances-calendar.component.html',
    styleUrl: './maintenances-calendar.component.scss'
})
export class MaintenancesCalendarComponent {
    selectedDate = new Date();
    listDataMap = {
        eight: [
            {
                id: '1',
                state: STATE.Awaiting,
                machineryId: 'M001',
                element: 'Elemento A',
                chiefIntervention: 'Jefe 1',
                date: new Date(),
                type: 'error',
                content: `M001 - OP002 - 08:00`
            },
            {
                id: '2',
                state: STATE.Pending,
                machineryId: 'M002',
                element: 'Elemento B',
                chiefIntervention: 'Jefe 2',
                date: new Date(),
                type: 'success',
                content: `M002 - OP001, OP003 - 09:00`
            }
        ],
        eleven: [
            {
                id: '5',
                state: STATE.Awaiting,
                machineryId: 'T001',
                element: 'Elemento E',
                chiefIntervention: 'Jefe 5',
                date: new Date(),
                type: 'error',
                content: `T001 - OP003 - 06:00`
            },
            {
                id: '6',
                state: STATE.Pending,
                machineryId: 'M003',
                element: 'Elemento F',
                chiefIntervention: 'Jefe 6',
                date: new Date(),
                type: 'success',
                content: `M003 - OP003 - 18:00`
            },
            {
                id: '7',
                state: STATE.InProgress,
                machineryId: 'M004',
                element: 'Elemento G',
                chiefIntervention: 'Jefe 7',
                date: new Date(),
                type: 'error',
                content: `M004 - OP002 - 17:30`
            },
            {
                id: '8',
                state: STATE.Finished,
                machineryId: 'S001',
                element: 'Elemento H',
                chiefIntervention: 'Jefe 8',
                date: new Date(),
                type: 'success',
                content: `S001 - OP001, OP002 - 10:00`
            }
        ]
    };

    getRandomTime(): string {
        const hours = Math.floor(Math.random() * 24);
        const minutes = Math.floor(Math.random() * 60);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }

    getListData(date: Date): Array<{ type: string, content: string }> {
        const day = date.getDate();
        switch (day) {
            case 8:
                return this.listDataMap.eight;
            case 11:
                return this.listDataMap.eleven;
            default:
                return [];
        }
    }

    onDateSelect(date: Date): void {
        console.log('Selected date:', date);
    }

    getMonthData(date: Date): number | null {
        if (date.getMonth() === 8) {
            return 1394;
        }
        return null;
    }
}
