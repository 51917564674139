import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { MeasurementUnitDto } from 'src/app/shared/dto/measurement-unit.dto';
import { MeasurementUnitResponseDto } from 'src/app/shared/dto/pagination/measurement-unit-response.dto';
import { BaseService } from 'src/app/shared/services/base.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';

@Injectable({
    providedIn: 'root',
})
export class MeasurementunitsService extends BaseService {
    private measurementUnitsSubject = new BehaviorSubject<MeasurementUnitDto[]>([]);
    public measurementUnits$ = this.measurementUnitsSubject.asObservable();

    private readonly socketService = inject(SocketService);

    constructor(httpClient: HttpClient) {
        super(httpClient, 'measurement-units');
    }

    async setupWebSocket(): Promise<void> {
        try {
            this.measurementUnitsSubject.next((await this.findAll()).measurementUnits);
            this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.MEASUREMENT_UNITS_ROOM));
            this.socketService.getNotifications(Messages.MEASUREMENT_UNITS_CHANGED).subscribe(async () => {
                const response = await this.findAll();
                this.measurementUnitsSubject.next(response.measurementUnits);
            });
        } catch (e: any) {
            throw new Error(`Error al establecer conexión con el socket de Unidades de Medida. Error: ${e.message}`);
        }
    }

    disconnectWebSocket(): void {
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.MEASUREMENT_UNITS_ROOM));
    }

    async findAll(): Promise<MeasurementUnitResponseDto> {
        try {
            const request = this.httpClient.get<MeasurementUnitResponseDto>(`${this.url}/findAll`);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se ha encontrado ninguna máquina. Error: ${e.message}`);
        }
    }
}
