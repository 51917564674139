<div class="bg-white p-4 rounded-lg shadow-lg">
    <!-- Filter Line -->
    <div class="flex justify-between items-center">
        <!-- Filter Buttons -->
        <div class="flex gap-2">
            <button class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Todas</button>
            <button class="bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300">En espera</button>
            <button class="bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300">En progreso</button>
            <button class="bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300">Pausadas</button>
            <button class="bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300">Finalizadas</button>
        </div>

        <!-- Selector Input -->
        <div class="flex gap-2">
            <div>
                <label for="selectFloor">Plantas</label>
                <select class="max-w-40" label="asdf" name="selectFloor">
                    @for(floor of floors; track idx; let idx = $index){
                        <option value="{{floor}}">{{floor}}</option>
                    }
                </select>
            </div>
            <div>
                <label for="selectZone">Zonas</label>
                <select class="max-w-40" label="asdf" name="selectZone">
                    @for(zone of zones; track idx; let idx = $index){
                        <option value="{{zone}}">{{zone}}</option>
                    }
                </select>
            </div>
            <div>
                <label for="selectLine">Líneas</label>
                <select class="max-w-40" name="selectLine">
                    @for(line of lines; track idx; let idx = $index){
                        <option value="{{line}}">{{line}}</option>
                    }
                </select>
            </div>
        </div>
        <!-- Search Input -->
        <div class="relative">
            <label for="search">Buscador</label>
            <input type="text" class="border rounded-lg pl-10 pr-4 py-2 bg-gray-100" name="search" placeholder="Nombre, Artículo...">
        </div>
    </div>
</div>
<h1 class="w-full text-center text-2xl font-bold py-4 mt-5">Planta 1 - Zona 1 - Línea 1</h1>

<div class="flex flex-wrap gap-4 p-4">
    @for(machine of machinesDashboard; track machine.machineName){
        <div class="w-80 h-50 p-0 bg-white shadow-lg rounded-lg">
            <!-- Header -->
            <div class="bg-gray-800 rounded-t-lg py-2 flex justify-between items-center">
                <h3 class="text-sm text-gray-200 font-bold uppercase flex-grow text-center">{{machine.machineName}}</h3>
                <button class="mr-2 bg-red-500 text-white px-1 py-1 rounded-full hover:bg-red-700" title="Crear solicitud de mantenimiento">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01M12 5a7 7 0 110 14 7 7 0 010-14z" />
                    </svg>
                </button>
            </div>


            <!-- Mid Section -->
            <div class="flex justify-between items-center py-1 px-2 bg-blue-100">
                <div>
                    <div class="flex space-x-2">
                        <button class="bg-devoltecGreen hover:bg-green-500 text-white font-bold  rounded">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 m-1" fill="none" viewBox="0 0 24 24" stroke="currentColor"
								stroke-width="1">
								<path stroke-linecap="round" stroke-linejoin="round"
									d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
							</svg>
                        </button>
                        <button class="bg-yellow-400 hover:bg-yellow-700 text-white font-bold  rounded">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 m-1" fill="none" viewBox="0 0 24 24" stroke="currentColor"
								stroke-width="2">
								<path stroke-linecap="round" stroke-linejoin="round" d="M10 9 v6 m4-6 v6 z" />
							</svg>
                        </button>
                        <button class="bg-red-500 hover:bg-red-700 text-white font-bold rounded">
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 m-1" fill="none"
								viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
								<path stroke-linecap="miter" stroke-linejoin="miter"
									d="M9 10a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4z" />
							</svg>
                        </button>
                    </div>
                </div>
                <div>
                    <p class="text-xs font-bold">Progreso</p>
                    <p class="text-3xl font-bold text-gray-800">{{machine.processProgress}}</p>
                </div>
                <div class="flex space-x-2">
                    <div class="flex flex-col items-center">
                        <div class="bg-yellow-400 rounded-full w-5 h-5 flex items-center justify-center text-white font-bold">{{machine.pauses}}</div>
                        <div class="text-xs">Pausas</div>
                      </div>
                      <div class="flex flex-col items-center">
                        <div class="bg-red-500 rounded-full w-5 h-5 flex items-center justify-center text-white font-bold">{{machine.scraps}}</div>
                        <div class="text-xs">Taras</div>
                      </div>
                </div>
            </div>

            <!-- Stats Section -->
            <div class="py-4 px-4">
                <div class="flex justify-between text-sm gap-3">
                    <div class="flex gap-1">
                        <p class="font-bold">Artículo:</p>
                        <p class="text-gray-500">{{machine.articleName}}</p>
                    </div>
                    <div>
                        <span [ngClass]="{
                            'bg-status-in-progress': machine.status === productionOrderStatesEnum.IN_PROGRESS,
                            'bg-status-paused': machine.status === productionOrderStatesEnum.FINISHED,
                            'bg-status-finished': machine.status === productionOrderStatesEnum.PAUSED,
                            'bg-status-awaiting': machine.status === productionOrderStatesEnum.AWAITING,
                          }" class="text-white text-xs px-2 py-1 rounded-lg">
                            {{machine.status}}
                          </span>
                    </div>
                </div>
                <div class="flex justify-between text-sm gap-4 mt-2">
                    <div class="flex gap-1">
                        <p class="font-bold">Cant. Prod.:</p>
                        <p class="text-gray-500">{{machine.quantityProduced}} {{machine.measurementUnit}}</p>
                    </div>
                    <div class="flex gap-1">
                        <span class="font-bold">Activo: </span>
                        <p class="text-gray-500">{{machine.uptime}} min</p>
                    </div>
                </div>
                <div class="flex justify-between text-sm mt-2 gap-3">
                    <div class="flex gap-1">
                        <p class="font-bold">Cant. T. Real:</p>
                        <p class="text-gray-500">{{machine.quantityRealTime}} {{machine.measurementUnit}}/min</p>
                    </div>
                    <div class="flex gap-1">
                        <span class="font-bold">Estim.:</span>
                        <p class="text-gray-500">{{machine.estimationTime}} min</p>
                    </div>
                </div>
            </div>
        </div>
    }
</div>
