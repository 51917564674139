import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTableModule } from 'ng-zorro-antd/table';
import { Subject, takeUntil } from 'rxjs';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { ProductionProcessDto } from 'src/app/shared/dto/production-process.dto';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { ProductionProcessesService } from '../../services/production-processes.service';

@Component({
    selector: 'app-production-processes',
    standalone: true,
    imports: [
        NzPageHeaderModule,
        NzSpaceModule,
        NzTableModule,
        NzDividerModule,
        NzDrawerModule,
        NzDatePickerModule,
        FormsModule,
        NzMessageModule,
        NzCheckboxModule
    ],
    templateUrl: './production-processes.component.html',
    styleUrl: './production-processes.component.scss',
})

export class ProductionProcessesComponent implements OnInit, OnDestroy {
    readonly productionProcessService = inject(ProductionProcessesService);
    readonly message = inject(NzMessageService);
    readonly userLoggedService = inject(UserLoggedService);

    productionProcesses: ProductionProcessDto[] = [];
    drawerVisible: boolean = false;
    fieldsReadOnly: boolean = true;
    drawerTitle: string = 'Placeholder';
    selectedProcess: ProductionProcessDto = new ProductionProcessDto();
    isNewProductionProcess: boolean = false;
    currentUser: UserDto = new UserDto();
    loadingTable: boolean = true;
    isWithQualityControl: boolean = false;

    private unsubscribe$ = new Subject<void>();


    async ngOnInit() {
        try {
            this.currentUser = this.userLoggedService.userLogged;

            await this.productionProcessService.setupWebSocket();
            this.productionProcessService.productionProcessess$
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: (processes) => {
                        this.productionProcesses = processes;
                        this.selectedProcess = this.productionProcesses.find(pp => pp.id === this.selectedProcess.id) ?? new ProductionProcessDto();
                        this.isWithQualityControl = this.selectedProcess.qualityControl ?? false;
                    },
                    error: (e) => {
                        this.message.create('Error cargando procesos de producción', e.message);
                    }
                });

            this.loadingTable = false;
        } catch (e: any) {
            this.message.create('error', e.message);
        }
    }

    ngOnDestroy(): void {
        this.productionProcessService.disconnectWebSocket();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    openCreateProcessDrawer() {
        this.fieldsReadOnly = false;
        this.selectedProcess = new ProductionProcessDto();
        this.drawerTitle = 'Creando nuevo proceso de producción';
        this.isNewProductionProcess = true;
        this.setData();
        this.openDrawer();
    }

    seeProcess(id: number) {
        this.fieldsReadOnly = true;
        this.selectedProcess = this.productionProcesses.find(
            (p) => p.id === id,
        ) as ProductionProcessDto;
        this.drawerTitle = 'Viendo proceso ' + this.selectedProcess?.name;

        this.setData();
        this.openDrawer();
    }

    editProcess(id: number) {
        this.fieldsReadOnly = false;
        this.isNewProductionProcess = false;
        this.selectedProcess = this.productionProcesses.find(
            (p) => p.id === id,
        ) as ProductionProcessDto;
        this.drawerTitle = 'Editando ruta ' + this.selectedProcess?.name;
        this.setData();
        this.openDrawer();
    }

    setData() {
        this.isWithQualityControl = this.selectedProcess.qualityControl ?? false;
    }

    cancelEditing() {
        this.closeDrawer();
    }

    async deleteProcess(processId: number) {
        try {
            if (window.confirm('¿Estás seguro de que quieres eliminar este proceso de producción? Esta acción no se puede deshacer.')) {
                const deletedProcess: ProductionProcessDto = await this.productionProcessService.deleteProcess(processId);

                const index = this.productionProcesses.findIndex((p) => p.id === processId);

                if (index < 0) {
                    this.message.create('error', `No se ha podido eliminar el proceso ${deletedProcess.name} con id ${processId}, proceso de producción no encontrado`);

                    return;
                }

                this.productionProcesses.splice(index, 1);
                const data = [...this.productionProcesses];
                this.productionProcesses = data;

                this.message.create('success', 'Proceso de producción eliminado correctamente');
            }
        } catch (e: any) {
            this.message.create('error', `${e.message}`);
        }
    }

    openDrawer() {
        this.drawerVisible = true;
    }

    closeDrawer() {
        this.drawerVisible = false;
    }

    async saveEditing() {
        const processToBeProcessed: ProductionProcessDto = {
            id: this.selectedProcess.id,
            name: (<HTMLInputElement>document.getElementById('name')).value
                ? ((<HTMLInputElement>document.getElementById('name'))
                    .value as string)
                : '',
            qualityControl: this.isWithQualityControl,
            createdBy: this.isNewProductionProcess ? this.currentUser.id : this.selectedProcess.createdBy
        };

        if (!this.isFormValid(processToBeProcessed)) {
            return;
        }

        if (this.isNewProductionProcess) {
            const createdProcess: ProductionProcessDto = await this.productionProcessService.createProcess(processToBeProcessed);

            this.productionProcesses.push(createdProcess);
            const data = [...this.productionProcesses];
            this.productionProcesses = data;

            this.message.create('success', 'Proceso de producción creada correctamente');
        } else {
            const updatedProcess: ProductionProcessDto = await this.productionProcessService.updateProcess(processToBeProcessed);

            const index = this.productionProcesses.findIndex((r) => r.id === updatedProcess.id);

            if (index < 0) {
                this.message.create('error', `No se ha podido actualizar el proceso ${processToBeProcessed.name} con id ${processToBeProcessed.id}, proceso de producción no encontrado`);

                return;
            }

            this.productionProcesses[index] = updatedProcess;
            const data = [...this.productionProcesses];
            this.productionProcesses = data;

            this.message.create('success', 'Proceso de producción actualizado correctamente');
        }

        this.closeDrawer();
    }

    isFormValid(productionProcess: ProductionProcessDto): boolean {
        if (!productionProcess.name) {
            this.message.create('error', 'El nombre del proceso de producción es obligatorio');

            return false;
        }

        return true;
    }
}
