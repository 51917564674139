/**
 * This is the DTO for the MachineMeasurement entity
 */
export class MachineMeasurementDto {
    /**
     * Do not send this to the server
     */
    id?: number;

    /**
     * The name of the measurement unit
     */
    measurement!: string;
}
