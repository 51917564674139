import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { AssessDto } from 'src/app/shared/dto/assess.dto';
import { BaseService } from 'src/app/shared/services/base.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';
import { AssessResponseDto } from '../../shared/dto/pagination/assess-response.dto';

@Injectable({
    providedIn: 'root',
})
export class AssessService extends BaseService {
    private assessmentsSubject = new BehaviorSubject<AssessDto[]>([]);
    public assessments$ = this.assessmentsSubject.asObservable();

    private readonly socketService = inject(SocketService);

    constructor(httpClient: HttpClient) {
        super(httpClient, 'assess');
    }

    async setupWebSocket(): Promise<void> {
        try {
            this.assessmentsSubject.next((await this.findAll()).assessments);
            this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.ASSESS_ROOM));
            this.socketService.getNotifications(Messages.ASSESS_CHANGED).subscribe(async () => {
                const response = await this.findAll();
                this.assessmentsSubject.next(response.assessments);
            });
        } catch (e: any) {
            throw new Error(`Error al establecer conexión con el socket de Escandallos. Error: ${e.message}`);
        }
    }

    disconnectWebSocket(): void {
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.ASSESS_ROOM));
    }

    async findAll(): Promise<AssessResponseDto> {
        try {
            const request = this.httpClient.get<AssessResponseDto>(`${this.url}/findAll`);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se ha encontrado ningun escandallo. Error: ${e.message}`);
        }
    }
}
