<nz-drawer
    [nzBodyStyle]="{ overflow: 'auto' }"
    [nzMaskClosable]="false"
    [nzVisible]="visible()"
    [nzTitle]="title()"
    [nzExtra]="extra"
    [nzWidth]="'500px'"
    (nzOnClose)="onCancel()"
>
    <div *nzDrawerContent class="h-full justify-items-center w-full">
        @if (loading) {
            <app-loader></app-loader>
        } @else {
            <app-screens-transfer
                [screen]="screen()"
                [saveEvent]="saveSubject"
            ></app-screens-transfer>
        }
    </div>
    <ng-template #extra>
        <div class="p-2 float-right">
            <button nz-button nzType="primary" (click)="save()">Guardar</button>
        </div>
    </ng-template>
</nz-drawer>
