import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { sha256 } from 'js-sha256';
import { lastValueFrom, Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/private/services/configuration.service';
import { Auth, AuthMethod } from 'src/app/shared/models/auth.model';
import { BaseService } from 'src/app/shared/services/base.service';
import { TOKEN } from '../../core/constants/storage';

@Injectable({
    providedIn: 'root',
})
export class AuthService extends BaseService {
    constructor(httpClient: HttpClient,) {
        super(httpClient, 'auth/');
    }

    readonly router = inject(Router);
    readonly configurationService = inject(ConfigurationService);

    public getIp(): Observable<any> {
        return this.httpClient.get<any>(`${this.url}ip`);
    }

    public login(method: AuthMethod, username: string, optional?: { screenId?: number; password?: string }): Promise<any> {
        let body = null;

        let password: string | undefined = undefined;

        if (optional && optional.password) {
            password = sha256(optional.password);
        }

        switch (method) {
            case AuthMethod.USER:
            case AuthMethod.EMAIL:
                body = new Auth({ method, username, password: password });
                break;

            case AuthMethod.RFID:
                if (optional && optional.screenId) {
                    body = new Auth({
                        method,
                        username,
                        screenId: optional.screenId,
                    });
                } else {
                    body = new Auth({ method, username, password: username });
                }
                break;

            default:
                break;
        }

        return lastValueFrom(this.httpClient.post<any>(`${this.url}sendCode`, body));
    }

    public sendCode(method: AuthMethod, username: string, secCode: number, optional?: { screenId?: number, password?: string }): Promise<any> {
        let password: string | undefined = undefined;

        if (optional && optional.password) {
            password = sha256(optional.password);
        }

        const body = new Auth({ method, username, password: password, code: secCode });

        return lastValueFrom(this.httpClient.post<any>(`${this.url}login`, body));
    }

    public logout() {
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/auth/login']);
    }

    public get token(): string | null {
        return localStorage.getItem(TOKEN);
    }

    async registerLogout(loggedUserId: number) {
        if (loggedUserId >= 0) {
            const logoutReq = this.httpClient.post<any>(`${this.url}logout`, { userId: loggedUserId });
            await lastValueFrom(logoutReq);
        }
    }

    async registerUser(method: AuthMethod, username: string, optional?: { screenId?: number; password?: string }): Promise<boolean> {
        try {

            if(!username || username === '') {
                throw new Error('El nombre de usuario no puede estar vacío');
            }

            if(!optional || !optional.password || optional.password === '') {
                throw new Error('La contraseña no puede estar vacía');
            }

            const isValid = lastValueFrom(this.httpClient.post<boolean>(`${this.url}registerUser`, new Auth({ method, username, password: sha256(optional!.password!) })));
            
            return isValid;
        } catch (error: any) {
            throw new Error("Error registrando usuario", error);
        }
    }

    async registerUserByRfid(rfid: string): Promise<boolean> {
        try {

            if(!rfid || rfid === '') {
                throw new Error('El rfid no puede estar vacío');
            }

            const isValid = lastValueFrom(this.httpClient.post<boolean>(`${this.url}registerUserByRfid`, {rfid}));

            return isValid;
        } catch (error: any) {
            throw new Error("Error registrando usuario", error);
        }
    }
}
