import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ArticleFamilyDto } from 'src/app/shared/dto/article-family.dto';
import { ArticleFamilyResponseDto } from 'src/app/shared/dto/pagination/article-family-response.dto';

import { BaseService } from 'src/app/shared/services/base.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';

@Injectable({
    providedIn: 'root',
})
export class ArticleFamilyService extends BaseService {
    private articleFamiliesSubject = new BehaviorSubject<ArticleFamilyDto[]>([]);
    public articleFamilies$ = this.articleFamiliesSubject.asObservable();

    private readonly socketService = inject(SocketService);

    constructor(httpClient: HttpClient) {
        super(httpClient, 'article-family');
    }

    async setupWebSocket(): Promise<void> {
        try {
            this.articleFamiliesSubject.next((await this.findAll()).articleFamilies);
            this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.ARTICLE_FAMILIES_ROOM));
            this.socketService.getNotifications(Messages.ARTICLE_FAMILIES_CHANGED).subscribe(async () => {
                const response = await this.findAll();
                this.articleFamiliesSubject.next(response.articleFamilies);
            });
        } catch (e: any) {
            throw new Error(`Error al establecer conexión con el socket de Familias de Artículos. Error: ${e.message}`);
        }
    }

    discontectWebSocket(): void {
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.ARTICLE_FAMILIES_ROOM));
    }

    async findAll(): Promise<ArticleFamilyResponseDto> {
        try {
            const request = this.httpClient.get<ArticleFamilyResponseDto>(`${this.url}/findAll`);

            return (await lastValueFrom(request));
        } catch (e: any) {
            throw new Error(`No se ha encontrado ninguna familia de articulo. Error: ${e.message}`);
        }
    }

    async createFamily(family: ArticleFamilyDto): Promise<ArticleFamilyDto> {
        try {
            const request = this.httpClient.post<ArticleFamilyDto>(`${this.url}/create`, family);

            const createdFamily: ArticleFamilyDto = await lastValueFrom(request);

            if (!createdFamily) {
                throw null;
            }

            return createdFamily;

        } catch (e: any) {
            throw new Error(`No se ha podido crear la familia de articulo. Error: ${e.error.message}`);
        }
    }

    async updateFamily(family: ArticleFamilyDto): Promise<ArticleFamilyDto> {
        try {
            const request = this.httpClient.patch<ArticleFamilyDto>(`${this.url}/update`, family);

            const updatedFamily: ArticleFamilyDto = await lastValueFrom(request);

            if (!updatedFamily) {
                throw null;
            }

            return updatedFamily;

        } catch (e: any) {
            throw new Error(`No se ha podido actualizar la familia de articulo. Error: ${e.message}`);
        }
    }

    async deleteFamily(familyId: number): Promise<ArticleFamilyDto> {
        try {
            const request = this.httpClient.delete<ArticleFamilyDto>(`${this.url}/${familyId}`);

            const deletedFamily: ArticleFamilyDto = await lastValueFrom(request);

            if (!deletedFamily) {
                throw null;
            }

            return deletedFamily;
        } catch (e: any) {
            throw new Error(`No se ha podido eliminiar la familia con id ${familyId}. Error: ${e.message}`);
        }
    }
}
