import { IMAGE_CONFIG } from "@angular/common";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ApplicationConfig, enableProdMode, importProvidersFrom, LOCALE_ID } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { provideRouter } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NZ_I18N, es_ES } from "ng-zorro-antd/i18n";
import { NzModalModule } from "ng-zorro-antd/modal";
import { environment } from "src/environments/environment";
import { routes } from "./app.routes";
import { PendingChangesGuard } from "./core/guards/pending-changes.guard";
import { ErrorInterceptor } from "./core/interceptors/error/error.interceptor";
import { TokenInterceptor } from "./core/interceptors/token/token.interceptor";
import { PermissionService } from "./core/services/permission.service";
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { provideAnimations } from "@angular/platform-browser/animations";

if (environment.production) {
    enableProdMode();
}

const socketConfig: SocketIoConfig = {
    url: environment.production
        ? `wss://${environment.socketUrl}`
        : environment.socketUrl,
    options: {
        transports: ['websocket'],
        upgrade: false,
        path: environment.socketPath,
    },
};

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(BrowserModule, FormsModule, NzModalModule, SocketIoModule.forRoot(socketConfig), ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production || environment.preproduction,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        })),
        provideRouter(routes),
        PendingChangesGuard,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: NZ_I18N, useValue: es_ES },
        { provide: LOCALE_ID, useValue: 'es-ES' },
        PermissionService,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        {
            provide: IMAGE_CONFIG,
            useValue: {
              disableImageSizeWarning: true, // Devoltec logo is so big that it triggers a warning
            }
          },
    ]
}