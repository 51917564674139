<div class="ml-4 mr-4">
    <nz-table #batchTable [nzData]="packagings" [nzLoading]="loading">
        <thead>
            <tr>
                <th></th>
                <th>Nombre</th>
                <th>Cantidad por formato</th>
                @if (canSetProducedQuantity) {
                    <th>Cantidad</th>
                    <th>Total</th>
                }
            </tr>
        </thead>
        <tbody>
            @for (data of batchTable.data; track data.id; let i = $index) {
                <tr>
                    <td>
                        <label
                        nz-checkbox
                        [disabled]="justWatching"
                        [ngModel]="selectedPackagings.indexOf(data.id || -1) > -1"
                        (nzCheckedChange)="setAsSelected(data.id || -1)"
                        ></label>
                    </td>
                    <td>{{ data.name }}</td>
                    <td>{{ data.quantityPerPackaging }} {{ data.measurementUnit?.abbreviation }}</td>
                    @if (canSetProducedQuantity) {
                        <td>
                            <input type="number" pattern="\d*" class="w-6"
                            [(ngModel)]="data.quantity"
                            [disabled]="justWatching"
                            [name]="'quantity'"/>
                        </td>
                        <td>{{ data.quantity ? data.quantityPerPackaging * data.quantity : 0 }} {{ data.measurementUnit?.abbreviation }}</td>
                    }
                </tr>
            }
        </tbody>
    </nz-table>
    <div *nzModalFooter>
        @if (canSetProducedQuantity || !justWatching) {
            <button
                class="mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                (click)="save()">
                Guardar
            </button>
        }
        <button
            class="mt-2 mr-6 text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            (click)="handleCancel()">
            Cancelar
        </button>
    </div>
</div>