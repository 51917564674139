import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/shared/services/base.service';

@Injectable({
    providedIn: 'root',
})
export class ProductionSubprocessService extends BaseService {
    constructor(httpClient: HttpClient) {
        super(httpClient, 'productionsubprocesses');
    }
}
