<div class="relative text-left flex items-center">
    <p class="text-[13px] leading-5 text-gray-100 font-medium pr-2">
        {{ userLogged?.name }}
        {{ userLogged?.surname }}
    </p>
    <div hlMenu>
        <span class="rounded-md shadow-sm">
            <button
                hlMenuButton
                class="inline-flex justify-center w-full font-medium leading-5 my-2 text-gray-700 transition duration-150 ease-in-out bg-white rounded-full hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
            >
                <div class="flex items-center">
                    <div class="relative w-8 h-8 overflow-hidden rounded-full">
                        <svg
                            class="absolute w-10 h-10 text-gray-400 -left-1"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </div>
                </div>
            </button>
        </span>
        <div
            *hlMenuItems
            @toggleAnimation
            class="absolute right-0 w-auto min-w-52 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none z-50"
        >
            <div class="py-1">
                <button
                    *hlMenuItem="let item"
                    (click)="goToChangePassword()"
                    [class]="
                        item.active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700'
                    "
                    class="flex justify-between w-full px-4 py-1 text-[12px] leading-5 text-left font-medium"
                >
                    Cambiar contraseña
                </button>
            </div>

            <div class="py-1">
                <button
                    *hlMenuItem="let item"
                    (click)="goToConfiguration()"
                    [class]="
                        item.active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700'
                    "
                    class="flex justify-between w-full px-4 py-1 text-[12px] leading-5 text-left font-medium"
                >
                    Configuración
                </button>
            </div>

            <div class="py-1">
                <button
                    *hlMenuItem="let item"
                    (click)="logout()"
                    [class]="
                        item.active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700'
                    "
                    class="flex justify-between w-full px-4 py-1 text-[12px] leading-5 text-left font-medium"
                >
                    Cerrar sesión
                </button>
            </div>
            <div class="px-4 py-2">
                <p
                    class="text-[12px] leading-5 text-gray-500 truncate font-light"
                >
                    {{ bottomText }}
                </p>
            </div>
        </div>
    </div>
</div>
