<div class="flex justify-center items-center mt-10">
    <div class="w-full max-w-md">
        <form
            class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
            [formGroup]="changePasswordFormGroup"
            (ngSubmit)="changePassword()"
        >
            <div class="grid grid-cols-1 divide-y">
                <div class="mb-4">
                    <label
                        class="block text-gray-700 text-sm font-bold mb-2"
                        for="oldPassword"
                    >
                        Contraseña actual
                    </label>
                    <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="oldPassword"
                        type="password"
                        placeholder="Contraseña actual"
                        formControlName="password"
                    />
                </div>
                <div class="py-1">
                    <div class="mb-3">
                        <label
                            class="block text-gray-700 text-sm font-bold mb-2"
                            for="newPassword"
                        >
                            Nueva contraseña
                        </label>
                        <input
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="newPassword"
                            type="password"
                            placeholder="Nueva contraseña"
                            formControlName="newPassword"
                        />
                    </div>
                    <div class="mb-6">
                        <label
                            class="block text-gray-700 text-sm font-bold mb-2"
                            for="repeatNewPassword"
                        >
                            Confirmar nueva contraseña
                        </label>
                        <input
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="repeatNewPassword"
                            type="password"
                            placeholder="Confirmar nueva contraseña"
                            formControlName="confirmPassword"
                        />
                    </div>
                </div>
            </div>
            <button
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
            >
                Cambiar contraseña
            </button>
        </form>
    </div>
</div>
