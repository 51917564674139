import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { StatusResponseDto } from 'src/app/shared/dto/pagination/status-response.dto';
import { StatusDto } from 'src/app/shared/dto/status.dto';
import { BaseService } from 'src/app/shared/services/base.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';

@Injectable({
    providedIn: 'root'
})
export class StatusesService extends BaseService {
    private statusesSubject = new BehaviorSubject<StatusDto[]>([]);
    public statuses$ = this.statusesSubject.asObservable();

    private readonly socketService = inject(SocketService);

    constructor(httpClient: HttpClient) {
        super(httpClient, 'statuses');
    }

    async setupWebSocket(): Promise<void> {
        try {
            this.statusesSubject.next((await this.findAll()).statuses);
            this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.STATUSES_ROOM));
            this.socketService.getNotifications(Messages.STATUSES_CHANGED).subscribe(async () => {
                const response = await this.findAll();
                this.statusesSubject.next(response.statuses);
            });
        } catch (e: any) {
            throw new Error(`Error al establecer conexión con el socket. Error: ${e.message}`);
        }
    }

    disconnectWebSocket(): void {
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.STATUSES_ROOM));
    }

    async findAll(): Promise<StatusResponseDto> {
        try {
            const request = this.httpClient.get<StatusResponseDto>(`${this.url}/findAll`);

            const response: StatusResponseDto = await lastValueFrom(request);

            if (!response) {
                throw new Error('No se ha encontrado ningún estado');
            }

            return response;
        } catch (e: any) {
            throw new Error(`No se ha encontrado ningun estado. Error: ${e.message}`);
        }
    }

    async createStatus(status: StatusDto): Promise<StatusDto> {
        try {
            if (status === null || status === undefined) {
                throw new Error('El estado no es válido');
            }

            const request = this.httpClient.post<StatusDto>(`${this.url}/create`, status);

            const createdStatus: StatusDto = await lastValueFrom(request);

            return createdStatus;
        } catch (e: any) {
            throw new Error(`No se ha podido crear el estado. Error: ${e.error.message}`);
        }
    }

    async updateStatus(status: StatusDto): Promise<StatusDto> {
        try {
            if (status === null || status === undefined) {
                throw new Error('El estado no es válido');
            }

            const request = this.httpClient.put<StatusDto>(`${this.url}/update`, status);

            const updatedStatus: StatusDto = await lastValueFrom(request);

            return updatedStatus;
        } catch (e: any) {
            throw new Error(`No se ha podido actualizar el estado. Error: ${e.message}`);
        }
    }

    async deleteStatus(statusId: number): Promise<StatusDto> {
        try {
            if (statusId <= 0) {
                throw new Error('El id del estado ha de ser mayor de 0');
            }

            const request = this.httpClient.delete<StatusDto>(`${this.url}/${statusId}`);

            const response: StatusDto = await lastValueFrom(request);

            if (!response) {
                throw null;
            }

            return response;
        } catch (e: any) {
            throw new Error(`No se ha podido eliminar el estado ${statusId ?? ''}. Error: ${e.message}`);
        }
    }
}
