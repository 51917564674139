import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { RfidFormComponent } from '../../components/rfid-form/rfid-form.component';
import { LoginFormComponent } from '../../components/login-form/login-form.component';

import { NzRowDirective, NzColDirective } from 'ng-zorro-antd/grid';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [
    NzRowDirective,
    NzColDirective,
    LoginFormComponent,
    RfidFormComponent
],
})
export class LoginComponent implements OnInit {
    showRFID: boolean = true;
    environment: any;

    ngOnInit(): void {
        this.environment = _.cloneDeep(environment);
    }

    changeRFID() {
        this.showRFID = !this.showRFID;
    }
}
