import {
    Component,
    HostListener,
    Input,
    ViewEncapsulation,
    inject,
} from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef, NzModalTitleDirective, NzModalFooterDirective } from 'ng-zorro-antd/modal';
import { IStandardModalData } from '../../interface/standard-modal-data';
import { NzWaveDirective } from 'ng-zorro-antd/core/wave';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { ɵNzTransitionPatchDirective } from 'ng-zorro-antd/core/transition-patch';


@Component({
    selector: 'app-standard-modal',
    templateUrl: './standard-modal.component.html',
    styleUrls: ['./standard-modal.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
    standalone: true,
    imports: [
    NzModalTitleDirective,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    NzModalFooterDirective,
    NzButtonComponent,
    NzWaveDirective
],
})
export class StandardModalComponent {
    /*MODAL INPUTS*/
    close = false;

    readonly nzModalData: IStandardModalData = inject(NZ_MODAL_DATA);

    constructor(
        private modal: NzModalRef,
        public dialogRef: NzModalRef<StandardModalComponent>,
    ) {
        this.close = this.nzModalData.close;
        console.log(this.nzModalData);
    }

    @HostListener('window:keyup.esc') onKeyUp() {
        this.cancel();
    }

    @HostListener('window:beforeunload', ['$event']) unloadHandler(
        event: Event,
    ) {
        event.returnValue = false;
    }

    accept() {
        this.close = true;
        this.modal.close(true);
    }

    cancel() {
        this.close = false;
        this.modal.close(false);
    }
}
