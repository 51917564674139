import { PermissionDto } from './permission.dto';

export class ProfileDto {
    id!: number;
    name!: string;
    createdAt!: Date;
    updatedAt!: Date;
    createdBy!: number;
    updatedBy!: number;
    permissions!: PermissionDto[];
}
