import {
    Component,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { FormService, ItemFormStructure } from '../../services/form.service';
import _ from 'lodash';
import { extractValueObjectDictionary } from '../../utils/utils';
import { NzSelectModeType, NzSelectComponent, NzOptionComponent } from 'ng-zorro-antd/select';

import { NzCheckboxComponent } from 'ng-zorro-antd/checkbox';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { NzInputNumberComponent } from 'ng-zorro-antd/input-number';
import { NzInputDirective } from 'ng-zorro-antd/input';
import { NzRowDirective, NzColDirective } from 'ng-zorro-antd/grid';
import { NzFormDirective, NzFormItemComponent, NzFormLabelComponent, NzFormControlComponent } from 'ng-zorro-antd/form';
import { input } from '@angular/core';

@Component({
    selector: 'app-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrl: './dynamic-form.component.scss',
    standalone: true,
    imports: [
    ReactiveFormsModule,
    NzFormDirective,
    NzRowDirective,
    NzFormItemComponent,
    NzColDirective,
    NzFormLabelComponent,
    NzFormControlComponent,
    NzInputDirective,
    NzInputNumberComponent,
    NzDatePickerComponent,
    NzCheckboxComponent,
    NzSelectComponent,
    NzOptionComponent
],
})
export class DynamicFormComponent implements OnInit, OnChanges {
    listOfSelectedObjectValueDictionary: Record<string, any[]> = {};
    dynamicForm!: FormGroup;
    hasChange: boolean = false;

    formStructure = input<ItemFormStructure[]>([]);
    listValue = input<Record<string, any[]>>({});
    nameSelected = input<string>('');

    constructor(
        private formBuilder: FormBuilder,
        public formService: FormService,
    ) {}

    ngOnInit() {
        const formGroup: { [key: string]: any } = {};
        this.formStructure().forEach((control) => {
            const controlValidators: any[] = [];

            if (control.validations) {
                control.validations.forEach((validation: any) => {
                    if (validation.validator === 'required') {
                        controlValidators.push(Validators.required);
                    }
                    if (validation.validator === 'email') {
                        controlValidators.push(Validators.email);
                    }
                    if (validation.validator === 'ip') {
                        controlValidators.push(
                            Validators.pattern(
                                '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)',
                            ),
                        );
                    }
                });
            }
            formGroup[control.name] = [
                { value: control.value, disabled: control.disabled },
                controlValidators,
            ];

            if (
                control.type === 'list' &&
                control.value !== undefined &&
                control.value !== ''
            ) {
                this.listOfSelectedObjectValueDictionary[control.name] =
                    extractValueObjectDictionary(
                        formGroup[control.name][0].value,
                    );
            }
        });
        this.dynamicForm = this.formBuilder.group(formGroup);
        console.log(this.dynamicForm);
        this.onCreateGroupFormValueChange();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['formStructure']) {
            this.ngOnInit();
        }
    }

    onSubmit() {
        if (this.dynamicForm.valid) {
            return this.dynamicForm.getRawValue();
        } else {
            Object.values(this.dynamicForm.controls).forEach((control) => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({ onlySelf: true });
                }
            });
        }
    }

    getErrorMessage(control: any) {
        const formControl = this.dynamicForm.get(control.name);

        for (const validation of control.validations) {
            if (formControl?.hasError(validation.validator)) {
                return validation.message;
            }
        }

        return '';
    }

    onCreateGroupFormValueChange() {
        const initialValue = this.dynamicForm.value;
        this.dynamicForm.valueChanges.subscribe((value) => {
            this.hasChange = Object.keys(initialValue).some(
                (key) => this.dynamicForm.get(key) != initialValue[key],
            );
        });
    }

    hasChangeObject() {
        return this.hasChange;
    }
    filterItemsOfType(type: any) {
        const listOfOption = this.listValue()[type].filter(
            (option: { name: string }) => {
                return option.name !== this.nameSelected();
            },
        );
        return listOfOption;
    }

    compareFn(o1: any, o2: any): boolean {
        return o1 && o2 ? o1.id === o2.id : false;
    }

    compareTypeSelect(type: string): NzSelectModeType {
        if (type === 'multiple') {
            return 'multiple';
        } else {
            return 'default';
        }
    }

    selectedTypeSelect(control: any): any {
        if (control.subtype === 'multiple') {
            return this.listOfSelectedObjectValueDictionary[control.name];
        } else {
            if (control.value.length > 0) {
                return this.listOfSelectedObjectValueDictionary[
                    control.name
                ][0];
            } else {
                return null;
            }
        }
    }
}
