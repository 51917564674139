import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { PackagingDto } from 'src/app/shared/dto/packaging.dto';
import { PackagingResponseDto } from 'src/app/shared/dto/pagination/packagings-response.dto';
import { BaseService } from 'src/app/shared/services/base.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';

@Injectable({
    providedIn: 'root',
})
export class PackagingsService extends BaseService {
    private packagingsSubject = new BehaviorSubject<PackagingDto[]>([]);
    public packagings$ = this.packagingsSubject.asObservable();

    private readonly socketService = inject(SocketService);

    constructor(httpClient: HttpClient) {
        super(httpClient, 'packagings');
    }

    async setupWebSocket(): Promise<void> {
        try {
            this.packagingsSubject.next((await this.findAll()).packagings);
            this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.PACKAGINGS_ROOM));
            this.socketService.getNotifications(Messages.PACKAGINGS_CHANGED).subscribe(async () => {
                const response = await this.findAll();
                this.packagingsSubject.next(response.packagings);
            });
        } catch (e: any) {
            throw new Error(`Error al establecer conexión con el socket de formatos. Error: ${e.message}`);
        }
    }

    disconnectWebSocket(): void {
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.PACKAGINGS_ROOM));
    }

    async findAll(): Promise<PackagingResponseDto> {
        try {
            const request = this.httpClient.get<PackagingResponseDto>(`${this.url}/findAll`);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se ha encontrado ningun formato. Error: ${e.message}`);
        }
    }

    async getPackagingsByArticleId(articleId: number): Promise<PackagingDto[]> {
        try {
            if (!articleId || articleId <= 0) {
                throw new Error('El id del articulo ha de ser mayor de 0');
            }

            const request = this.httpClient.get<PackagingDto[]>(`${this.url}/findByArticleId/${articleId}`);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se han podido obtener los lotes para el artículo ${articleId}. Error: ${e.message}`);
        }
    }

    async setPackagingAsConsumed(packagingElementId: number): Promise<boolean> {
        try {
            if (!packagingElementId || packagingElementId <= 0) {
                throw new Error('El id del formato ha de ser mayor de 0');
            }

            //TODO: update sending object, not just id
            // const request = this.httpClient.put<boolean>(`${this.url}/setPackagingAsConsumed/${packagingElementId}`, {});
            this.httpClient.put<boolean>(`${this.url}/setPackagingAsConsumed/${packagingElementId}`, {});

            // return await lastValueFrom(request);
            return true;
        } catch (e: any){
            throw new Error(`No se ha podido consumir el formato con id ${packagingElementId}. Error: ${e.message}`);
        }
    }
}
