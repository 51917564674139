import { Component, inject, input, model, OnDestroy, OnInit } from '@angular/core';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzTransferComponent, TransferDirection, TransferItem } from 'ng-zorro-antd/transfer';
import { lastValueFrom, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { MachineService } from 'src/app/private/services/machine.service';
import { ScreenService } from 'src/app/public/services/screen.service';
import { MachineDto } from 'src/app/shared/dto/machine.dto';
import { ScreenDto } from 'src/app/shared/dto/screen.dto';
import { DirectionsEnum } from 'src/app/shared/enums/directions.enum';

@Component({
    selector: 'app-screens-transfer',
    templateUrl: './screens-transfer.component.html',
    styleUrl: './screens-transfer.component.scss',
    standalone: true,
    imports: [
        NzTransferComponent,
        NzMessageModule,
    ],
})
export class ScreensTransferComponent implements OnInit, OnDestroy {
    readonly machinesService = inject(MachineService);
    readonly screenService = inject(ScreenService);
    readonly toast = inject(NzMessageService);

    saveEvent = input.required<Observable<void>>();
    screen = model.required<ScreenDto>();
    machinesTransferItems: TransferItem[] = [];
    machines: MachineDto[] = [];
    disabled = false;
    saveSubs!: Subscription;

    private readonly unsubscribe$ = new Subject<void>();

    constructor() { }

    async ngOnInit(): Promise<void> {
        if (this.screen() === undefined) {
            return;
        }

        this.screenService.screens$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: async (screens) => {
                    this.screen.set(screens.find((screen) => screen.id === this.screen()['id'])!);
                    this.machines = (await this.machinesService.findAll()).machines;
                    this.initComponent();
                }
            });

        this.machinesService.setupWebSocket();
        this.machinesService.machines$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: async (machines) => {
                    this.machines = machines;
                    this.screen.set(await lastValueFrom(this.screenService.getById(this.screen()['id'])));

                    this.initComponent();
                }
            });

        this.saveSubs = this.saveEvent().subscribe(() => this.save());
    }

    ngOnDestroy() {
        if (this.saveSubs !== undefined) {
            this.saveSubs.unsubscribe();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.machinesService.disconnectWebSocket();
    }

    /** left: disponibles, right: actuales */
    initComponent(): void {
        const transferItems: TransferItem[] = [];
        const availableMachines = this.machines.filter((machine) => {
            return !this.screen().machines?.some((m) => m.machineId === machine.id);
        });

        availableMachines.forEach((availableMachine) => {
            transferItems.push({
                key: availableMachine.id,
                title: availableMachine.name,
                description: availableMachine.ip,
                direction: DirectionsEnum.LEFT as TransferDirection,
            });
        });

        this.screen().machines?.forEach((assignedMachine) => {
            transferItems.push({
                key: assignedMachine.machineId,
                title: assignedMachine.machines.name,
                description: assignedMachine.machines.ip,
                direction: DirectionsEnum.RIGHT as TransferDirection,
            });
        });

        this.machinesTransferItems = transferItems;
    }

    async getScreenById(screenId: number) {
        const screen$ = (await lastValueFrom(this.screenService.getById(screenId)));
        return screen$;
    }

    async save() {
        try {
            const assignMachines: { screenId: number, machineId: number }[] = [];

            this.machinesTransferItems.forEach((element) => {
                if (element.direction === DirectionsEnum.RIGHT as TransferDirection) {
                    assignMachines.push({
                        screenId: this.screen().id,
                        machineId: element['key'],
                    });
                }
            });

            await this.screenService.assignMachines('create', this.screen().id, assignMachines);

            this.toast.create('success', 'Máquinas asignadas correctamente');
        } catch (e: any) {
            this.toast.create('error', `No se ha podido guardar correctamente. Error: ${e.message}`);
        }
    }
}
