import { MachineDto } from "./machine.dto";
import { UserDto } from "./user.dto";

export class ScreenDto {
    id!: number;
    name!: string;
    ip!: string;
    // machines?: MachineDto[];
    machines?: {
        screenId: number,
        machineId: number,
        machines: MachineDto
    }[];
    users?: UserDto[];
}