import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ScrapReasonDto } from 'src/app/shared/dto/scrap-reason.dto';

import { BaseService } from 'src/app/shared/services/base.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';

@Injectable({
    providedIn: 'root',
})
export class ScrapReasonService extends BaseService {
    private scrapReasonsSubject = new BehaviorSubject<ScrapReasonDto[]>([]);
    public scrapReasons$ = this.scrapReasonsSubject.asObservable();

    private readonly socketService = inject(SocketService);

    constructor(httpClient: HttpClient) {
        super(httpClient, 'scrap-reasons');
    }

    async setupWebSocket(): Promise<void> {
        try {
            this.scrapReasonsSubject.next((await this.findAll()));
            this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.SCRAP_REASONS_ROOM));
            this.socketService.getNotifications(Messages.SCRAP_REASONS_CHANGED).subscribe(async () => {
                const response = await this.findAll();
                this.scrapReasonsSubject.next(response);
            });
        } catch (e: any) {
            throw new Error(`Error al establecer conexión con el socket de Razones de Tara. Error: ${e.message}`);
        }
    }

    disconnectWebSocket(): void {
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.SCRAP_REASONS_ROOM));
    }

    async findAll(): Promise<ScrapReasonDto[]> {
        try {
            const scrapReasonsObservable = this.httpClient.get<ScrapReasonDto[]>(this.url + '/findAll');

            return await lastValueFrom(scrapReasonsObservable);
        } catch (e: any) {
            throw new Error(`No se han podido obtener las razones de tara. Error: ${e.message}`);
        }
    }
}
