<!-- This example requires Tailwind CSS v2.0+ -->
<div class="h-screen w-screen flex justify-center items-center">
    <div>
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-20 h-20 m-auto text-red-500"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.288 15.038a5.25 5.25 0 017.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 011.06 0z"
                />
            </svg>

            <div class="lg:text-center">
                <h2 class="text-lg font-semibold text-red-5 00">Error</h2>
                <p
                    class="mt-3 text-2xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl"
                >
                    Sin conexión a Internet
                </p>
                <p class="mt-2 max-w-2xl text-lg text-gray-500 lg:mx-auto">
                    Porfavor, compruebe la conexión a Internet del equipo o
                    contacte con el equipo de soporte a través de un ticket.
                </p>
                <button
                    (click)="refresh()"
                    class="mt-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-4 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 w-full"
                >
                    ACTUALIZAR
                </button>
            </div>
        </div>
    </div>
</div>
