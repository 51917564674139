import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { TOKEN, USER } from '../constants/storage';
import { isEmpty } from 'src/app/shared/utils/utils';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { ProfileDto } from 'src/app/shared/dto/profile.dto';
import { AuthService } from 'src/app/public/services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class UserLoggedService {
    public userSubject: Subject<UserDto> = new Subject();

    private _user!: UserDto;

    constructor(private authService: AuthService) {}

    public get userLogged(): UserDto {
        if (this._user) {
            return this._user;
        }

        const userStr = localStorage.getItem(USER);

        if (userStr) {
            this._user = JSON.parse(userStr) as UserDto;

            return this._user;
        }

        this.authService.logout();

        return new UserDto();
    }

    public set userLogged(user: UserDto) {
        this._user = user;
        this.userSubject.next(user);
    }

    public setUserLogged(user: UserDto, accessToken: string): void {
        this.userLogged = user;

        if (user !== null && accessToken !== '') {
            localStorage.setItem(TOKEN, accessToken);
            localStorage.setItem(USER, JSON.stringify(user));
        }
    }

    public hasRole(roleNames: string[]): boolean {
        if (isEmpty(this.userLogged) && isEmpty(this.userLogged!.profile)) {
            return false;
        }

        const profile: ProfileDto = this.userLogged!.profile as ProfileDto;

        return roleNames.includes(profile.name);
    }
}
