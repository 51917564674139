import { Component, input, Input, model } from '@angular/core';
import * as _ from 'lodash';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { Subject } from 'rxjs';
import { ɵNzTransitionPatchDirective } from 'ng-zorro-antd/core/transition-patch';
import { NzWaveDirective } from 'ng-zorro-antd/core/wave';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { ScreensTransferComponent } from '../../components/screens/screens-transfer/screens-transfer.component';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { NzDrawerComponent, NzDrawerContentDirective } from 'ng-zorro-antd/drawer';
import { ScreenDto } from 'src/app/shared/dto/screen.dto';

@Component({
    selector: 'app-assign-machines',
    templateUrl: './assign-machines.component.html',
    styleUrl: './assign-machines.component.scss',
    standalone: true,
    imports: [
        NzDrawerComponent,
        NzDrawerContentDirective,
        LoaderComponent,
        ScreensTransferComponent,
        NzButtonComponent,
        NzWaveDirective,
        ɵNzTransitionPatchDirective,
        NzMessageModule,
    ],
})
export class AssignMachinesComponent {
    visible = model(false);
    title = input('Asignación de máquinas');
    screen = input.required<ScreenDto>();
    saveSubject: Subject<void> = new Subject<void>();

    loading: boolean = false;

    save(): void {
        this.saveSubject.next();
    }

    onCancel() {
        this.visible.set(false);
    }
}
