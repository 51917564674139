import { Component } from '@angular/core';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzTableComponent, NzTheadComponent, NzTrDirective, NzTableCellDirective, NzTbodyComponent } from 'ng-zorro-antd/table';
import { NzPageHeaderComponent, NzPageHeaderExtraDirective } from 'ng-zorro-antd/page-header';

interface PreventiveMaintenance {
    id: string;
    interventionLevel: string;
    machineId: string;
    operators: Operator[];
    element: string;
    frequency: string;
    description: string;
    duration: number;
    economicCost: number;
}

interface Operator {
    id: string;
    name: string;
    surname: string;

}

@Component({
    selector: 'app-preventive-maintenances',
    standalone: true,
    imports: [
        NzPageHeaderComponent,
        NzTableComponent,
        NzTheadComponent,
        NzTrDirective,
        NzTableCellDirective,
        NzTbodyComponent,
        NzMessageModule,
        NzPageHeaderExtraDirective
    ],
    templateUrl: './preventive-maintenances.component.html',
    styleUrl: './preventive-maintenances.component.scss'
})
export class PreventiveMaintenancesComponent {
    preventiveMaintenances: PreventiveMaintenance[] = [
        {
            id: "MP001",
            interventionLevel: "1",
            machineId: "M001",
            operators: [
                { id: "O001", name: "Juan", surname: "Pérez" },
                { id: "O002", name: "María", surname: "Gómez" }
            ],
            element: "Máquina de extrusión",
            frequency: "2 años",
            description: "Supervisión y test de todos los componentes de la máquina de extrusión. Sustitución cuando sea necesario.",
            duration: 12,
            economicCost: 0
        },
        {
            id: "MP002",
            interventionLevel: "2",
            machineId: "M002",
            operators: [
                { id: "O003", name: "Carlos", surname: "López" },
                { id: "O004", name: "Ana", surname: "Martínez" }
            ],
            element: "Máquina de trenzado",
            frequency: "1 año",
            description: "Limpieza a fondo de la máquina de trenzado. Sustitución de los elementos principales que forman parte de la dotación.",
            duration: 4,
            economicCost: 500
        },
        {
            id: "MP003",
            interventionLevel: "2",
            machineId: "T001",
            operators: [
                { id: "O005", name: "Luis", surname: "García" },
            ],
            element: "Toro de transporte",
            frequency: "6 meses",
            description: "Revisión completa del toro de transporte, incluyendo frenos, ruedas y sistema hidráulico.",
            duration: 8,
            economicCost: 300
        },
        {
            id: "MP004",
            interventionLevel: "3",
            machineId: "M003",
            operators: [
                { id: "O006", name: "Miguel", surname: "Fernández" },
            ],
            element: "Sistema de bobinado",
            frequency: "1 mes",
            description: "Limpieza a fondo de todo el sistema de bobinado, incluyendo rodillos y guías.",
            duration: 6,
            economicCost: 500
        },
        {
            id: "MP005",
            interventionLevel: "4",
            machineId: "M004",
            operators: [
                { id: "O007", name: "Jorge", surname: "Hernández" },
                { id: "O008", name: "Sara", surname: "Díaz" }
            ],
            element: "Limpieza de estructura",
            frequency: "3 semanas",
            description: "Limpieza general de la estructura de la maquinaria, comprobar su correcto funcionamiento.",
            duration: 1,
            economicCost: 50
        },
        {
            id: "MP006",
            interventionLevel: "1",
            machineId: "S001",
            operators: [
                { id: "O009", name: "Pedro", surname: "Ruiz" },
            ],
            element: "Estanterías",
            frequency: "1 año",
            description: "Inspección y certificación ITA de las estanterías para asegurar su seguridad y cumplimiento de normativas.",
            duration: 2,
            economicCost: 200
        }
    ];

    filteredPreventiveMaintenances: PreventiveMaintenance[] = this.preventiveMaintenances;

    filters = {
        interventionLevel: '',
        machineId: '',
        operatorId: '',
        element: '',
        frequency: '',
        duration: null as number | null,
        economicCost: null as number | null
    };

    applyFilters() {
        this.filteredPreventiveMaintenances = this.preventiveMaintenances.filter(maintenance => {
            return (
                (this.filters.interventionLevel ? maintenance.interventionLevel === this.filters.interventionLevel : true) &&
                (this.filters.machineId ? maintenance.machineId === this.filters.machineId : true) &&
                (this.filters.operatorId ? maintenance.operators.some(op => op.id === this.filters.operatorId) : true) &&
                (this.filters.element ? maintenance.element === this.filters.element : true) &&
                (this.filters.frequency ? maintenance.frequency === this.filters.frequency : true) &&
                (this.filters.duration !== null ? maintenance.duration === this.filters.duration : true) &&
                (this.filters.economicCost !== null ? maintenance.economicCost === this.filters.economicCost : true)
            );
        });
    }

}

