@if (pauses.length > 0) {
  <div class="w-full h-full p-0 m-0">
    <nz-table
      #basicTable
      [nzData]="pauses"
      class="m-0 p-0"
      [nzLoading]="isLoading"
      nzTableLayout="auto"
      [nzBordered]="true"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzScroll]="{ y: '350px' }"
      >
      <thead>
        <tr>
          <th nzWidth="250px">Motivo</th>
          <th>Inicio</th>
          <th>Operario Inicio Pausa</th>
          <th>Fin</th>
          <th>Operario Reanudación</th>
          <th nzWidth="15%">Duración</th>
        </tr>
      </thead>
      <tbody>
        @for (data of basicTable.data; track data) {
          <tr>
          <td>{{ data.observations }}</td>
          <td class="text-right">
            {{
            data.startDate
            | date
            : (datesAreOnSameDay(data.startDate!)
            ? "HH:mm:ss"
            : "dd/MM/YYYY HH:mm:ss a (O)")
            }}
          </td>
          <td>{{ data.pausedBy ? data.pausedBy.name : "-" }}</td>
          <td class="text-right">
            {{
            data.endDate === null
            ? "-"
            : (data.endDate
            | date
            : (datesAreOnSameDay(data.endDate!)
            ? "HH:mm:ss"
            : "dd/MM/YYYY HH:mm:ss a (O)"))
            }}
          </td>
          <td>{{ data.pausedBy ? data.pausedBy.name : "-" }}</td>
          <td class="text-right">
            {{
            data.endDate === null
            ? "-"
            : milisecondsToFormatTime(
            data.startDate,
            data.endDate
            )
            }}
          </td>
        </tr>
      }
    </tbody>
  </nz-table>
</div>
}
@if (pauses.length === 0) {
  <div class="w-full h-full p-0 m-0">
    <nz-table
      #basicTable
      [nzData]="pauses"
      class="m-0 p-0"
      [nzLoading]="isLoading"
      nzTableLayout="auto"
      [nzBordered]="true"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      >
      <thead>
        <tr>
          <th nzWidth="250px">Motivo</th>
          <th>Inicio</th>
          <th>Fin</th>
          <th nzWidth="150px">Duración</th>
          <th>Operario</th>
        </tr>
      </thead>
      <tbody>
        @for (data of basicTable.data; track data) {
          <tr>
            <td>{{ data.observations }}</td>
            <td class="text-right">
              {{ data.startDate | date: "dd/MM/YYYY HH:mm:ss" }}
            </td>
            <td class="text-right">
              {{
              data.endDate === null
              ? "-"
              : (data.endDate | date: "dd/MM/YYYY HH:mm:ss")
              }}
            </td>
            <td class="text-right">
              {{
              data.endDate === null
              ? "-"
              : milisecondsToFormatTime(
              data.startDate,
              data.endDate
              )
              }}
            </td>
            <td>{{ data.pausedBy?.name }}</td>
          </tr>
        }
      </tbody>
    </nz-table>
  </div>
}
<div *nzModalFooter>
  <button
    type="submit"
    (click)="handleOk()"
    class="mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
    >
    Aceptar
  </button>
</div>
