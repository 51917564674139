import { Component, EventEmitter, Inject, inject, Output } from '@angular/core';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from 'src/app/public/services/auth.service';

@Component({
    selector: 'app-modal-rfid',
    standalone: true,
    imports: [NzButtonComponent],
    templateUrl: './modal-rfid.component.html',
    styleUrl: './modal-rfid.component.scss'
})
export class ModalRfidComponent {
    @Output() emitRfid = new EventEmitter();

    isValidUser: boolean = false;
    rfid: string = '';
    loginFunction: any;

    private readonly authService = inject(AuthService);
    private readonly toast = inject(NzMessageService);
    private readonly modalService = inject(NzModalService);

    constructor(
        @Inject(NZ_MODAL_DATA) public data: any,
    ) {
        this.rfid = data.data.rfid;
    }

    async registerUserSupport(source: string): Promise<void> {
        if(source === 'login') {
            this.emitRfid.emit(this.rfid);
        }else if (source === 'register') {
            try {
                this.isValidUser = await this.authService.registerUserByRfid(this.rfid);

                if(this.isValidUser) {
                    this.toast.success('Operario registrado en esta pantalla');
                } else if (!this.isValidUser){
                    this.toast.error('Usuario y/o contraseña incorrectos');
                }

            }catch (error) {
                this.toast.error('Error al registrar el operario');
            }
        }

        this.modalService.closeAll();
    }
}
