<div class="ml-4 mr-4">
    <h1 class="pt-3 text-base">
    </h1>
    <div class="">
        @for (material of rawMaterials; track subidx; let subidx = $index) {
            <div class="flex gap-4">
                <div class="w-1/2">
                    <label for="articleSelector">Material a utilizar</label>
                    <nz-select
                    nzShowSearch
                    nzPlaceHolder="Selecciona un artículo o lote"
                    [(ngModel)]="selectedItems[subidx]"
                    (click)="removeRawMaterialsFromArticlesAndBatches()"
                    (ngModelChange)="onSelectionChange($event, subidx)"
                    class="searchSelectors "
                    >
                    @for (articleOrBatch of articlesAndBatches; track subidx; let subidx = $index) {
                        <nz-option
                            [nzLabel]="articleOrBatch.name
                                ?
                                    articleOrBatch.name + ' | ' + articleOrBatch.quantity
                                :
                                    articleOrBatch.batchNumber + ' | ' + articleOrBatch.article.name + ' | ' + articleOrBatch.quantity"
                            [nzValue]="articleOrBatch"
                        ></nz-option>

                    }
                    </nz-select>
                </div>
                <div class="w-1/2">
                    <label for="quantity">Cantidad</label>
                    <input
                        type="number"
                        pattern="\d*"
                        class="w-6"
                        [(ngModel)]="rawMaterials[subidx].quantity"
                        [name]="'quantity'"
                    />
                </div>
            </div>
        }
    </div>
    <button
            (click)="addRawMaterial()"
            class="mt-2 mr-6 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800"
        >
            Añadir materia prima
    </button>
    <div *nzModalFooter>
        <button
            class="mx-2 text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            (click)="handleCancel()"
        >
            Cancelar
        </button>
         <button
            class="ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            (click)="saveRawMaterials()"
        >
            Guardar materias primas
        </button>
    </div>
</div>
