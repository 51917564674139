<div class="p-10 h-full">
    <nz-page-header class="text-[16px] font-semibold p-0 m-0" nzTitle="Mantenimientos">

    </nz-page-header>

    <div class="flex justify-between items-center mt-4">
        <!-- Filter Inputs -->
        <div class="flex gap-2">
            <div class="flex flex-col">
                <label for="inputInterventionLevel">Nº</label>
                <input type="text" class="max-w-40 border rounded-lg pl-2 pr-2 py-1 bg-gray-100" name="inputInterventionLevel" placeholder="...">
            </div>
            <div class="flex flex-col">
                <label for="inputMachine">Máquina</label>
                <input type="text" class="max-w-40 border rounded-lg pl-2 pr-2 py-1 bg-gray-100" name="inputMachine" placeholder="...">
            </div>
            <div class="flex flex-col">
                <label for="inputStatus">Estado</label>
                <input type="text" class="max-w-40 border rounded-lg pl-2 pr-2 py-1 bg-gray-100" name="inputStatus" placeholder="...">
            </div>
            <div class="flex flex-col">
                <label for="inputElement">Elemento</label>
                <input type="text" class="max-w-40 border rounded-lg pl-2 pr-2 py-1 bg-gray-100" name="inputElement" placeholder="...">
            </div>
            <div class="flex flex-col">
                <label for="inputInterventionChief">Jefe de Intervención</label>
                <input type="number" class="max-w-40 border rounded-lg pl-2 pr-2 py-1 bg-gray-100" name="inputInterventionChief" placeholder="..">
            </div>
            <div class="flex flex-col">
                <label for="inputOperator">Operario</label>
                <input type="text" class="max-w-40 border rounded-lg pl-2 pr-2 py-1 bg-gray-100" name="inputOperator" placeholder="...">
            </div>
            <div class="flex flex-col">
                <label for="inputDuration">Duración</label>
                <input type="number" class="max-w-40 border rounded-lg pl-2 pr-2 py-1 bg-gray-100" name="inputDuration" placeholder="... (h)">
            </div>
            <div class="flex flex-col">
                <label for="inputDate">Fecha</label>
                <input type="date" class="max-w-40 border rounded-lg pl-2 pr-2 py-1 bg-gray-100" name="inputDate" placeholder="...">
            </div>
        </div>
    </div>
    <div #tableContainer class="mt-[15px] h-[calc(100%_-_100px)] max-h-[calc(100%_-_100px)]">
        <nz-table #nestedTable nzTableLayout="auto" nzSize="small" nzTableLayout="auto"
            [nzData]="maintenances" [nzSimple]="true"
            [nzBordered]="true"
            [nzFrontPagination]="false" nzShowSizeChanger nzBordered
        >
            <thead>
                <tr>
                    <th id="maintenanceId">Nº</th>
                    <th id="machineId">Maquinaria</th>
                    <th id="status">Estado</th>
                    <th id="element">Elemento</th>
                    <th id="interventionChief">Jefe de intervención</th>
                    <th id="operators">Operarios</th>
                    <th id="description">Descripción</th>
                    <th id="duration">Duración</th>
                    <th id="date">Fecha</th>
                </tr>
            </thead>
            <tbody>
                @for(maintenance of nestedTable.data; track maintenance.id){
                    <tr>
                        <td>{{ maintenance.id }}</td>
                        <td>{{ maintenance.machineId }}</td>
                        <td>{{ maintenance.status}}</td>
                        <td>{{ maintenance.element }}</td>
                        <td>{{ maintenance.interventionChief }}</td>
                        <td>
                            @for(operator of maintenance.operators; track operator.id){
                                {{ operator.id}}<br>
                            }
                        </td>
                        <td>{{ maintenance.description }}</td>
                        <td>{{ maintenance.duration }} h</td>
                        <td>{{ maintenance.date }}</td>
                    </tr>
                }
            </tbody>
        </nz-table>
    </div>
</div>
