@if (plainRawMaterials.length > 0) {
  <div class="w-full h-full p-0 m-0">
    <nz-table
      #basicTable
      [nzData]="plainRawMaterials"
      class="m-0 p-0"
      [nzLoading]="isLoading"
      nzTableLayout="auto"
      [nzBordered]="true"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzScroll]="{ y: '350px' }"
      >
      <thead>
        <tr>
            <th id="article">Artículo</th>
            <th id="batch">Lote</th>
            <th id="packaging">Formato</th>
            <th id="actualQuantity">Cantidad actual</th>
            <th id="quantity">Cantidad</th>
            <th id="totalQuantity">Cantidad a consumir</th>
            <th id="action">Acciones</th>
        </tr>
      </thead>
      <tbody>
        @for (rawMaterial of basicTable.data; track rawMaterial; let idx = $index) {
          <tr>
            <td>
                <nz-select class="w-full" [(ngModel)]="rawMaterial.article" (ngModelChange)="onArticleChanged($event, idx)" [compareWith]="compareById">
                    @for(article of articles; track article){
                        <nz-option [nzValue]="article" [nzLabel]="article.name"></nz-option>
                    }
                </nz-select>
            </td>
            <td>
                <nz-select class="w-full" [(ngModel)]="rawMaterial.articleBatch" [compareWith]="compareByBatchNumber"
                [disabled]="!plainRawMaterials[idx].article?.useBatches" (ngModelChange)="onBatchChanged(rawMaterial.articleBatch, idx)">
                    @for(batch of batchesOfArticlesInRawMaterials.get(idx); track batch.batchNumber){
                        <nz-option [nzValue]="batch" [nzLabel]="batch.batchNumber || ''"></nz-option>
                    }
                </nz-select>
            </td>

            <td>
                <nz-select nzMode="multiple"
                nzPlaceHolder="Selecciona formatos" [(ngModel)]="rawMaterial.packaging"
                name="packagingSelector" class="w-full"
                [nzDisabled]="rawMaterial.packaging === undefined" [compareWith]="compareByPackagingElementId">
                @for(packaging of packagingsOfBatchesSelected.get(idx); track packaging.packagingElementId){
                    <nz-option [nzValue]="packaging" [nzLabel]="packaging.packagingElementId + ' ' + packaging.name"></nz-option>
                }
                </nz-select>
            </td>
           <!--  <td>
                <nz-select class="w-full" [(ngModel)]="rawMaterial.packaging" [compareWith]="compareByPackagingElementId"
                [disabled]="rawMaterial.packaging === undefined ">
                    @for(packaging of packagingsOfBatchesSelected.get(idx); track packaging.packagingElementId){
                            <nz-option [nzValue]="packaging" [nzLabel]="packaging.packagingElementId + ' ' + packaging.name"></nz-option>
                    }
                </nz-select>
            </td> -->
            <td>
                {{rawMaterial.articleBatch?.quantity || rawMaterial.article?.quantity}} {{rawMaterial.measurementUnit}}
            </td>
            <td>
                <div class="flex items-center">
                    <input nz-input [(ngModel)]="rawMaterial.quantity" [disabled]="plainRawMaterials[idx].packaging !== undefined" type="number" class="mr-2"/>
                    <span>{{ rawMaterial.measurementUnit }}</span>
                </div>
            </td>
            <td>
                {{quantityToProduce * rawMaterial.quantity}} {{rawMaterial.measurementUnit}}
            </td>
            <td style="width: 1px;">
                <div class="buttonsDnDTableContainer">
                    <img src="assets/icons/xmark-icon.svg" class="h-6 w-6" alt="Eliminar"
                        (click)="deleteRawMaterial(idx)" title="Eliminar">
                </div>
            </td>
          </tr>
        }
      </tbody>
    </nz-table>
  </div>
}

<div *nzModalFooter>
    <div class="flex justify-between">
        <button (click)="handleAddRawMaterial()"
            class="mt-2 mr-6 text-white bg-devoltecCyan hover:bg-cyan-600 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
            Añadir materia prima
        </button>
        <div>
            <button
            (click)="handleCancel()"
            class="mt-2 mr-6 text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
            Cancelar
        </button>

        <button
        type="submit"
        (click)="handleOk()"
        class="mt-2 text-white bg-devoltecBlue hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
        Aceptar
    </button>
</div>
</div>

</div>
