<!-- This example requires Tailwind CSS v2.0+ -->
<div class="mt-36 flex justify-center items-center">
  <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    <div class="lg:text-center">
      <p
        class="mt-4 text-2xl font-bold leading-8 tracking-tight text-red-500 sm:text-4xl"
        >
        Error durante la carga de datos
      </p>
      <p class="mt-4 max-w-2xl text-lg text-gray-500 lg:mx-auto">
        Porfavor, compruebe la conexión a Internet del equipo o contacte
        con el equipo de soporte a través de un ticket.
      </p>
      @if (!environment.preproduction || !environment.development || !environment.local) {
        <div
          class="flex p-4 mt-6 text-sm text-left text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
          role="alert"
          >
          <svg
            aria-hidden="true"
            class="flex-shrink-0 inline w-5 h-5 mr-3"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span class="sr-only">Danger</span>
          <div>
            <span class="font-medium">Error message: </span>
            <p class="text-red-700 ml-2">
              {{ error! }}
            </p>
          </div>
        </div>
      }
      <button
        (click)="goBack()"
        type="button"
        class="mt-12 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-7 py-3 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
        Volver atrás
      </button>
    </div>
  </div>
</div>
