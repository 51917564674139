import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import _ from "lodash";
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageModule, NzMessageService } from "ng-zorro-antd/message";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpaceModule } from "ng-zorro-antd/space";
import { NzTableModule } from "ng-zorro-antd/table";
import { Subject, takeUntil } from "rxjs";
import { UserLoggedService } from "src/app/core/services/userLogged.service";
import { ArticleFamilyDto } from "src/app/shared/dto/article-family.dto";
import { ArticleTypeDto } from "src/app/shared/dto/article-type.dto";
import { ArticleDto } from "src/app/shared/dto/article.dto";
import { MachineMeasurementsValueDto } from "src/app/shared/dto/machine-measurements-value.dto";
import { MachineMeasurementDto } from "src/app/shared/dto/machine-measurements.dto";
import { MachineDto } from "src/app/shared/dto/machine.dto";
import { MeasurementUnitDto } from "src/app/shared/dto/measurement-unit.dto";
import { UserDto } from "src/app/shared/dto/user.dto";
import { ArticleFamilyService } from "../../services/article-family.service";
import { ArticleTypeService } from "../../services/article-type.service";
import { ArticlesService } from "../../services/articles.service";
import { MachineMeasurementsValueService } from "../../services/machine-measurements-value.service";
import { MachineMeasurementsService } from "../../services/machine-measurements.service";
import { MachineService } from "../../services/machine.service";
import { MeasurementunitsService } from "../../services/measurementunits.service";

@Component({
    selector: 'app-articles',
    templateUrl: './articles.component.html',
    styleUrl: './articles.component.scss',
    standalone: true,
    imports: [
        NzPageHeaderModule,
        NzSpaceModule,
        NzTableModule,
        NzDividerModule,
        NzDrawerModule,
        NzDatePickerModule,
        FormsModule,
        NzMessageModule,
        NzDropDownModule,
        NzSelectModule,
        NzInputModule,
        FormsModule,
        NzCheckboxModule,
    ],
})
export class ArticlesComponent implements OnInit, OnDestroy {

    readonly articlesService = inject(ArticlesService);
    readonly message = inject(NzMessageService);
    readonly userLoggedService = inject(UserLoggedService);
    readonly measurementUnitService = inject(MeasurementunitsService);
    readonly articleFamilyService = inject(ArticleFamilyService);
    readonly articleTypeService = inject(ArticleTypeService);
    readonly machineMeasurementsService = inject(MachineMeasurementsService);
    readonly machineMeasurementsValueService = inject(MachineMeasurementsValueService);
    readonly machinesService = inject(MachineService);

    articles: ArticleDto[] = [];
    drawerVisible = false;
    drawerTitle = 'Placeholder';
    fieldsReadOnly = false;
    articleSelected: ArticleDto = new ArticleDto();
    createdAt: Date = new Date();
    updatedAt: Date = new Date();
    isNewArticle = false;
    currentUser: UserDto = new UserDto();
    articleActive: boolean = false;
    articleUseBatches: boolean = false;
    loadingTable: boolean = true;

    measurementUnits: MeasurementUnitDto[] = [];
    measurementUnitSelected: MeasurementUnitDto = new MeasurementUnitDto();

    articleFamilyDto: ArticleFamilyDto[] = [];
    articleFamiliesSelected: ArticleFamilyDto[] = [];

    articleTypeDto: ArticleTypeDto[] = [];
    articleTypeSelected: ArticleTypeDto = new ArticleTypeDto();

    machineMeasurements: MachineMeasurementDto[] = [];
    machineMeasurementsSelected: MachineMeasurementDto = new MachineMeasurementDto();

    machineMeasurementsValues: MachineMeasurementsValueDto[] = [];
    machineMeasurementsValueSelected: MachineMeasurementsValueDto[] = [];
    machineMeasurementsValueAdding: MachineMeasurementsValueDto = new MachineMeasurementsValueDto();

    machines: MachineDto[] = [];
    machineSelected: MachineDto = new MachineDto();

    private unsubscribe$ = new Subject<void>();


    async ngOnInit() {
        this.currentUser = this.userLoggedService.userLogged;

        await this.articlesService.setupWebSocket();
        this.articlesService.articles$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (articles) => {
                    this.articles = articles;
                    this.articleSelected = this.articles.find(article => article.id === this.articleSelected.id) || new ArticleDto();
                    this.measurementUnitSelected = this.measurementUnits.find((m) => m.id === this.articleSelected.measurementUnitId) || new MeasurementUnitDto();
                    this.articleTypeSelected = this.articleTypeDto.find((t) => t.id === this.articleSelected.articleTypeId) || new ArticleTypeDto();
                    //TODO: refactor this to allow realation many-to-many articles and machines
                    this.machineSelected = this.machines.find((m) => (this.articleSelected.machineMeasurementValues as MachineMeasurementsValueDto[])[0].machineId === m.id) || new MachineDto();
                    this.articleActive = this.articleSelected.active;
                    this.articleFamiliesSelected = this.articleFamilyDto.filter(family => this.articleSelected.families?.map(f => f.familyId).includes(family.id));
                    this.articleUseBatches = this.articleSelected.useBatches ?? false;
                },
                error: () => {
                    this.message.error('Error cargando artículos');
                }
            });

        await this.measurementUnitService.setupWebSocket();
        this.measurementUnitService.measurementUnits$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (measures) => {
                    this.measurementUnits = measures;
                    if (this.measurementUnits.length > 0) {
                        this.measurementUnitSelected = this.measurementUnits[0];
                    } else {
                        this.measurementUnitSelected = new MeasurementUnitDto();
                    }
                },
                error: () => {
                    this.message.error('Error cargando unidades de medida');
                }
            });


        await this.articleFamilyService.setupWebSocket();
        this.articleFamilyService.articleFamilies$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (families) => {
                    this.articleFamilyDto = families;
                },
                error: () => {
                    this.message.error('Error cargando familias de artículo');
                }
            });

        await this.articleTypeService.setupWebSocket();
        this.articleTypeService.articleTypes$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (types) => {
                    this.articleTypeDto = types;
                    if (this.articleTypeDto.length > 0) {
                        this.articleTypeSelected = this.articleTypeDto[0];
                    } else {
                        this.articleTypeSelected = new ArticleTypeDto();
                    }
                },
                error: () => {
                    this.message.error('Error cargando tipos de artículo');
                }
            });

        await this.machineMeasurementsService.setupWebSocket();
        this.machineMeasurementsService.machineMeasurements$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (machineMeasurements) => {
                    this.machineMeasurements = machineMeasurements;
                    if (this.machineMeasurements.length > 0) {
                        this.machineMeasurementsSelected = this.machineMeasurements[0];
                    } else {
                        this.machineMeasurementsSelected = new MachineMeasurementDto();
                    }
                },
                error: () => {
                    this.message.error('Error cargando medidas de máquina');
                }
            });

        await this.machineMeasurementsValueService.setupWebSocket();
        this.machineMeasurementsValueService.machineMeasurementsValues$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (machineMeasurementsValues) => {
                    this.machineMeasurementsValues = machineMeasurementsValues;
                },
                error: () => {
                    this.message.error('Error cargando valores de medidas de máquina');
                }
            });

        await this.machinesService.setupWebSocket();
        this.machinesService.machines$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (machines) => {
                    this.machines = machines;
                    if (this.machines.length > 0) {
                        this.machineSelected = this.machines[0];
                    } else {
                        this.machineSelected = new MachineDto();
                    }
                },
                error: () => {
                    this.message.error('Error cargando máquinas');
                }
            });

        this.loadingTable = false;
    }

    ngOnDestroy(): void {
        this.articlesService.disconnectWebSocket();
        this.measurementUnitService.disconnectWebSocket();
        this.articleFamilyService.discontectWebSocket();
        this.articleTypeService.disconnectWebSocket();
        this.machineMeasurementsService.disconnectWebSocket();
        this.machineMeasurementsValueService.disconnectWebSocket();
        this.machinesService.disconnectWebSocket();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    createArticle() {
        this.fieldsReadOnly = false;
        this.articleSelected = new ArticleDto();
        this.drawerTitle = 'Creando nuevo artículo';
        this.isNewArticle = true;
        this.articleFamiliesSelected = [];
        this.articleTypeSelected = this.articleTypeDto[0] || new ArticleTypeDto();
        this.machineMeasurementsSelected = this.machineMeasurements[0] || new MachineMeasurementDto();
        this.machineMeasurementsValueSelected = [];
        this.machineSelected = this.machines[0] || new MachineDto();
        this.openDrawer();
    }

    seeArticle(id: number) {
        this.fieldsReadOnly = true;
        this.articleSelected = this.articles.find(
            (a) => a.id === id,
        ) as ArticleDto;
        this.drawerTitle = 'Viendo artículo ' + this.articleSelected?.name;
        this.setData();
        this.openDrawer();
    }

    editArticle(id: number) {
        this.fieldsReadOnly = false;
        this.articleSelected = this.articles.find(
            (c) => c.id === id,
        ) as ArticleDto;
        this.drawerTitle = 'Editando artículo ' + this.articleSelected?.name;
        this.isNewArticle = false;
        this.setData();
        this.openDrawer();
    }

    deleteArticle(id: number) {
        if (
            window.confirm(
                '¿Estás seguro de que quieres eliminar este artículo? Esta acción no se puede deshacer.',
            )
        ) {
            this.articlesService.delete(id).subscribe({
                next: (article: ArticleDto) => {
                    try {
                        if (article == undefined) {
                            throw new Error('Article not found');
                        }
                        const index = this.articles.findIndex((a) => a.id === id);
                        if (index < 0) {
                            this.message.create('error', `No se ha podido eliminar el articulo ${article.name} con id ${id}, artículo no encontrado`);
                            throw new Error('Article not found');
                        }
                        this.articles.splice(index, 1);
                        const data = [...this.articles];
                        this.articles = data;
                        this.message.create('success', 'Artículo eliminado correctamente',);
                    } catch (error) {
                        console.error(error);
                        this.message.create('error', 'Error al eliminar el artículo');
                    }
                },
                error: (error) => {
                    console.error(error);
                    this.message.create('error', 'Error al eliminar el artículo, error de servidor');
                },
            });
        } else {
            console.log('Cancelando eliminación de artículo ' + id);
        }
    }

    setData() {
        this.articleActive = this.articleSelected.active;
        this.articleUseBatches = this.articleSelected.useBatches ? this.articleSelected.useBatches : false;
        this.measurementUnitSelected = this.measurementUnits.find((m) => m.id === this.articleSelected.measurementUnitId) as MeasurementUnitDto;
        this.articleTypeSelected = this.articleTypeDto.find((t) => t.id === this.articleSelected.articleTypeId) as ArticleTypeDto;
        this.machineMeasurementsSelected = this.machineMeasurements[0] || new MachineMeasurementDto();

        if (this.articleSelected.families) {
            const familiesIds: number[] = this.articleSelected.families.map((f) => f.familyId);
            this.articleFamiliesSelected = this.articleFamilyDto.filter((f) => familiesIds.includes(f.id));
        } else {
            this.articleFamiliesSelected = [];
        }

        if (this.articleSelected.machineMeasurementValues != null && this.articleSelected.machineMeasurementValues.length > 0) {
            this.machineSelected = this.machines.find((m) => (this.articleSelected.machineMeasurementValues as MachineMeasurementsValueDto[])[0].machineId === m.id) as MachineDto;
            this.machineMeasurementsValueSelected = this.articleSelected.machineMeasurementValues;

            for (const mmv of this.machineMeasurementsValueSelected) {
                mmv.formattedValue = mmv.value + ' ' + this.machineMeasurements.find((m) => m.id === mmv.measurementId)?.measurement;
            }
        }
    }

    openDrawer() {
        this.drawerVisible = true;
    }

    closeDrawer() {
        this.drawerVisible = false;
    }

    saveEditing() {
        const customArticleDto: ArticleDto = {
            id: this.articleSelected.id,
            name: (<HTMLInputElement>document.getElementById('name'))?.value ?? '',
            ean: (<HTMLInputElement>document.getElementById('ean'))?.value ?? '',
            alias: (<HTMLInputElement>document.getElementById('alias'))?.value ?? '',
            externalReference: (<HTMLInputElement>document.getElementById('externalReference'))?.value ?? '',
            quantity: (<HTMLInputElement>document.getElementById('quantity'))?.value
                ? +((<HTMLInputElement>document.getElementById('quantity'))?.value)
                : 0,
            active: this.articleActive ? true : false,
            useBatches: this.articleUseBatches ? true : false,
            batchControl: this.articleUseBatches ? true : false,
            measurementUnit: this.measurementUnitSelected,
            familiesIds: this.articleFamiliesSelected.map((f) => f.id),
            articleType: this.articleTypeSelected,
        };

        if (!this.validForm(customArticleDto)) {
            return;
        }

        if (this.machineMeasurementsValueSelected && this.machineMeasurementsValueSelected.length > 0) {
            customArticleDto.machineMeasurementValues = _.cloneDeep(this.machineMeasurementsValueSelected);
            for (const mmv of customArticleDto.machineMeasurementValues) {
                mmv.formattedValue = undefined;
                mmv.id = undefined;
            }
        }

        if (this.isNewArticle) {
            this.articlesService.save(customArticleDto).subscribe({
                next: (article: ArticleDto) => {
                    try {
                        if (article == undefined) {
                            throw new Error('Error creating article');
                        }
                        this.articles.push(article);
                        const data = [...this.articles];
                        this.articles = data;
                        this.message.create('success', 'Artículo creado correctamente',);
                        this.closeDrawer();
                    } catch (error) {
                        console.error('Error creating article', error);
                        this.message.create('error', 'Error al crear el artículo');
                    }
                },
                error: (error) => {
                    console.error('Error creating article', error);
                    this.message.create('error', 'Error al crear el artículo, error de servidor');
                },
            });
        } else {
            this.articlesService.update(customArticleDto.id, customArticleDto).subscribe({
                next: (article: ArticleDto) => {
                    try {
                        if (article == undefined) {
                            throw new Error('Error updating article');
                        }
                        console.log('Article updated', article);
                        const index = this.articles.findIndex(
                            (a) => a.id === article.id,
                        );
                        if (index < 0) {
                            this.message.create('error', `No se ha podido actualizar el articulo ${customArticleDto.name} con id ${customArticleDto.id}, artículo no encontrado`);
                            throw new Error('Article not found');
                        }
                        this.articles[index] = article;
                        const data = [...this.articles];
                        this.articles = data;
                        this.message.create('success', 'Artículo actualizado correctamente');
                        console.log('Article list', this.articles);
                        this.closeDrawer();
                    } catch (error) {
                        console.error('Error updating articles', error);
                        this.message.create('error', 'Error al actualizar el artículo');
                    }
                },
                error: (error) => {
                    console.error('Error updating articles', error);
                    this.message.create('error', 'Error al actualizar el artículo, error de servidor');
                },
            });
        }
    }

    cancelEditing() {
        this.closeDrawer();
    }

    validForm(article: ArticleDto): boolean {
        if (!article.name) {
            this.message.create('error', 'El nombre del artículo es obligatorio');
            return false;
        }
        if (!article.ean) {
            this.message.create('error', 'El EAN del artículo es obligatorio');
            return false;
        }
        if (!article.alias) {
            this.message.create('error', 'El alias del artículo es obligatorio');
            return false;
        }
        if (!article.externalReference) {
            this.message.create('error', 'La referencia externa del artículo es obligatoria');
            return false;
        }
        if (article.quantity === undefined || article.quantity === null) {
            this.message.create('error', 'La cantidad del artículo es obligatoria');
            return false;
        }
        if (article.quantity < 0) {
            this.message.create('error', 'La cantidad del artículo no puede ser negativa');
            return false;
        }
        return true;
    }

    changeMeasure(id: number) {
        this.measurementUnitSelected = this.measurementUnits.find(
            (m) => m.id === id,
        ) as MeasurementUnitDto;
    }

    changeType(id: number) {
        this.articleTypeSelected = this.articleTypeDto.find(
            (t) => t.id === id,
        ) as ArticleTypeDto;
    }

    addEntryMachineMeasurement() {
        if (!this.machineMeasurementsValueAdding.value || this.machineMeasurementsValueAdding.value === '') {
            this.message.create('error', 'El valor de la medida es obligatorio');
            return;
        }
        const measurement: string = this.machineMeasurements.find((m) => m.id === this.machineMeasurementsSelected.id)?.measurement as string;
        this.machineMeasurementsValueAdding.formattedValue = this.machineMeasurementsValueAdding.value + ' ' + measurement;
        this.machineMeasurementsValueAdding.articleId = this.articleSelected.id;
        this.machineMeasurementsValueAdding.machineId = this.machineSelected.id;
        this.machineMeasurementsValueAdding.measurementId = this.machineMeasurementsSelected.id as number;

        if (!this.machineMeasurementsValueAdding.id) {
            this.machineMeasurementsValueAdding.id = this.machineMeasurementsValues.length + 1;
        }

        const machineMeasurementsValueAddingClone = _.cloneDeep(this.machineMeasurementsValueAdding);

        const data = [...this.machineMeasurementsValueSelected];
        data.push(machineMeasurementsValueAddingClone);
        this.machineMeasurementsValueSelected = data;


    }

    deleteEntryMachineMeasurement(id: number | undefined) {
        if (!id) {
            this.message.create('error', 'Error al eliminar la medida de máquina');
            return;
        }

        const data = this.machineMeasurementsValueSelected.filter((mmv) => mmv.id !== id);
        this.machineMeasurementsValueSelected = data;
    }

    compareArticleFamilies = (o1: any, o2: any): boolean => {
        return o1 && o2 ? o1.id === o2.id : o1 === o2;
    };
}
