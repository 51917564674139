import { Injectable } from '@angular/core';
import { EntityStructureInfo } from '../models/entity-structure-info';

export interface ValidationFormStructure {
    name: string;
    validator: string;
    message: string;
}

export interface ItemFormStructure {
    component: string;
    type: string;
    subtype: string;
    disabled: boolean;
    required: boolean;
    label: string;
    name: string;
    value: string | boolean | number | Date | any[];
    visible: boolean;
    validations: ValidationFormStructure[] | [];
}

@Injectable({
    providedIn: 'root',
})

export class FormService {
    constructor() {}

    formStructure: ItemFormStructure[] = [
        {
            component: 'input',
            type: 'text',
            subtype: 'subtext',
            disabled: false,
            required: false,
            visible: true,
            label: 'Name',
            name: 'name',
            value: 'Paco',
            validations: [
                {
                    name: 'required',
                    validator: 'required',
                    message: 'Name is required',
                },
            ],
        },
        {
            component: 'input',
            type: 'email',
            subtype: '',
            disabled: true,
            required: true,
            visible: true,
            label: 'Email',
            name: 'email',
            value: '',
            validations: [
                {
                    name: 'required',
                    validator: 'required',
                    message: 'Email is required',
                },
                {
                    name: 'pattern',
                    validator: 'email',
                    message: 'Invalid email format',
                },
            ],
        },
        {
            component: 'date',
            type: '',
            subtype: '',
            disabled: true,
            required: false,
            visible: true,
            label: 'Fecha creación',
            name: 'createdAt',
            value: new Date(),
            validations: [],
        },
        {
            component: 'checkbox',
            type: '',
            subtype: '',
            disabled: true,
            required: true,
            visible: true,
            label: 'Activa',
            name: 'active',
            value: true,
            validations: [
                {
                    name: 'required',
                    validator: 'required',
                    message: 'Email is required',
                },
            ],
        },
        {
            component: 'date',
            type: '',
            subtype: '',
            disabled: true,
            required: false,
            visible: true,
            label: 'Fecha creación',
            name: 'updatedAt',
            value: new Date(),
            validations: [],
        },
        {
            component: 'select',
            type: 'list',
            subtype: 'sublist',
            disabled: true,
            required: false,
            visible: true,
            label: 'Seleccion',
            name: 'select',
            value: [],
            validations: [],
        },
    ];

    getFormStructure(): ItemFormStructure[] {
        return this.formStructure;
    }

    mapEntityToFormStructure(
        entityFields: EntityStructureInfo[],
        isView: boolean = false,
        entity?: any,
    ): ItemFormStructure[] {
        const formStructure: ItemFormStructure[] = [];
        for (const field of entityFields) {
            let component = '';
            switch (field.properties.dataType) {
                case 'string':
                    component = 'input';
                    break;

                case 'number':
                    component = 'inputnumber';
                    break;

                case 'Date':
                    component = 'date';
                    break;

                case 'boolean':
                    component = 'checkbox';
                    break;
                case 'list':
                    component = 'select';
                    break;

                case 'any':
                    component = 'array';
                    break;

                default:
                    component = 'input';
                    break;
            }

            const validations: ValidationFormStructure[] = [];
            const label = field.properties.config?.find(
                (config) => config.lang === 'es-ES',
            )?.text;
            this.buildValidations(
                field,
                validations,
                label,
                formStructure,
                component,
                isView,
                entity,
            );
        }

        return formStructure;
    }

    private buildValidations(
        field: EntityStructureInfo,
        validations: ValidationFormStructure[],
        label: string | undefined,
        formStructure: ItemFormStructure[],
        component: string,
        isView: boolean,
        entity?: any,
    ) {
        if (field.restrictions && field.restrictions['required']) {
            validations.push({
                name: 'required',
                validator: 'required',
                message: `El campo ${label ? label : field.fieldName} es obligatorio`,
            });
        }

        if (field.restrictions && field.restrictions['isIP']) {
            validations.push({
                name: 'ip',
                validator: 'ip',
                message: `El formato del campo ${label ? label : field.fieldName} no es correcto, ejemplo: 8.8.8.8`,
            });
        }

        if (field.restrictions && field.restrictions['isEmail']) {
            validations.push({
                name: 'email',
                validator: 'email',
                message: `El formato del campo ${label ? label : field.fieldName} no es correcto, ejemplo: ejemplo@email.es`,
            });
        }

        if (field.properties.dataType && field.properties.dataType !== 'any') {
            //            console.log(field.properties);
            formStructure.push({
                component: component,
                type: field.properties.dataType,
                subtype: field.properties.dataSubType,
                visible:
                    field.properties['visible'] === undefined
                        ? true
                        : field.properties['visible'],
                disabled: isView,
                label: label ? label : field.fieldName,
                name: field.fieldName,
                required:
                    field.restrictions && field.restrictions['required']
                        ? true
                        : false,
                validations: validations,
                value:
                    entity == undefined
                        ? this.getDefaultValueByType(field.properties.dataType)
                        : entity[field.fieldName], // TODO: si entity null default
            });
        }
    }

    private getDefaultValueByType(type: string): any {
        let value: any = '';
        switch (type) {
            case 'number':
                value = 0;
                break;
            case 'list':
                value = [];
                break;
        }
        return value;
    }
}
