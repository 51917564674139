import { ArticleDto } from "./article.dto";
import { MachineMeasurementDto } from "./machine-measurements.dto";
import { MachineDto } from "./machine.dto";

/**
 * Represents a machine measurement value
 */
export class MachineMeasurementsValueDto {

    /**
     * Do not send this to the server
     */
    id?: number;

    /**
     * The value of the measurement
     */
    value!: string;

    /**
     * Do not send this to the server
     */
    machine?: MachineDto;

    /**
     * The id of the machine that this measurement belongs to (MachineDto)
     */
    machineId!: number;

    /**
     * Do not send this to the server
     */
    measurement?: MachineMeasurementDto;

    /**
     * The id of the measurement unit (MachineMeasurementDto)
     */
    measurementId!: number;

    /**
     * Do not send this to the server
     */
    article?: ArticleDto;

    /**
     * The id of the article that this measurement belongs to (ArticleDto)
     */
    articleId!: number;

    /**
     * The formatted value to be displayed, do not sent to the server
     */
    formattedValue?: string;
}
