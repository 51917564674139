import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root',
})
export class UpdateAppService {
    constructor(private readonly updates: SwUpdate) {
        // const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable === true));
        // const everySixHours$ = interval(6 * 60 * 60 * 1000);
        const everyHalfHour$ = interval(1 * 60 * 1000); //every2minute
        // const everyHalfHourOnceAppIsStable$ = concat(appIsStable$, everyHalfHour$);
        //everyHourOnceAppIsStable$.subscribe(() => updates.checkForUpdate());
        everyHalfHour$.subscribe(() => {
            if (environment.preproduction || environment.production) {
                updates.checkForUpdate();
            }
        });
        this.updates.versionUpdates.subscribe(() => this.updateApp());
    }

    updateApp() {
        this.updates.activateUpdate().then(() => document.location.reload());
    }
}
