import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { MachineMeasurementDto } from 'src/app/shared/dto/machine-measurements.dto';
import { MachineMeasurementResponseDto } from 'src/app/shared/dto/pagination/machine-measurements-response.dto';
import { BaseService } from 'src/app/shared/services/base.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';

@Injectable({
    providedIn: 'root'
})
export class MachineMeasurementsService extends BaseService {
    private machineMeasurementsSubject = new BehaviorSubject<MachineMeasurementDto[]>([]);
    public machineMeasurements$ = this.machineMeasurementsSubject.asObservable();

    private readonly socketService = inject(SocketService);

    constructor(httpClient: HttpClient) {
        super(httpClient, 'machine-measurements');
    }

    async setupWebSocket(): Promise<void> {
        try {
            this.machineMeasurementsSubject.next((await this.findAll()).machineMeasurements);
            this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.MACHINE_MEASUREMENTS_ROOM));
            this.socketService.getNotifications(Messages.MACHINE_MEASUREMENTS_CHANGED).subscribe(async () => {
                const response = await this.findAll();
                this.machineMeasurementsSubject.next(response.machineMeasurements);
            });
        } catch (e: any) {
            throw new Error(`Error al establecer conexión con el socket de Medidas de Máquina. Error: ${e.message}`);
        }
    }

    disconnectWebSocket(): void {
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.MACHINE_MEASUREMENTS_ROOM));
    }

    async findAll(): Promise<MachineMeasurementResponseDto> {
        try {
            const request = this.httpClient.get<MachineMeasurementResponseDto>(`${this.url}/findAll`);

            return (await lastValueFrom(request));
        } catch (e: any) {
            throw new Error(`No se ha encontrado ninguna máquina. Error: ${e.message}`);
        }
    }
}
