<div class="ml-4 mr-4">
    <nz-page-header class="site-page-header" nzTitle="Usuarios" nzSubtitle="Usuarios en el sistema">
        <nz-page-header-extra>
            <nz-space>
                <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button" (click)="createUser()">
                    Añadir usuario
                </button>
            </nz-space>
        </nz-page-header-extra>
    </nz-page-header>

    <nz-table #usersTable [nzData]="users" [nzLoading]="loadingTable">
        <thead>
            <tr>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Correo electrónico</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            @for (data of usersTable.data; track data) {
            <tr>
                <td>{{ data.name }}</td>
                <td>{{ data.surname }}</td>
                <td>{{ data.email }}</td>
                <td>
                    <div class="buttonsDnDTableContainer">
                        <img src="assets/icons/eye-icon.svg" class="h-6 w-6" (click)="seeUser(data.id)" title="Ver">
                        <nz-divider nzType="vertical"></nz-divider>
                        <img src="assets/icons/edit-icon.svg" class="h-6 w-6" (click)="editUser(data.id)"
                            title="Editar">
                        <nz-divider nzType="vertical"></nz-divider>
                        <img src="assets/icons/xmark-icon.svg" class="h-6 w-6" (click)="deleteUser(data.id)"
                            title="Eliminar">
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </nz-table>
</div>

<nz-drawer [nzClosable]="false" [nzVisible]="drawerVisible" nzPlacement="right" [nzTitle]="drawerTitle"
    (nzOnClose)="closeDrawer()" [nzWidth]="'500px'" [nzMaskClosable]="fieldsReadOnly">
    <ng-container *nzDrawerContent>
        <form (ngSubmit)="saveEditing()">

            <label>Nombre:</label>
            <input nz-input [value]="userSelected?.name || ''" type="text" class="searchSelectors"
                [readOnly]="fieldsReadOnly" id="name" />

            <label>Apellido:</label>
            <input nz-input [value]="userSelected?.surname || ''" type="text" class="searchSelectors"
                [readOnly]="fieldsReadOnly" id="surname" />

            <label>Username:</label>
            <input nz-input [value]="userSelected?.username || ''" type="text" class="searchSelectors"
                [readOnly]="fieldsReadOnly" id="username" />

            @if (!fieldsReadOnly) {
            <label>Contraseña:</label>
            <input nz-input [value]="userSelected?.password || ''" type="password" class="searchSelectors"
                [readOnly]="fieldsReadOnly" id="inputPassword"
                [placeholder]=" isNewUser ? '' : 'Dejar vacio para no cambiar' " />
            }

            <label>Email:</label>
            <input nz-input [value]="userSelected?.email || ''" type="text" class="searchSelectors"
                [readOnly]="fieldsReadOnly" id="email" />

            <label nz-checkbox [(ngModel)]="externalAccess" [nzDisabled]="fieldsReadOnly" name="batchControl">¿Acceso
                externo?</label>
            <br>

            <label>Perfil:</label>
            <nz-select nzShowSearch nzPlaceHolder="Selecciona" [(ngModel)]="profileSelected" name="profileSelector"
                class="searchSelectors" [nzDisabled]="fieldsReadOnly" [compareWith]="compareProfiles">
                @for (p of profileDataSet; track p.name) {
                <nz-option [nzLabel]="p.name" [nzValue]="p"></nz-option>
                }
            </nz-select>

            @if (!fieldsReadOnly) {
            <button
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit">
                Guardar
            </button>
            <nz-divider nzType="vertical"></nz-divider>
            <button
                class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button" (click)="cancelEditing()">
                Cancelar
            </button>
            }
        </form>
    </ng-container>
</nz-drawer>
