import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ProductionOrderStatesEnum } from '../../../shared/enums/production-order-states.enum';

interface MachineDashboard {
    machineName: string;
    articleName: string;
    processProgress: string;
    scraps: number;
    pauses: number;
    quantityProduced: number;
    quantityRealTime: number;
    status: ProductionOrderStatesEnum;
    measurementUnit: string;
    uptime: number;
    estimationTime: number;
}

@Component({
    selector: 'app-machines-dashboard',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './machines-dashboard.component.html',
    styleUrl: './machines-dashboard.component.scss'
})
export class MachinesDashboardComponent {
    machinesDashboard: MachineDashboard[];
    floors: string[] = ['Planta 1', 'Planta 2', 'Planta 3', 'Planta 4', 'Planta 5'];
    zones: string[] = ['Zona 1', 'Zona 2', 'Zona 3', 'Zona 4', 'Zona 5'];
    lines: string[] = ['Linea 1', 'Linea 2', 'Linea 3', 'Linea 4', 'Linea 5'];
    productionOrderStatesEnum = ProductionOrderStatesEnum;

    constructor() {
        this.machinesDashboard = [
            {
                machineName: 'Maquina 1',
                articleName: 'Cable',
                processProgress: '50%',
                scraps: 0,
                pauses: 0,
                quantityProduced: 160,
                quantityRealTime: 25,
                status: ProductionOrderStatesEnum.IN_PROGRESS,
                measurementUnit: 'm',
                uptime: 60,
                estimationTime: 120,
            },
            {
                machineName: 'Maquina 2',
                articleName: 'Tubo',
                processProgress: '100%',
                scraps: 1,
                pauses: 0,
                quantityProduced: 187,
                quantityRealTime: 0,
                status: ProductionOrderStatesEnum.FINISHED,
                measurementUnit: 'm',
                uptime: 180,
                estimationTime: 0,
            },
            {
                machineName: 'Maquina 3',
                articleName: 'Cuerda',
                processProgress: '75%',
                scraps: 0,
                pauses: 0,
                quantityProduced: 200,
                quantityRealTime: 10,
                status: ProductionOrderStatesEnum.IN_PROGRESS,
                measurementUnit: 'm',
                uptime: 100,
                estimationTime: 150,
            },
            {
                machineName: 'Maquina 4',
                articleName: 'Manguera',
                processProgress: '25%',
                scraps: 0,
                pauses: 0,
                quantityProduced: 20,
                quantityRealTime: 2,
                status: ProductionOrderStatesEnum.IN_PROGRESS,
                measurementUnit: 'm',
                uptime: 45,
                estimationTime: 180,
            },
            {
                machineName: 'Maquina 5',
                articleName: 'Cinta',
                processProgress: '20.5%',
                scraps: 0,
                pauses: 2,
                quantityProduced: 35.4,
                quantityRealTime: 0,
                status: ProductionOrderStatesEnum.PAUSED,
                measurementUnit: 'm',
                uptime: 50,
                estimationTime: 220,
            },
            {
                machineName: 'Maquina 6',
                articleName: 'Cable de fibra óptica',
                processProgress: '100%',
                scraps: 3,
                pauses: 1,
                quantityProduced: 0,
                quantityRealTime: 0,
                status: ProductionOrderStatesEnum.FINISHED,
                measurementUnit: 'm',
                uptime: 250,
                estimationTime: 0,
            },
            {
                machineName: 'Maquina 7',
                articleName: 'Cuerda de seguridad',
                processProgress: '0%',
                scraps: 0,
                pauses: 0,
                quantityProduced: 0,
                quantityRealTime: 0,
                status: ProductionOrderStatesEnum.AWAITING,
                measurementUnit: 'm',
                uptime: 0,
                estimationTime: 100,
            }
        ];
    }

}
