<div *nzModalTitle>
  @if (nzModalData.title) {
    <h3>
      <span nz-icon class="text-xl" nzType="exclamation-circle"></span>
      {{ nzModalData.title }}
    </h3>
  }
</div>

<div class="p-4">
  <p>{{ nzModalData.message }}</p>
</div>

<div *nzModalFooter>
  <button nz-button nzType="default" (click)="cancel()">Cancelar</button>
  <button nz-button nzType="primary" (click)="accept()">Aceptar</button>
</div>
