import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ProductionProcessDto } from 'src/app/shared/dto/production-process.dto';
import { ProductionRouteDto } from 'src/app/shared/dto/production-route.dto';
import { BaseService } from 'src/app/shared/services/base.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';
import { ProductionProcessResponseDto } from '../../shared/dto/pagination/production-process-response.dto';

@Injectable({
    providedIn: 'root',
})
export class ProductionProcessesService extends BaseService {
    private productionPrcesessSubject = new BehaviorSubject<ProductionProcessDto[]>([]);
    public productionProcessess$ = this.productionPrcesessSubject.asObservable();

    private readonly socketService = inject(SocketService);

    constructor(httpClient: HttpClient) {
        super(httpClient, 'production-processes');
    }

    async setupWebSocket(): Promise<void> {
        try {
            this.productionPrcesessSubject.next((await this.findAll()).productionProcesses);
            this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.PRODUCTION_PROCESSES_ROOM));
            this.socketService.getNotifications(Messages.PRODUCTION_PROCESSESS_CHANGED).subscribe(async () => {
                const response = await this.findAll();
                this.productionPrcesessSubject.next(response.productionProcesses);
            });
        } catch (e: any) {
            throw new Error(`Error al establecer conexión con el socket. Error: ${e.message}`);
        }
    }

    disconnectWebSocket(): void {
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.PRODUCTION_PROCESSES_ROOM));
    }

    async findAll(): Promise<ProductionProcessResponseDto> {
        try {
            const request = this.httpClient.get<ProductionProcessResponseDto>(`${this.url}/findAll`);

            const response: ProductionProcessResponseDto = await lastValueFrom(request);

            if (!response) {
                throw null;
            }

            return response;
        } catch (e: any) {
            throw new Error(`No se ha encontrado ningun proceso de producción. Error: ${e.message}`);
        }
    }

    async createProcess(process: ProductionProcessDto): Promise<ProductionProcessDto> {
        try {
            const request = this.httpClient.post<ProductionProcessDto>(`${this.url}/create`, process);

            const createdProcess: ProductionProcessDto = await lastValueFrom(request);

            if (!createdProcess) {
                throw null;
            }

            return createdProcess;

        } catch (e: any) {
            throw new Error(`No se ha podido crear el proceso de producción. Error: ${e.error.message}`);
        }
    }

    async updateProcess(process: ProductionProcessDto): Promise<ProductionProcessDto> {
        try {
            const request = this.httpClient.put<ProductionProcessDto>(`${this.url}/update`, process);

            const updatedProcess: ProductionProcessDto = await lastValueFrom(request);

            if (!updatedProcess) {
                throw null;
            }

            return updatedProcess;

        } catch (e: any) {
            throw new Error(`No se ha podido actualizar el proceso de producción. Error: ${e.message}`);
        }
    }

    async deleteProcess(processId: number): Promise<ProductionProcessDto> {
        try {
            const request = this.httpClient.delete<ProductionProcessDto>(`${this.url}/${processId}`);

            const response: ProductionProcessDto = await lastValueFrom(request);

            if (!response) {
                throw null;
            }

            return response;
        } catch (e: any) {
            throw new Error(`No se ha podido eliminar el proceso de producción ${processId}. Error: ${e.message}`);
        }
    }

    sortProductionProcessByOrder(productionProcesses: ProductionProcessDto[], productionRoute: ProductionRouteDto, selectedProcesses: ProductionProcessDto[], rows: any): void {

        const sortedProductionProcesses = productionRoute.productionProcesses?.sort((a, b) => a.order! - b.order!);

        sortedProductionProcesses?.forEach((p) => {
            const process = productionProcesses.find(
                (pp) => pp.id === p.productionProcessId,
            );

            if (process) {
                rows.push(process);
            }

            if (!selectedProcesses.includes(process as ProductionProcessDto)) {
                selectedProcesses.push(process as ProductionProcessDto);
            }
        });
    }

}
