{
	"name": "devolsoft-mes",
	"version": "0.35.4",
	"scripts": {
		"ng": "ng",
		"start": "(npm run lint || npm run lint:fix) && ng serve",
		"start:dev": "ng serve",
		"build": "ng build",
		"watch": "ng build --watch --configuration development",
		"test": "ng test",
		"lint": "ng lint",
		"lint:fix": "ng lint --fix"
	},
	"private": true,
	"dependencies": {
		"@angular/animations": "^18.0.4",
		"@angular/cdk": "^18.0.6",
		"@angular/common": "^18.0.4",
		"@angular/compiler": "^18.0.4",
		"@angular/core": "^18.0.4",
		"@angular/forms": "^18.0.4",
		"@angular/platform-browser": "^18.0.4",
		"@angular/platform-browser-dynamic": "^18.0.4",
		"@angular/router": "^18.0.4",
		"@angular/service-worker": "^18.0.4",
		"@ant-design/icons": "^5.3.0",
		"@auth0/angular-jwt": "^5.2.0",
		"@carbon/icons": "^11.34.1",
		"@ngneat/overview": "^6.0.0",
		"@schematics/angular": "^17.1.2",
		"@swimlane/ngx-graph": "^8.4.0",
		"@types/d3": "^7.4.3",
		"@types/lodash": "^4.14.202",
		"angular-cd-timer": "^3.0.0",
		"d3": "^7.8.5",
		"d3-selection": "^1.2.0",
		"daisyui": "^4.6.1",
		"eslint-config-prettier": "^9.1.0",
		"eslint-plugin-prettier": "^5.1.3",
		"flowbite": "^2.2.1",
		"headlessui-angular": "^0.0.7",
		"js-sha256": "^0.11.0",
		"lodash": "^4.17.21",
		"moment": "^2.30.1",
		"moment-timezone": "^0.5.45",
		"ng-connection-service": "^9.0.3",
		"ng-zorro-antd": "^18.0.1",
		"ngx-socket-io": "^4.7.0",
		"prettier-eslint": "^16.3.0",
		"rxjs": "~7.8.0",
		"socket.io-client": "^4.7.4",
		"tailwindcss": "^3.4.1",
		"tslib": "^2.3.0",
		"zone.js": "~0.14.3"
	},
	"devDependencies": {
		"@angular-devkit/build-angular": "^18.0.5",
		"@angular-eslint/builder": "17.2.1",
		"@angular-eslint/eslint-plugin": "17.2.1",
		"@angular-eslint/eslint-plugin-template": "17.2.1",
		"@angular-eslint/schematics": "^18.1.0",
		"@angular-eslint/template-parser": "17.2.1",
		"@angular/cli": "^18.0.5",
		"@angular/compiler-cli": "^18.0.4",
		"@types/jasmine": "~5.1.0",
		"@typescript-eslint/eslint-plugin": "6.19.0",
		"@typescript-eslint/parser": "6.19.0",
		"eslint": "^8.56.0",
		"jasmine-core": "~5.1.0",
		"karma": "~6.4.0",
		"karma-chrome-launcher": "~3.2.0",
		"karma-coverage": "~2.2.0",
		"karma-jasmine": "~5.1.0",
		"karma-jasmine-html-reporter": "~2.1.0",
		"prettier": "^3.3.1",
		"typescript": "~5.4.5"
	}
}
