import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import _ from 'lodash';
import { environment } from 'src/environments/environment';
import { UpdateAppService } from './core/updates/update-app.service';
import { ConnectionService, ConnectionState } from 'ng-connection-service';
import { RouterOutlet } from '@angular/router';
import { NoInternetComponent } from './public/views/errors/no-internet/no-internet.component';
import { PreProductionBannerComponent } from './shared/components/pre-production-banner/pre-production-banner.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    standalone: true,
    imports: [
        PreProductionBannerComponent,
        NoInternetComponent,
        RouterOutlet,
    ],
})
export class AppComponent implements OnInit {
    title = 'devolsoft-mes';
    previousCon = true;
    showNoInternetScreen: boolean = false;
    environment: any;

    constructor(
        private updateAppService: UpdateAppService,
        private connectionService: ConnectionService,
        private cdRef: ChangeDetectorRef,
    ) {
        this.environment = _.cloneDeep(environment);
        this.connectionService
            .monitor()
            .subscribe((connectionState: ConnectionState) => {
                const isConnected = connectionState.hasNetworkConnection;
                this.showNoInternetScreen = this.previousCon && !isConnected;
                this.previousCon = isConnected;
            });
    }

    ngOnInit(): void {
        this.cdRef.detectChanges();
    }
}
