import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-error-api',
    templateUrl: './error-api.component.html',
    styleUrls: ['./error-api.component.scss'],
    standalone: true,
    imports: [],
})
export class ErrorApiComponent implements OnInit {
    error: Error | null = null;
    environment: any;

    constructor(private router: Router) {
        if (
            this.router.getCurrentNavigation() === undefined ||
            this.router.getCurrentNavigation() === null
        ) {
            history.back();
        }
        if (
            this.router.getCurrentNavigation()!.extras.state === undefined ||
            this.router.getCurrentNavigation()!.extras.state == null
        ) {
            this.router.navigateByUrl('/');
        }
        const state = this.router.getCurrentNavigation()!.extras.state;
        this.error = state!['error'];
    }

    ngOnInit(): void {
        this.environment = _.cloneDeep(environment);
        if (this.error === null) {
            history.back();
        }
    }

    goBack(): void {
        //history.back();
        this.router.navigateByUrl('/');
    }
}
