import { Component, EventEmitter, inject, Inject, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalFooterDirective, NzModalService } from 'ng-zorro-antd/modal';
import { NzTableModule } from 'ng-zorro-antd/table';
import { ArticleBatchDto } from 'src/app/shared/dto/article-batch.dto';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FormsModule } from '@angular/forms';
import { NzCheckboxComponent } from 'ng-zorro-antd/checkbox';
import { PackagingsService } from 'src/app/private/services/packagings.service';
import { PackagingDto } from 'src/app/shared/dto/packaging.dto';

@Component({
    selector: 'app-modal-select-article-packaging',
    standalone: true,
    imports: [
        NzTableModule,
        NzModalFooterDirective,
        NzTagModule,
        NzIconModule,
        NzCheckboxComponent,
        FormsModule
    ],
    templateUrl: './modal-select-article-packaging.component.html',
    styleUrl: './modal-select-article-packaging.component.scss'
})
export class ModalSelectArticlePackagingComponent {
    @Output() emitService = new EventEmitter();

    private readonly modalService = inject(NzModalService);
    private readonly toast = inject(NzMessageService);
    private readonly packagingsService = inject(PackagingsService);

    currentArticleBatch: ArticleBatchDto;
    packagings: PackagingDto[] = [];
    loading: boolean = true;
    selectedPackagings: number[] = [];
    canSetProducedQuantity: boolean = false;
    justWatching: boolean = false;

    private articleId: number = 0;
    private selectedArticleBatches: ArticleBatchDto[] = [];
    private quantityToProduce: number = 0;
    private quantityProduced: number = 0;

    constructor(@Inject(NZ_MODAL_DATA) public data: any) {
        this.currentArticleBatch = data?.data?.currentArticleBatch;
        this.selectedArticleBatches = data?.data?.selectedArticleBatches;
        this.quantityToProduce = data?.data?.quantityToProduce;
        this.quantityProduced = data?.data?.quantityProduced;
        this.canSetProducedQuantity = data?.data?.canSetProducedQuantity;
        this.justWatching = data?.data?.justWatching;
        this.articleId = data?.data?.articleId;
    }

    async ngOnInit() {
        if (!this.currentArticleBatch) {
            this.loading = false;
            this.toast.error('Algo ha ido mal obteniendo los formatos');

            return;
        }

        this.packagings = await this.packagingsService.getPackagingsByArticleId(this.articleId);
     
        this.selectedPackagings =  this.currentArticleBatch.packagings?.map(packaging => packaging.id!) ?? [];

        if (this.currentArticleBatch.packagings) {
            this.selectedPackagings =  this.currentArticleBatch.packagings?.map(packaging => packaging.id!) ?? [];

            for (let packaging of this.currentArticleBatch.packagings) {
                const index: number = this.packagings.findIndex(b => b.id === packaging.id);

                this.packagings[index].quantity = packaging.quantity;
            }
        }  

        this.loading = false;
    }

    setAsSelected(batchId: number) {
        if (this.selectedPackagings.includes(batchId)) {
            this.selectedPackagings.splice(this.selectedPackagings.indexOf(batchId), 1)

            return;
        }

        this.selectedPackagings.push(batchId);
    }

    save() {
        let packagingsToAssign: PackagingDto[] = [];

        for (let selectedPackagingId of this.selectedPackagings) {
            packagingsToAssign.push(this.packagings.find(p => p.id === selectedPackagingId)!);
        }

        if (this.canSetProducedQuantity && !this.validateQuantities(packagingsToAssign)) {
            return;
        }

        this.emitService.next(packagingsToAssign);
    }

    handleCancel() {
        this.emitService.next(undefined);
    }

    private validateQuantities(packagingsToAssign: PackagingDto[]): boolean {
        let currentBatchTotal: number = 0;

        for (let packaging of packagingsToAssign) {
            if (packaging.quantity) {
                currentBatchTotal += packaging.quantityPerPackaging * packaging.quantity;
            }

            if (currentBatchTotal > this.currentArticleBatch.quantity) {
                this.toast.error(`No puedes empaquetar más unidades de las existentes en el lote (${this.currentArticleBatch.quantity})`);
                return false;
            }
        }

        let total: number = currentBatchTotal;

        for (let selectedBatch of this.selectedArticleBatches) {
            if (!selectedBatch.packagings) {
                continue;
            }

            for (let packaging of selectedBatch.packagings) {
                if (packaging.quantity) {
                    total += packaging.quantityPerPackaging * packaging.quantity;
                }
    
                if (this.quantityProduced && this.quantityProduced > 0 && currentBatchTotal > this.quantityProduced) {
                    this.toast.error(`No puedes empaquetar más unidades de las que se han producido en el proceso (${this.quantityProduced})`);
                    return false;
                }

                if (currentBatchTotal > this.quantityToProduce) {
                    this.toast.error(`No puedes empaquetar más unidades de las que se esperan producir en el proceso (${this.quantityToProduce})`);
                    return false;
                }
            }
        }

        return true;
    }
}
