import {
    AfterViewInit,
    Component,
    ElementRef,
    inject,
    OnChanges,
    OnInit,
    SimpleChange,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import _ from 'lodash';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ScreenLoggedService } from 'src/app/core/services/screenLogged.service';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { ModalRfidComponent } from 'src/app/private/components/modals/modal-rfid/modal-rfid.component';
import { ConfigurationService } from 'src/app/private/services/configuration.service';
import { AuthService } from 'src/app/public/services/auth.service';
import { ScreenDto } from 'src/app/shared/dto/screen.dto';
import { AuthMethod } from 'src/app/shared/models/auth.model';
import { environment } from 'src/environments/environment';
import packageJson from '../../../../../package.json';

@Component({
    selector: 'app-rfid-form',
    templateUrl: './rfid-form.component.html',
    styleUrls: ['./rfid-form.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        FormsModule,
        NzMessageModule,
    ],
})
export class RfidFormComponent implements OnInit, AfterViewInit, OnChanges {
    loginScreen: ScreenDto | null = null;
    screenToBeLogged: Screen | null = null;
    environment: any;
    version: any = packageJson.version;
    helper: JwtHelperService;

    @ViewChild('rfidInput') rfidInput!: ElementRef;

    rfid: string = '';
    constructor() {
        this.helper = new JwtHelperService();
    }

    private readonly authService = inject(AuthService);
    private readonly screenLoggedService = inject(ScreenLoggedService);
    private readonly userLoggedService = inject(UserLoggedService);
    private readonly toast = inject(NzMessageService);
    private readonly router = inject(Router);
    private readonly configurationService = inject(ConfigurationService);
    private readonly modalService = inject(NzModalService);

    ngOnInit(): void {
        this.environment = _.cloneDeep(environment);
    }

    ngAfterViewInit() {
        setInterval(() => {
            this.rfidInput.nativeElement.focus();
        }, 250);
    }

    ngOnChanges(changes: SimpleChanges) {
        const loginScreen: SimpleChange = changes['loginScreen'];
        if (
            loginScreen != undefined &&
            loginScreen.previousValue != loginScreen.currentValue
        ) {
            this.screenToBeLogged = _.cloneDeep(loginScreen.currentValue);
        }
    }

    async login(): Promise<void | any> {
        try {
            let authToken = null;

            if (this.loginScreen) {
                authToken = await this.authService.login(AuthMethod.RFID, this.rfid, { screenId: this.loginScreen.id });
            } else {
                authToken = await this.authService.login(AuthMethod.RFID, this.rfid);
            }

            const dataToken = this.helper.decodeToken(authToken.accessToken);

            this.userLoggedService.setUserLogged(
                dataToken.user,
                authToken.accessToken
            );

            this.screenLoggedService.setScreen(
                this.loginScreen ? this.loginScreen : null,
            );

            const configurations = await this.configurationService.getConfigurations().catch((error) => {
                console.error(error);
                this.toast.error('No se han podido cargar las configuraciones');
            });

            localStorage.setItem('configurations', JSON.stringify(configurations));

            this.router.navigate(['/production-orders']);
        } catch (error: any) {
            return this.toast.error(error.message);
        }
    }

    onRfidRead() {
        const modal = this.modalService.create({
            nzTitle: `Inicio de sesión con RFID`,
            nzContent: ModalRfidComponent,
            nzStyle: { width: '50%', top: '15%' },
            nzClosable: false,
            nzFooter: null,
            nzData: {
                data: {
                    rfid: this.rfid,
                }
            }
        });

        modal.componentInstance?.emitRfid.subscribe((rfid: string) => {
            this.rfid = rfid;
            this.login();
        });
    }
}
