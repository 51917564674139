<div class="w-full">
    <div class="py-6">
        @if (showLoginInput) {
            <div class="form-control w-full">
                <label class="label">
                    <span class="label-text font-medium">Usuario o email</span>
                </label>
                <input #userInput nz-input class="w-full" [(ngModel)]="user" />
            </div>
            <div class="form-control w-full mt-1">
                <label class="label">
                    <span class="label-text font-medium">Contraseña</span>
                </label>
                <nz-input-group [nzSuffix]="suffixTemplate">
                    <input
                        [type]="passwordVisible ? 'text' : 'password'"
                        nz-input
                        placeholder="input password"
                        [(ngModel)]="password"
                        class="w-full"
                        (keydown.enter)="login()"
                    />
                </nz-input-group>
                <ng-template #suffixTemplate>
                    <span
                        nz-icon
                        [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                        nzTheme="outline"
                        role="button"
                        tabIndex="{0}"
                        (click)="passwordVisible = !passwordVisible"
                    ></span>
                </ng-template>
            </div>
        } @else {
            <div class="form-control w-full">
                <label class="label">
                    <span class="label-text font-medium">Codigo de Seguridad</span>
                </label>
                <input nz-input class="w-full" [(ngModel)]="codeSecurity" />
            </div>
        }

        @if (showLoginButton) {
            <button nz-button (click)="login()" nzType="primary" class="w-full mt-5">
                Iniciar sesión
            </button>
            <button nz-button (click)="registerUserSupport()" nzType="primary" class="w-full mt-5">
                Acceso de apoyo
            </button>
        } @else {
            <button nz-button (click)="verifyCode()" nzType="primary" class="w-full mt-5">
                Enviar Codigo
            </button>
        }
    </div>

    <div class="text-xs w-full text-left h-2 font-medium mt-5 flex items-center">
        <img class="w-3 h-auto mr-1.5" src="assets/svg/devoltec-logo.svg" alt="Logo" loading="lazy" />
        <p>Devoltec - v.{{ version }}</p>
    </div>
</div>
