import { Component } from '@angular/core';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzPageHeaderComponent, NzPageHeaderExtraDirective } from 'ng-zorro-antd/page-header';
import { NzTableCellDirective, NzTableComponent, NzTbodyComponent, NzTheadComponent, NzTrDirective } from 'ng-zorro-antd/table';
interface MaintenanceRequests{
    id: string;
    machineId: string;
    operator: string;
    description: string;
    priority: Priority;
}

enum Priority{
    LOW = 'Bajo',
    MEDIUM = 'Medio',
    HIGH = 'Alto',
    URGENT = 'Urgente'
}

@Component({
    selector: 'app-maintenance-requests',
    standalone: true,
    imports: [
        NzPageHeaderComponent,
        NzTableComponent,
        NzTheadComponent,
        NzTrDirective,
        NzTableCellDirective,
        NzTbodyComponent,
        NzMessageModule,
        NzPageHeaderExtraDirective
    ],
    templateUrl: './maintenance-requests.component.html',
    styleUrl: './maintenance-requests.component.scss'
})

export class MaintenanceRequestsComponent {
    maintenanceRequests: MaintenanceRequests[] = [
        {
            id: 'S001',
            machineId: 'M001',
            operator: 'OP001',
            description: 'La máquina no enciende',
            priority: Priority.URGENT
        },
        {
            id: 'S002',
            machineId: 'M002',
            operator: 'OP002',
            description: 'La máquina hace un ruido extraño',
            priority: Priority.HIGH
        },
        {
            id: 'S003',
            machineId: 'M003',
            operator: 'OP003',
            description: 'La máquina se sobrecalienta',
            priority: Priority.MEDIUM
        },
        {
            id: 'S004',
            machineId: 'M004',
            operator: 'OP002',
            description: 'La máquina tiene una fuga de aceite',
            priority: Priority.LOW
        }
    ];
}
