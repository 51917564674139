<div *nzModalTitle class="px-0 py-1">
	Ver Taras - Orden {{ processId }} | {{ processDescription }}
</div>
<div class="w-full h-full p-0 m-0 mb-0.5">
	<nz-table #basicTable [nzData]="scraps" class="m-0 p-0" [nzLoading]="isLoading" nzTableLayout="auto"
		[nzBordered]="true" [nzFrontPagination]="false" [nzShowPagination]="false" [nzScroll]="{ y: '350px' }">
		<thead>
			<tr>
				<th>Pieza</th>
				<th>Motivo</th>
				<th nzWidth="120px">Cantidad</th>
				<th>Fecha</th>
				<th>Observaciones</th>
				<th>Operario</th>
			</tr>
		</thead>
		<tbody>
			@for (data of basicTable.data; track data) {
			<tr>
				<td>{{ article.name }}</td>
				<td>{{ data.scrapCause?.text }}</td>
				<td class="text-right">
					{{ data.units }}
				</td>
				<td>
					{{
					data.date === null
					? "-"
					: (data.date | date: "dd/MM/YYYY HH:mm:ss")
					}}
				</td>
				<td>{{ data.observations }}</td>
				<td>{{ data.createdBy?.name }}</td>
			</tr>
			}
		</tbody>
	</nz-table>
</div>

<div *nzModalFooter style="border: 0px">
	<button type="submit" (click)="handleOk()"
		class="mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
		Aceptar
	</button>
</div>