import {
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Output,
} from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import * as _ from 'lodash';
import { NZ_MODAL_DATA, NzModalService, NzModalTitleDirective, NzModalFooterDirective } from 'ng-zorro-antd/modal';
import { PauseService } from 'src/app/private/services/pause.service';
import { PauseReasonDto } from 'src/app/shared/dto/pause-reason.dto';
import { ProductionPauseDto } from 'src/app/shared/dto/production-pause.dto';
import { NzWaveDirective } from 'ng-zorro-antd/core/wave';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { NzSelectComponent, NzOptionComponent } from 'ng-zorro-antd/select';
import { ɵNzTransitionPatchDirective } from 'ng-zorro-antd/core/transition-patch';
import { NzInputGroupComponent, NzInputDirective } from 'ng-zorro-antd/input';
import { NzRowDirective, NzColDirective } from 'ng-zorro-antd/grid';
import { NzFormDirective, NzFormItemComponent, NzFormControlComponent } from 'ng-zorro-antd/form';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { ProductionOrderProcessesService } from 'src/app/private/services/production-order-processes.service';
import { ProductionOrderProcessDto } from 'src/app/shared/dto/production-order-process.dto';


@Component({
    selector: 'app-modal-create-pause',
    templateUrl: './modal-create-pause.component.html',
    styleUrls: ['./modal-create-pause.component.scss'],
    standalone: true,
    imports: [
        NzModalTitleDirective,
        ReactiveFormsModule,
        NzFormDirective,
        NzRowDirective,
        NzFormItemComponent,
        NzColDirective,
        NzFormControlComponent,
        NzInputGroupComponent,
        ɵNzTransitionPatchDirective,
        NzInputDirective,
        NzSelectComponent,
        NzOptionComponent,
        NzButtonComponent,
        NzWaveDirective,
        NzModalFooterDirective,
        NzMessageModule,
    ],
})
export class ModalCreatePauseComponent implements OnInit {
    @Output() emitService = new EventEmitter();

    isLoading: boolean = true;
    orderProcessId: number = 0;
    createdPauseId: number = 0;
    orderProcessDescription: string = '';
    pauseReasons: PauseReasonDto[] = [];
    currentUser: UserDto = new UserDto();
    previousStatusId: number = 0;

    validateForm: FormGroup<{
        observations: FormControl<string>;
        selectedReason: FormControl<PauseReasonDto>;
    }> = this.fb.group({
        observations: ['', [Validators.required]],
        selectedReason: [new PauseReasonDto(), [Validators.required]], // ToDo: review why this is not being validated and the create button is able to be clicked
    });

    constructor(
        @Inject(NZ_MODAL_DATA) public data: any,
        private modalService: NzModalService,
        private toast: NzMessageService,
        private pauseService: PauseService,
        private fb: NonNullableFormBuilder,
        private loggedService: UserLoggedService,
        private orderProcessService: ProductionOrderProcessesService,
    ) {
        this.orderProcessId = data?.data?.orderProcessId as number;
        this.orderProcessDescription = data?.data?.orderProcessDescription as string;
        this.previousStatusId = data?.data?.previousStatusId as number;

        this.isLoading = false;
    }

    async ngOnInit(): Promise<void> {
        this.isLoading = true;
        this.currentUser = this.loggedService.userLogged;

        await this.getPauseReasons();

        this.isLoading = false;
    }

    handleOk(): void {
        this.submitForm();
    }

    handleCancel(): void {
        this.modalService.closeAll();
    }

    async submitForm(): Promise<void> {
        try {
            console.log('submit', this.validateForm.value);

            if (!this.validateForm.valid) {
                this.toast.error(`Porfavor, rellene los campos obligatorios`);
                return;
            }

            if (this.validateForm.value.selectedReason == undefined || isNaN(Number(this.validateForm.value.selectedReason))) {
                this.toast.error(`Porfavor, seleccione un motivo de pausa`);
                return;
            }

            const pause: ProductionPauseDto = {
                startDate: new Date(),
                endDate: new Date(),
                observations: this.validateForm.value.observations ?? '',
                productionOrderProcessId: this.orderProcessId,
                pauseReasonId: Number(this.validateForm.value.selectedReason) ?? 0,
                pausedById: this.currentUser.id,
                previousStatusId: this.previousStatusId,
            };

            const updatedOrderProcess: ProductionOrderProcessDto = await this.orderProcessService.setOrderProcessAsPaused(pause);

            this.emitService.next(updatedOrderProcess);

            this.modalService.closeAll();
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.toast.error(e.message);
            }
        }
    }

    async getPauseReasons() {
        try {
            this.pauseReasons = await this.pauseService.getReasons();
            console.log('pauseReasons', this.pauseReasons);
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.toast.error(e.message);
            }
        }
    }
}
