import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ScrapDto } from 'src/app/shared/dto/scrap.dto';
import { BaseService } from 'src/app/shared/services/base.service';

@Injectable({
    providedIn: 'root',
})
export class ScrapService extends BaseService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'scraps');
    }

    async create(scrap: ScrapDto): Promise<ScrapDto> {
        try {
            if (!scrap) {
                throw new Error('La tara no es un objeto válido.');
            }

            const createdScrap = await lastValueFrom(
                this.httpClient.post<ScrapDto>(`${this.url}`, scrap)
            );

            return createdScrap as ScrapDto;
        } catch (e: any) {
            throw new Error(`No se ha podido crear la tara. Error: ${e.message}`);
        }
    }
}
