import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { MachineValuesDto } from 'src/app/shared/dto/machine-values.dto';
import { MachineMeasurementsValueResponseDto } from 'src/app/shared/dto/pagination/machine-measurements-value-response.dto';
import { BaseService } from 'src/app/shared/services/base.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';
import { environment } from 'src/environments/environment';
import { MachineMeasurementsValueDto } from '../../shared/dto/machine-measurements-value.dto';

@Injectable({
    providedIn: 'root'
})
export class MachineMeasurementsValueService extends BaseService {
    private machineMeasurementsValuesSubject = new BehaviorSubject<MachineMeasurementsValueDto[]>([]);
    public machineMeasurementsValues$ = this.machineMeasurementsValuesSubject.asObservable();

    private readonly socketService = inject(SocketService);

    constructor(httpClient: HttpClient) {
        super(httpClient, 'machine-measurements-values');
    }

    async setupWebSocket(): Promise<void> {
        try {
            this.machineMeasurementsValuesSubject.next((await this.findAll()).machineMeasurementsValues);
            this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.MACHINE_MEASUREMENTS_VALUES_ROOM));
            this.socketService.getNotifications(Messages.MACHINE_MEASUREMENTS_VALUES_CHANGED).subscribe(async () => {
                const response = await this.findAll();
                this.machineMeasurementsValuesSubject.next(response.machineMeasurementsValues);
            });
        } catch (e: any) {
            throw new Error(`Error al establecer conexión con el socket de Valores de Medidas de Maquina. Error: ${e.message}`);
        }
    }

    disconnectWebSocket(): void {
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.MACHINE_MEASUREMENTS_VALUES_ROOM));
    }

    getMachineValuesFormated(orderProcessId: number): Observable<{ data: MachineValuesDto[], total: number, page?: number, lastPage?: number }> {
        return this.httpClient.get<{ data: MachineValuesDto[], total: number, page?: number, lastPage?: number }>(`${environment.apiUrl}machine-values/${orderProcessId}`);
    }

    async findAll(): Promise<MachineMeasurementsValueResponseDto> {
        try {
            const request = this.httpClient.get<MachineMeasurementsValueResponseDto>(`${this.url}/findAll`);

            return (await lastValueFrom(request));
        } catch (e: any) {
            throw new Error(`No se ha encontrado ningun valor de medición de máquina. Error: ${e.message}`);
        }
    }
}
