<!--
<nz-layout style="height: 100vh;">
  <nz-header style="height: auto !important; padding: 0px;">
    <app-toolbar></app-toolbar>
  </nz-header>
  <nz-content style="background-color: white !important">
    <router-outlet></router-outlet>
  </nz-content>
</nz-layout>

-->
<div
    [class]="
        environment.preproduction || environment.development || environment.local
            ? 'h-[calc(100%_-_44px)]  max-h-[calc(100%_-_44px)] w-full m-0 p-0 flex flex-col'
            : 'h-full w-full m-0 p-0 flex flex-col'
    "
>
    <div class="relative h-[60px] max-h-[60px]">
        <app-navbar></app-navbar>
    </div>
    <div class="h-[calc(100%_-_60px)] max-h-[calc(100%_-_60px)]">
        <router-outlet></router-outlet>
    </div>
</div>
