import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { CustomerDto } from 'src/app/shared/dto/customer.dto';
import { CustomerResponseDto } from 'src/app/shared/dto/pagination/customer-response.dto';
import { BaseService } from 'src/app/shared/services/base.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';

@Injectable({
    providedIn: 'root',
})
export class CustomersService extends BaseService {
    private customersSubject = new BehaviorSubject<CustomerDto[]>([]);
    public customers$ = this.customersSubject.asObservable();

    private readonly socketService = inject(SocketService);
    constructor(httpClient: HttpClient) {
        super(httpClient, 'customers');
    }

    async setupWebSocket(): Promise<void> {
        try {
            this.customersSubject.next((await this.findAll()).customers);
            this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.CUSTOMERS_ROOM));
            this.socketService.getNotifications(Messages.CUSTOMERS_CHANGED).subscribe(async () => {
                const response = await this.findAll();
                this.customersSubject.next(response.customers);
            });
        } catch (e: any) {
            throw new Error(`Error al establecer conexión con el socket de Clientes. Error: ${e.message}`);
        }
    }

    disconnectWebSocket(): void {
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.CUSTOMERS_ROOM));
    }

    async findAll(): Promise<CustomerResponseDto> {
        try {
            const request = this.httpClient.get<CustomerResponseDto>(`${this.url}/findAll`);

            const response: CustomerResponseDto = await lastValueFrom(request);

            if (!response) {
                throw null;
            }

            return response;
        } catch (e: any) {
            throw new Error(`No se ha encontrado ningun cliente. Error: ${e.message}`);
        }
    }

    async createCustomer(customer: CustomerDto): Promise<CustomerDto> {
        try {
            const request = this.httpClient.post<CustomerDto>(`${this.url}/create`, customer);

            const createdCustomer: CustomerDto = await lastValueFrom(request);

            if (!createdCustomer) {
                throw null;
            }

            return createdCustomer;

        } catch (e: any) {
            throw new Error(`No se ha podido crear el cliente. Error: ${e.error.message}`);
        }
    }

    async updateCustomer(customer: CustomerDto): Promise<CustomerDto> {
        try {
            const request = this.httpClient.put<CustomerDto>(`${this.url}/update`, customer);

            const updatedCustomer: CustomerDto = await lastValueFrom(request);

            if (!updatedCustomer) {
                throw null;
            }

            return updatedCustomer;

        } catch (e: any) {
            throw new Error(`No se ha podido actualizar el cliente. Error: ${e.message}`);
        }
    }

    async deleteCustomer(customerId: number): Promise<CustomerDto> {
        try {
            const request = this.httpClient.delete<CustomerDto>(`${this.url}/${customerId}`);

            const response: CustomerDto = await lastValueFrom(request);

            if (!response) {
                throw null;
            }

            return response;
        } catch (e: any) {
            throw new Error(`No se ha podido eliminar el cliente ${customerId}. Error: ${e.message}`);
        }
    }
}
