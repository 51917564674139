import { AssessDetailDto } from "./assess-detail.dto";
import { ProductionOrderProcessDto } from "./production-order-process.dto";

export class AssessDto {
    id?: number;
    description!: string;
    articleId!: number;
    quantity!: number;
    assessDetails?: AssessDetailDto[];
    productionOrderProcesses?: ProductionOrderProcessDto[];
}
