<div class="ml-4 mr-4">
    <nz-page-header class="site-page-header" nzTitle="Artículo" nzSubtitle="Articulos en el sistema">
        <nz-page-header-extra>
            <nz-space>
                <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button" (click)="createPackaging()">
                    Añadir Formato
                </button>
            </nz-space>
        </nz-page-header-extra>
    </nz-page-header>

    <nz-table #packagingsTable [nzData]="packagings" [nzLoading]="loadingTable">
        <thead>
            <tr>
                <th>Nombre</th>
                <th>Referencia externa</th>
                <th>Cantidad</th>
                <th>Unidad de medida</th>
                <th>Artículo</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            @for (data of packagingsTable.data; track data.id) {
            <tr>
                <td>{{ data.name }}</td>
                <td>{{ data.externalReference ? data.externalReference : '-'}}</td>
                <td>{{ data.quantity }}</td>
                <td>{{ data.measurementUnit?.abbreviation }}</td>
                <td>{{ data.article?.name }}</td>
                <td>
                    <div class="buttonsDnDTableContainer">
                        <img src="assets/icons/eye-icon.svg" class="h-6 w-6" (click)="seePackaging(data.id!)" title="Ver">
                        <nz-divider nzType="vertical"></nz-divider>
                        <img src="assets/icons/edit-icon.svg" class="h-6 w-6" (click)="editPackaging(data.id!)"
                            title="Editar">
                        <nz-divider nzType="vertical"></nz-divider>
                        <img src="assets/icons/xmark-icon.svg" class="h-6 w-6" (click)="deletePackaging(data.id!)"
                            title="Eliminar">
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </nz-table>
</div>

<nz-drawer [nzClosable]="false" [nzVisible]="drawerVisible" nzPlacement="right" [nzTitle]="drawerTitle"
    (nzOnClose)="closeDrawer()" [nzWidth]="'500px'" [nzMaskClosable]="fieldsReadOnly">
    <ng-container *nzDrawerContent>
        <form (ngSubmit)="saveEditing()">

            <label>Nombre:</label>
            <input nz-input [value]="packagingSelected.name ? packagingSelected.name : ''" type="text"
                class="searchSelectors" [readOnly]="fieldsReadOnly" id="name" />

            <label>Referencia externa</label>
            <input nz-input [value]="packagingSelected.externalReference ? packagingSelected.externalReference : ''"
                type="text" class="searchSelectors" [readOnly]="fieldsReadOnly" id="externalReference" />

            <label>Cantidad:</label>
            <input nz-input [value]="packagingSelected.quantityPerPackaging" type="number" pattern="\d*" class="searchSelectors"
                [readOnly]="fieldsReadOnly" id="quantityPerPackaging" />

            <button nz-button nz-dropdown [nzDropdownMenu]="menuMeasurements" type="button" [disabled]="fieldsReadOnly"
                nzTrigger="click" class="bg-white border border-gray-200 rounded px-4 py-2">
                <b>Unidad de medida:</b> {{ selectedMeasurement.name }}
            </button>

            <nz-dropdown-menu #menuMeasurements="nzDropdownMenu">
                <ul nz-menu>
                    @for (m of measurements; track m.id) {
                    <li nz-menu-item [innerHTML]="m.name" (click)="changeMeasure(m.id)"></li>
                    }
                </ul>
            </nz-dropdown-menu>
            <br /><br />

            <button nz-button nz-dropdown [nzDropdownMenu]="menuArticles" type="button" [disabled]="fieldsReadOnly"
                nzTrigger="click" class="bg-white border border-gray-200 rounded px-4 py-2">
                <b>Artículo:</b> {{ selectedArticle.name }}
            </button>

            <nz-dropdown-menu #menuArticles="nzDropdownMenu">
                <ul nz-menu>
                    @for (a of articles; track a.id) {
                    <li nz-menu-item [innerHTML]="a.name" (click)="changeArticle(a.id)"></li>
                    }
                </ul>
            </nz-dropdown-menu>
            <br /><br />

            @if (!fieldsReadOnly) {
            <button
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit">
                Guardar
            </button>
            <nz-divider nzType="vertical"></nz-divider>
            <button
                class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button" (click)="cancelEditing()">
                Cancelar
            </button>
            } @else {
            <!-- No metadata at the moment -->
            <!--
            <nz-divider></nz-divider>
            <h3>Metadatos</h3>
            -->
            }
        </form>
    </ng-container>
</nz-drawer>